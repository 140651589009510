import { Injectable } from '@angular/core';

@Injectable()
export class FunctionsService {

  constructor() { }

  public validateEmail(_email: string): boolean {
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regExp2 = /[ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ]/;

    return regExp.test(String(_email).toLowerCase()) && !regExp2.test(String(_email).toLowerCase());
  }

}
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Output() onInputsChange: EventEmitter<any> = new EventEmitter();

  public cardNumber: string;
  public cardName: string;
  public cardExpiry: string;
  public cardCvv: string;

  constructor() { }

  ngOnInit(): void {}

  public handleChange(event: any, field: string): void {
    this[field] = event.target.value;

    this.onInputsChange.emit({
      cardNumber: this.cardNumber,
      cardName: this.cardName,
      cardExpiry: this.cardExpiry,
      cardCvv: this.cardCvv
    });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Colors } from 'src/utils/colors';
import { LoginService } from '../../login/login.service';

@Component({
  selector: 'app-plan-details',
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.scss']
})
export class PlanDetailsComponent implements OnInit {

  public viewType = '';
  public Colors = Colors;
  public frequencyPeriodOptions: any[] = [
    {
      value: 'anual',
      label: 'Anual'
    },
    {
      value: 'semestral',
      label: 'Semestral'
    },
    {
      value: 'both',
      label: 'Both'
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<PlanDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private authService: LoginService
  ) { }

  ngOnInit(): void {
    if (this.data.information) {
      this.viewType = this.data.viewType;
      this.data = this.data.information;
    }
  }

  public selectPlan(plan: any): void {
    const route = this.authService.isAuthenticated() ? '/purchase-flux/cart' : '/purchase-flux/login-register';

    this.router.navigate([route], { queryParams: { planId: plan.id, planName: plan.name } });

    this.dialogRef.close();
  }

  public getFrequencyPeriodLabel(value: string): string {
    return this.frequencyPeriodOptions.find(option => option.value === value)?.label;
  }

  public getCurrentPlan(): string {
    return this.data.dailyPriceUnit === 'euro' ? 'EUR' : 'USD';
  }
}

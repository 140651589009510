<div class="blog-post-container mb-5">
    <div (click)="handleReadPost()" class="img-block">
        <img class="img-fluid" [src]="data.image" [alt]="data.title">
        <div class="flag" [style.background-color]="data.color"></div>
        <div class="flag_2" [style.background-color]="data.color"></div>
    </div>

    <div class="tags row my-3">
        <div class="tag ml-3" *ngFor="let tag of data?.tags">{{ tag }}</div>
    </div>

    <div (click)="handleReadPost()" class="title">
        <h3>{{ data?.title }}</h3>
    </div>

    <div (click)="handleReadPost()" class="description">
        <p>Author: {{ data?.author}} Publish: {{ data?.created_at | date:"dd/MM/yyyy HH:mm"}}</p>
    </div>

    <div (click)="handleReadPost()" class="description">
        <p>{{ getPartOfContent() }}</p>
    </div>

    <div class="mt-5 mx-auto text-center">
        <app-button 
            (click)="handleReadPost()" 
            buttonLabel="{{ 'readMore' | translate }}" 
            [buttonBGColor]="Colors.pink"
        ></app-button>
    </div>
</div>
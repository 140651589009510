import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchBarService {

  private _url: string;

  constructor(
    private _http: HttpClient
  ) {
    this._url = environment.api_url;
  }

  public getVisaTypes(): Observable<any> {
    return this._http.get(`${this._url}/visa`);
  }

  public getUniversityStates(): Observable<any> {
    return this._http.get(`${this._url}/state`);
  }

  public getUniversities(): Observable<any> {
    return this._http.get(`${this._url}/university`);
  }

  public getUniversitiesByState(idState: number, idVisa: number): Observable<any> {
    return this._http.get(`${this._url}/university?idState=${idState}&idVisa=${idVisa}`);
  }

  public setFilterUniversity(filter: any): void {
    localStorage.setItem('filter-university', JSON.stringify(filter));
  }

  public getFilterUniversity(): any {
    return JSON.parse(localStorage.getItem('filter-university'));
  }

  public setFilterPolicy(filter: any): void {
    localStorage.setItem('filter-policy', JSON.stringify(filter));
  }

  public getFilterPolicy(): any {
    return JSON.parse(localStorage.getItem('filter-policy'));
  }
  
  public setFilterCollege(filter: any): void {
    localStorage.setItem('filter-college', JSON.stringify(filter));
  }
  
  public getFilterCollege(): any {
    return JSON.parse(localStorage.getItem('filter-university'));
  }
}
<div class="login-container">
    <div class="login mx-4">
        <div class="login__title pt-4">
            <h2>{{ 'login' | translate }}</h2>
            <div class="line-stronger"></div>
        </div>

        <div class="mt-4">
            <app-input
                name="email"
                inputId="email" 
                inputLabel="{{ 'emailOrUsername' | translate }}:" 
                inputType="text" 
                inputPlaceholder=""
                (inputChange)="email = $event.target.value"
                [(ngModel)]="email"
                ngDefaultControl
            ></app-input>
        </div>

        <div class="mt-4">
            <app-input
                inputId="password" 
                inputLabel="{{ 'password' | translate }}:" 
                inputType="password" 
                inputPlaceholder=""
                (inputChange)="password = $event.target.value"
                [(ngModel)]="password"
                ngDefaultControl
                isPassword="true"
            ></app-input>
        </div>

        <div class="mt-3">
            <app-checkbox
                id="rememberMe"
                label="{{ 'rememberMe' | translate }}"
                (checkboxChange)="rememberMe = $event.target.checked"
                [(ngModel)]="rememberMe"
                ngDefaultControl
            >
            </app-checkbox>
        </div>
    </div>

    <div class="login__button pb-5 mt-5">
        <button class="forgot-passowd-link mb-3" (click)="onRecoveryPassword.emit(true)">{{ 'forgotPassword' | translate }}</button>
        <app-button 
            (click)="login()" 
            buttonLabel="{{ 'login' | translate }}" 
            [buttonBGColor]="Colors.deepPurple"
            [loading]="loading"
        ></app-button>
    </div>
</div>
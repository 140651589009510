import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-table-cart',
  templateUrl: './table-cart.component.html',
  styleUrls: ['./table-cart.component.scss']
})
export class TableCartComponent implements OnInit {

  @Input() cart = {
    startDate: moment().format('YYYY-MM-DD'),
    duration: 90,
    name: '',
    visaType: '',
    state: '',
    university: '',
    dailyPrice: '',
    total: '',
    insuranceCompany: '',
    dailyPriceUnit: 'dollar'
  };

  @Output() onChangeDate: EventEmitter<any> = new EventEmitter();
  @Output() onChangeDuration: EventEmitter<any> = new EventEmitter();

  public durationOptions: any = [];

  constructor() { }

  ngOnInit(): void {
    let duration = 90;
    let maxLength = 275;

    if (this.cart.insuranceCompany === 'Well Away') {
      duration = 180;
      maxLength = 185;
      this.cart.duration = 180;
    }

    this.durationOptions = Array.from({ length: maxLength }, (_, i) => ({
      value: i + duration,
      label: `${i + duration} days`
    }));

    this.handleChangeDuration(duration);
  }

  public handleChangeDate(event: any): void {
    this.onChangeDate.emit(event);
    this.cart.startDate = event;
  }

  public handleChangeDuration(event: any): void {
    this.onChangeDuration.emit(event);
    this.cart.duration = event;
  }

  public getCompanyLogo(): string {
    if (this.cart.insuranceCompany === 'Global Benefits Group' || this.cart.insuranceCompany === 'GBG') {
      return '../../../assets/img/gbclogo.png';

    } else if (this.cart.insuranceCompany === 'Dhig' || this.cart.insuranceCompany === 'DHIG') {
      return '../../../assets/img/dailyhealth-logo.png';

    } else if (this.cart.insuranceCompany === 'Well Away') {
      return '../../../assets/img/wellway-logo.png';
    }

    return null;
  }

  public getCurrentPlan(): string {
    return this.cart.dailyPriceUnit === 'euro' ? 'EUR' : 'USD';
  }
}

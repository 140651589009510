<div class="login-container">
    <div *ngIf="!showInfoScreen" class="login mx-4">
        <div class="login__title pt-4 text-center">
            <h2>{{ 'passwordAssistance' | translate }}</h2>
            <div class="line-stronger mx-auto mt-4"></div>
        </div>

        <div class="mt-4">
            <app-input
                name="email"
                inputId="email" 
                inputLabel="{{ 'email' | translate }}:" 
                inputType="text" 
                inputPlaceholder=""
                (inputChange)="email = $event.target.value"
                [(ngModel)]="email"
                ngDefaultControl
            ></app-input>
        </div>

        <div class="mt-4">
            <app-input
                name="email"
                inputId="confirmEmail" 
                inputLabel="{{ 'confirmEmail' | translate }}:" 
                inputType="text" 
                inputPlaceholder=""
                (inputChange)="confirmEmail = $event.target.value"
                [(ngModel)]="confirmEmail"
                ngDefaultControl
            ></app-input>
        </div>
    </div>

    <div *ngIf="showInfoScreen" class="login mx-4">
        <div class="login__title pt-4 text-center">
            <h2>{{ 'pleaseCheckYourEmail' | translate }}</h2>
            <div class="line-stronger mx-auto mt-4"></div>
        </div>

        <p class="text-center">An email has been sent with the instructions to reestablish your password. </p>
        
        <p class="text-center">If you need any other assistance please reach out to <span>info@global4students.com</span></p>
    </div>
    
    <div class="login__button pb-5 mt-5">
        <app-button 
            (click)="showInfoScreen ? navigateTo('purchase-flux/login-register') : recoveryPassword()" 
            buttonLabel="{{ getButtonTitle() | translate }}" 
            [buttonBGColor]="Colors.deepPurple"
            [loading]="loading"
        ></app-button>
    </div>
</div>
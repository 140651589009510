<div style="display: block; width: 100%; overflow-x: auto;">
    <table style="width: 100%; overflow-x: auto;">
        <tr class="table-header">
            <th style="width: 12%">{{ 'plan' | translate }}</th>
            <th>{{ 'startDate' | translate }}</th>
            <th>{{ 'endDate' | translate }}</th>
            <th>{{ 'total' | translate }}</th>
            <th>{{ 'documents' | translate }}</th>
        </tr>
        <tr class="table-body" *ngFor="let data of tableData">
            <td class="plan-name">
                <app-badge
                    *ngIf="data?.classification"
                    [label]="data?.classification"
                    [bgColor]="Colors.oceanBlue"
                    [textColor]="Colors.darkGray"
                ></app-badge>
                <a (click)="openDetails(data)">{{ data?.name || '-' }}</a>
            </td>
            <td>{{ data?.startDate | date: 'dd/MM/yyyy'}}</td>
            <td>{{ data?.endDate | date: 'dd/MM/yyyy'}}</td>
            <td>{{ data?.total | currency:getCurrentPlan(data):true }}</td>
            <td>
                <mat-icon class="cursor-pointer" (click)="viewDocs(data)">remove_red_eye</mat-icon>
            </td>
            <td>
                <app-button (click)="selectPlan(data)" buttonLabel="{{ 'buyAgain' | translate }}" [buttonBGColor]="Colors.deepPurple"></app-button>
            </td>
        </tr>
    </table>
    <div class="text-center my-5">
        <app-button (click)="searchNewPlan()" buttonLabel="{{ 'searchNewPlan' | translate }}" [buttonBGColor]="Colors.pink"></app-button>
    </div>
</div>
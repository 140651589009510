<div class="select">
    <label [for]="selectId" [style.color]="labelColor">{{ selectLabel }}</label>
    <select
        [id]="selectId"
        [name]="name"
        (change)="selectChange.emit($event)"
        [(ngModel)]="selected"
        [style.width]="customWidth"
        [ngClass]="{ 'selected-green': selectedCheckbox }"
    >
        <option value="">{{ 'select' | translate }}</option>
        <option *ngFor="let option of options" [value]="option.value">
            {{ option.label }}
        </option>
    </select>
</div>
<div class="container-search-bar">
    <div class="switch-search-form row pt-3">
        <!-- <div class="col-6 d-flex justify-content-end">
            <div>
                <label for="usa">{{ 'usa' | translate }}</label>
                <input 
                    type="radio" 
                    id="usa"
                    [(ngModel)]="searchForm"
                    name="switchSearchForm"
                    value="usa"
                />
            </div>
        </div> -->
        <!-- <div class="col-6">
            <label for="abroad">{{ 'abroad' | translate }}</label>
            <input 
                type="radio" 
                id="abroad"
                [(ngModel)]="searchForm"
                name="switchSearchForm"
                value="abroad"
            />
        </div> -->
    </div>

    <div *ngIf="searchForm === 'usa'" class="col-12 search-bar">
        <div class="row">
            <div class="col search-bar__input">
                <app-select 
                    selectId="visaType" 
                    selectLabel="{{ 'visaType' | translate }}:" 
                    [options]="visaTypes"
                    (selectChange)="visaType = $event.target.value" 
                    [(ngModel)]="visaType"
                    ngDefaultControl
                    [selected]="visaType"
                    customWidth="100%"
                >
                </app-select>
            </div>
            <div class="col search-bar__input my-3 my-md-0">
                <app-input 
                    inputId="birthDate" 
                    inputLabel="{{ 'birthDate' | translate }}:" 
                    inputType="date" 
                    inputPlaceholder=""
                    (inputChange)="birthDate = $event.target.value" 
                    [(ngModel)]="birthDate" 
                    ngDefaultControl
                    [value]="birthDate"
                ></app-input>
            </div>
            <div class="col search-bar__input">
                <app-select 
                    selectId="universityState" 
                    selectLabel="{{ 'universitiesState' | translate }}:"
                    [options]="universityStates" 
                    (selectChange)="handleStateChange($event)"
                    [(ngModel)]="universityState" 
                    ngDefaultControl
                    [selected]="universityState"
                    customWidth="100%"
                ></app-select>
            </div>
            <div class="col search-bar__input my-3 my-md-0">
                <app-select 
                    selectId="universityName" 
                    selectLabel="{{ 'universitiesName' | translate }}:"
                    [options]="universities" 
                    (selectChange)="universityName = $event.target.value"
                    [(ngModel)]="universityName" 
                    ngDefaultControl
                    [selected]="universityName"
                    customWidth="100%"
                ></app-select>
            </div>
            <div class="col search-bar__button">
                <app-button 
                    (click)="search()" 
                    buttonLabel="{{ 'editSearch' | translate }}"
                    [buttonBGColor]="Colors.pink"
                    customWidth="100%"
                ></app-button>
            </div>
        </div>
    </div>

    <div *ngIf="searchForm === 'abroad'" class="search-bar">
        <div class="row">
            <div class="col search-bar__input my-3 my-md-0">
                <app-input 
                    inputId="birthDate" 
                    inputLabel="{{ 'birthDate' | translate }}:" 
                    inputType="date"
                    inputPlaceholder=""
                    (inputChange)="birthDate = $event.target.value" 
                    [(ngModel)]="birthDate" 
                    ngDefaultControl
                    [value]="birthDate"
                ></app-input>
            </div>
            <div class="col search-bar__input">
                <app-select 
                    selectId="country" 
                    selectLabel="{{ 'country' | translate }}:"
                    [options]="countries" 
                    (selectChange)="country = $event.target.value"
                    [(ngModel)]="country" 
                    ngDefaultControl
                    [selected]="country"
                ></app-select>
            </div>
            <div class="col search-bar__input my-3 my-md-0">
                <app-input 
                    inputId="universityNameSearch" 
                    inputLabel="{{ 'university_name' | translate }}:" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="universityNameSearch = $event.target.value" 
                    [(ngModel)]="universityNameSearch" 
                    ngDefaultControl
                    [value]="universityNameSearch"
                ></app-input>
            </div>
            <div class="col search-bar__button">
                <app-button 
                    (click)="search()" 
                    buttonLabel="{{ 'editSearch' | translate }}"
                    [buttonBGColor]="Colors.pink"
                    customWidth="100%"
                ></app-button>
            </div>
        </div>
    </div>

    <div *ngIf="searchForm === 'school'" class="search-bar">
        <div class="row">
            <div class="col search-bar__input">
                <app-select 
                    selectId="visaType" 
                    selectLabel="{{ 'visaType' | translate }}:" 
                    [options]="visaTypesSchool"
                    (selectChange)="visaType = $event.target.value" 
                    [(ngModel)]="visaType"
                    ngDefaultControl
                    [selected]="visaType"
                    customWidth="100%"
                >
                </app-select>
            </div>
            <div class="col search-bar__input my-3 my-md-0">
                <app-input 
                    inputId="birthDate" 
                    inputLabel="{{ 'birthDate' | translate }}:" 
                    inputType="date" 
                    inputPlaceholder=""
                    (inputChange)="birthDate = $event.target.value" 
                    [(ngModel)]="birthDate" 
                    ngDefaultControl
                    [value]="birthDate"
                ></app-input>
            </div>
            <div class="col search-bar__input">
                <app-select 
                    selectId="universityState" 
                    selectLabel="{{ 'universitiesState' | translate }}:"
                    [options]="universityStates" 
                    (selectChange)="handleStateChange($event)"
                    [(ngModel)]="universityState" 
                    ngDefaultControl
                    [selected]="universityState"
                    customWidth="100%"
                ></app-select>
            </div>
            <div class="col search-bar__input my-3 my-md-0">
                <app-input 
                    inputId="schoolNameSearch" 
                    inputLabel="{{ 'schoolName' | translate }}:" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="schoolNameSearch = $event.target.value" 
                    [(ngModel)]="schoolNameSearch" 
                    ngDefaultControl
                    [value]="schoolNameSearch"
                ></app-input>
            </div>
            <div class="col search-bar__button">
                <app-button 
                    (click)="search()" 
                    buttonLabel="{{ 'editSearch' | translate }}"
                    [buttonBGColor]="Colors.pink"
                    customWidth="100%"
                ></app-button>
            </div>
        </div>
    </div>
</div>
<div class="container-payment">
    <div class="payment mx-5 py-4">
        <div class="payment-header">
            <h2>{{ 'payment' | translate }}</h2>
            <p>{{ 'allTransactionsAreSecureAndEncrypted' | translate }}</p>
        </div>

        <div class="payment-option">

        </div>

        <div class="mt-4">
            <app-input
                inputId="cardName" 
                inputLabel="{{ 'nameOnCard' | translate }}" 
                inputType="text" 
                inputPlaceholder=""
                (inputChange)="handleChange($event, 'cardName')"
                [(ngModel)]="cardName"
                ngDefaultControl
            ></app-input>
       </div>

       <div class="mt-4">
            <app-input
                inputId="cardNumber" 
                inputLabel="{{ 'cardNumber' | translate }}" 
                inputType="text" 
                inputPlaceholder=""
                (inputChange)="handleChange($event, 'cardNNummber')"
                [(ngModel)]="cardNumber"
                ngDefaultControl
                mask="0000 0000 0000 0000"
            ></app-input>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-6">
                <app-input
                    inputId="cardExpiry" 
                    inputLabel="{{ 'expirationDate' | translate }}" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="handleChange($event, 'cardExpiry')"
                    [(ngModel)]="cardExpiry"
                    ngDefaultControl
                    mask="00/0000"
                ></app-input>
            </div>
            <div class="col-12 col-md-6 mt-md-0 mt-4">
                <app-input
                    inputId="cardCvv" 
                    inputLabel="{{ 'cvv' | translate }}" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="handleChange($event, 'cardCvv')"
                    [(ngModel)]="cardCvv"
                    ngDefaultControl
                    mask="000"
                ></app-input>
            </div>
        </div>
    </div>
</div>
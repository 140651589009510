<div class="container-checkbox">
    <div class="checkbox">
        <input 
            type="checkbox" 
            [id]="id"
            (change)="handleChange($event)"
            [(ngModel)]="value"
        />
        <label 
            [for]="id" 
            [ngStyle]="{ 'font-size': customLabelSize, 'font-weight': customLabelWeight }"
        >{{ label }}</label>
    </div>
</div>
<div class="container-accordion">
    <div class="accordion-custom p-4">
        <div class="accordion-header" (click)="handleExpand()">
            <div class="row">
                <div class="col-1">
                    <div class="accordion-header-number">
                        <span>{{ index }}</span>
                    </div>
                </div>
                <div class="col-10 my-auto">
                    <h2 class="accordion-title">
                        <span class="accordion-item-title-text">{{ title }}</span>
                    </h2>
                </div>
                <div class="col-1 hide-mobile">
                    <button class="button-open-close" [ngClass]="{ 'close' : isExpanded }">
                        <mat-icon 
                            aria-hidden="false" 
                        >{{ isExpanded ? 'clear' : 'add' }}</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="isExpanded" class="accordion-body d-flex justify-content-end mt-4">
            <div class="accordion-body-content col-md-11 col-12">
                <p [innerHTML]="sanitizer.bypassSecurityTrustHtml(description)"></p>
            </div>
        </div>
    </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type OrderReviewData = {
  name: string;
  duration: number;
  visa: string;
  subtotal: number;
  total: number;
  price: number;
  coverageDays?: number;
  priceUnit?: string;
};

@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.scss']
})
export class OrderReviewComponent implements OnInit {

  @Input() orderReviewData: OrderReviewData;

  @Output() onInputsChange: EventEmitter<any> = new EventEmitter();

  public termsAccepted = false;
  public termsStoreHandlingData = false;

  constructor() { }

  ngOnInit(): void { }

  public handleChange(value: any, field: string): void {
    this[field] = value;
    this.onInputsChange.emit({
      termsAccepted: this.termsAccepted,
      termsStoreHandlingData: this.termsStoreHandlingData
    });
  }

  public getCurrentPlan(): string {
    return this.orderReviewData.priceUnit === 'euro' ? 'EUR' : 'USD';
  }
}

<div class="col-12">
    <div class="row">
        <div class="col-12 col-md-6">
            <app-input
                inputId="firstName" 
                inputLabel="{{ 'firstName' | translate }}" 
                inputType="text" 
                inputPlaceholder=""
                (inputChange)="firstName = $event.target.value"
                [(ngModel)]="firstName"
                ngDefaultControl
                [value]="firstName"
            ></app-input>
        </div>
        <div class="col-12 col-md-6">
            <app-input
                inputId="lastName" 
                inputLabel="{{ 'lastName' | translate }}" 
                inputType="text" 
                inputPlaceholder=""
                (inputChange)="lastName = $event.target.value"
                [(ngModel)]="lastName"
                ngDefaultControl
                [value]="lastName"
            ></app-input>
        </div>
    </div>

    <div class="mt-4">
        <app-input
            name="email"
            inputId="email" 
            inputLabel="{{ 'email' | translate }}" 
            inputType="text" 
            inputPlaceholder=""
            (inputChange)="email = $event.target.value"
            [(ngModel)]="email"
            ngDefaultControl
            [value]="email"
        ></app-input>
    </div>

    <div class="row mt-4">
        <div class="col-12 col-md-6">
            <app-input
                inputId="passport" 
                inputLabel="{{ 'passport' | translate }}" 
                inputType="text" 
                inputPlaceholder=""
                (inputChange)="passport = $event.target.value"
                [(ngModel)]="passport"
                ngDefaultControl
                [value]="passport"
            ></app-input>
        </div>
        <div class="col-12 col-md-6">
            <app-input
                inputId="birthdate" 
                inputLabel="{{ 'birthDate' | translate }}" 
                inputType="text" 
                inputPlaceholder=""
                (inputChange)="birthdate = $event.target.value"
                [(ngModel)]="birthdate"
                ngDefaultControl
                mask="00/00/0000"
                [value]="birthdate"
            ></app-input>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-12 col-md-6">
            <app-input
                inputId="phone" 
                inputLabel="{{ 'phone' | translate }}" 
                inputType="text" 
                inputPlaceholder=""
                (inputChange)="phone = $event.target.value"
                [(ngModel)]="phone"
                ngDefaultControl
                [value]="phone"
                mask="(000) 000-0000"
            ></app-input>
        </div>
        <div class="col-12 col-md-6">
            <app-input
                inputId="mobile" 
                inputLabel="{{ 'mobile' | translate }}" 
                inputType="text" 
                inputPlaceholder=""
                (inputChange)="mobile = $event.target.value"
                [(ngModel)]="mobile"
                ngDefaultControl
                [value]="mobile"
                mask="(000) 000-0000"
            ></app-input>
        </div>
    </div>

    <div class="mt-5">
        <div class="text-center">
            <h4 class="change-password-title">{{ 'changePassword' | translate }}</h4>
            <p class="change-password-subtitle">{{ 'doYouNeedToUpdatePassowrd' | translate }}</p>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-6">
                <app-input
                    inputId="password" 
                    inputLabel="{{ 'newPassword' | translate }}" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="password = $event.target.value"
                    [(ngModel)]="password"
                    ngDefaultControl
                    [value]="password"
                ></app-input>
            </div>
            <div class="col-12 col-md-6">
                <app-input
                    inputId="confirmPassword" 
                    inputLabel="{{ 'confirmNewPassword' | translate }}" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="confirmPassword = $event.target.value"
                    [(ngModel)]="confirmPassword"
                    ngDefaultControl
                    [value]="confirmPassword"
                ></app-input>
            </div>
        </div>
    </div>
    
    <div class="my-5 text-center">
        <app-button 
            (click)="updateUser()" 
            buttonLabel="{{ 'saveChanges' | translate }}" 
            [buttonBGColor]="Colors.deepPurple"
            [loading]="loading"
        ></app-button>
    </div>
</div>
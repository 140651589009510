import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private _url: string;
  private httpHeaders = {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }

  constructor(
    private _http: HttpClient
  ) {
    this._url = environment.api_url;
  }

  public moduleRecords(): Observable<any> {
    return this._http.get(`${this._url}/admin/module_records`, this.httpHeaders);
  }

  public generateNewId(option: string): Observable<any> {
    return this._http.get(`${this._url}/admin/generate_new_id/${option}`, this.httpHeaders);
  }

  public listPolicy(params?: any): Observable<any> {
    const uniqHttpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    });

    const options = {
      headers: uniqHttpHeaders,
      params: new HttpParams({ fromObject: params })
    };

    return this._http.get(`${this._url}/admin/policy`, options);
  }

  public getPolicy(id: number): Observable<any> {
    return this._http.get(`${this._url}/policy/${id}`, this.httpHeaders);
  }

  public createPolicy(policy: any): Observable<any> {
    return this._http.post(`${this._url}/admin/policy`, { policy }, this.httpHeaders);
  }

  public forcedPolicy(params: any): Observable<any> {
    return this._http.post(`${this._url}/admin/forced_policy`, { params }, this.httpHeaders);
  }

  public listForcedPolicy(id: number): Observable<any> {
    return this._http.get(`${this._url}/admin/forced_policy/${id}`, this.httpHeaders);
  }

  public deleteForcedPolicyConnection(id: number): Observable<any> {
    return this._http.delete(`${this._url}/admin/forced_policy/${id}`, this.httpHeaders);
  }

  public updatePolicy(data: any): Observable<any> {
    return this._http.put(`${this._url}/admin/policy/${data.policy.id}`, data, this.httpHeaders);
  }

  public deletePolicy(id: number): Observable<any> {
    return this._http.delete(`${this._url}/admin/policy/${id}`, this.httpHeaders);
  }

  public restorePolicy(id: any): Observable<any> {
    return this._http.put(`${this._url}/admin/policy/restore/${id}`, null, this.httpHeaders);
  }

  public getUniversity(id: number): Observable<any> {
    return this._http.get(`${this._url}/university/${id}`, this.httpHeaders);
  }

  public listUniversity(params?: any): Observable<any> {
    const uniqHttpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    });

    const options = {
      headers: uniqHttpHeaders,
      params
    };

    return this._http.get(`${this._url}/admin/university`, options);
  }

  public createUniversity(data: any): Observable<any> {
    return this._http.post(`${this._url}/admin/university`, { university: data }, this.httpHeaders);
  }

  public updateUniversity(data: any): Observable<any> {
    return this._http.put(`${this._url}/admin/university/${data.university.id}`, data, this.httpHeaders);
  }

  public deleteUniversity(id: number): Observable<any> {
    return this._http.delete(`${this._url}/admin/university/${id}`, this.httpHeaders);
  }

  public restoreUniversity(id: any): Observable<any> {
    return this._http.put(`${this._url}/admin/university/restore/${id}`, null, this.httpHeaders);
  }

  public listVisa(): Observable<any> {
    return this._http.get(`${this._url}/visa`, this.httpHeaders);
  }

  public getVisa(id: number): Observable<any> {
    return this._http.get(`${this._url}/visa/${id}`, this.httpHeaders);
  }

  public createVisa(data: any): Observable<any> {
    return this._http.post(`${this._url}/admin/visa`, data, this.httpHeaders);
  }

  public updateVisa(data: any): Observable<any> {
    return this._http.put(`${this._url}/admin/visa/${data.visa.id}`, data, this.httpHeaders);
  }

  public deleteVisa(id: number): Observable<any> {
    return this._http.delete(`${this._url}/admin/visa/${id}`, this.httpHeaders);
  }

  public listPayment(): Observable<any> {
    return this._http.get(`${this._url}/admin/order`, this.httpHeaders);
  }

  public deleteOrder(id: number): Observable<any> {
    return this._http.delete(`${this._url}/admin/order/${id}`, this.httpHeaders);
  }

  public getPayment(id: number): Observable<any> {
    return this._http.get(`${this._url}/payment/${id}`, this.httpHeaders);
  }

  public createPayment(data: any): Observable<any> {
    return this._http.post(`${this._url}/payment`, data, this.httpHeaders);
  }

  public updatePayment(data: any): Observable<any> {
    return this._http.put(`${this._url}/payment`, data, this.httpHeaders);
  }

  public deletePayment(id: number): Observable<any> {
    return this._http.delete(`${this._url}/payment/${id}`, this.httpHeaders);
  }

  public listState(): Observable<any> {
    return this._http.get(`${this._url}/state`, this.httpHeaders);
  }

  public getState(id: number): Observable<any> {
    return this._http.get(`${this._url}/state/${id}`, this.httpHeaders);
  }

  public createState(data: any): Observable<any> {
    return this._http.post(`${this._url}/admin/state`, data, this.httpHeaders);
  }

  public updateState(data: any): Observable<any> {
    return this._http.put(`${this._url}/admin/state/${data.state.id}`, data, this.httpHeaders);
  }

  public deleteState(id: number): Observable<any> {
    return this._http.delete(`${this._url}/admin/state/${id}`, this.httpHeaders);
  }

  public listUsers(): Observable<any> {
    return this._http.get(`${this._url}/admin/user/all`, this.httpHeaders);
  }

  public getUser(id: number): Observable<any> {
    return this._http.get(`${this._url}/admin/user/${id}`, this.httpHeaders);
  }

  public createUser(data: any): Observable<any> {
    return this._http.post(`${this._url}/admin/user`, data, this.httpHeaders);
  }

  public updateUser(id: number, data: any): Observable<any> {
    return this._http.put(`${this._url}/admin/user/${id}`, data, this.httpHeaders);
  }

  public deleteUser(id: number): Observable<any> {
    return this._http.delete(`${this._url}/admin/user/${id}`, this.httpHeaders);
  }

  // View Type
  public viewClient(id: number): Observable<any> {
    return this._http.get(`${this._url}/admin/view/client/${id}`, this.httpHeaders);
  }

  public viewPolicy(id: number): Observable<any> {
    return this._http.get(`${this._url}/admin/view/policy/${id}`, this.httpHeaders);
  }

  public viewUniversity(id: number): Observable<any> {
    return this._http.get(`${this._url}/admin/view/university/${id}`, this.httpHeaders);
  }

  public listContacts(): Observable<any> {
    return this._http.get(`${this._url}/admin/contacts`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  public deleteContact(id: number): Observable<any> {
    return this._http.delete(`${this._url}/admin/contacts/${id}`, this.httpHeaders);
  }

  public importPolicy(data: any): Observable<any> {
    return this._http.post(`${this._url}/admin/import/policy`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      }
    });
  }

  public importUniversity(data: any): Observable<any> {
    return this._http.post(`${this._url}/admin/import/university`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      }
    });
  }

  public setMenu(_menu: string) {
    setTimeout(() => {
      const menuItems = [
        'module_visa',
        'module_blog',
        'module_state',
        'module_policy',
        'module_payment',
        'module_contact',
        'module_university',
        'module_user'
      ]

      $('#' + _menu).addClass('selectedMenu');

      menuItems.forEach(m => {
        if (m !== _menu) {
          $('#' + m).removeClass('selectedMenu');
        }
      });
    }, 50);
  }

  public setSubMenu(_menu: string) {
    setTimeout(() => {
      const menuItems = [
        'subMenuActive',
        'subMenuTrash'
      ]

      $('#' + _menu).addClass('selectedMenu');

      menuItems.forEach(m => {
        if (m !== _menu) {
          $('#' + m).removeClass('selectedMenu');
        }
      });
    }, 100);
  }
}

<div 
    class="badge-container" 
    [ngStyle]="{ 'background-color': bgColor }"
>
    <p 
        class="badge-label" 
        [ngStyle]="{ 'color': textColor }"
    >
        {{ label }}
    </p>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Colors } from 'src/utils/colors';

@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit {

  public Colors = Colors;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public getQuote(): void {
    this.router.navigate(['student-health-insurance-plans']);
  }
}

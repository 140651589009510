export const Colors = {
    deepPurple: '#742994',
    pink: '#FF00FF',
    lighterGray: '#F6F6F6',
    lightGray: '#D9D9D9',
    darkGray: '#55565A',
    mediumGray: '#727070',
    oceanBlue: '#ACFFFE',
    lightGreen: '#EEFDC4',
    darkGreen: '#5fb017',
    lightPink: '#F8E8FF',
    deepOrange: '#FD6C0F',
    greenAd: '#17BEBB',
    darknessGreen: '#116C6B',
    deepRed: '#D72338',
    pinkBlog: '#F8049A',
    purpleBlog: '#8C52FF',
    bestPrice: '#00FFF0',
    bestValue: '#4FF633',
    bestCoverage: '#FF9900'
};

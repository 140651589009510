import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-billing-info',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.scss']
})
export class BillingInfoComponent implements OnInit {

  @Output() onInputsChange: EventEmitter<any> = new EventEmitter();

  public firstName: string;
  public lastName: string;
  public email: string;
  public country: string;
  public address: string;
  public city: string;
  public state: string;
  public postalCode: string;
  public addressLine2: string;

  constructor() { }

  ngOnInit(): void {
  }

  public handleChange(event: any, field: string): void {
    this[field] = event.target.value;
    
    this.onInputsChange.emit({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      country: this.country,
      address: this.address,
      city: this.city,
      state: this.state,
      postalCode: this.postalCode,
      addressLine2: this.addressLine2
    });
  }
}

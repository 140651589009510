import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { ComponentsModule } from './components/components.module';
import { NgxTranslateModule } from './translate/translate.module';
import { FunctionsService } from './shared/functions.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConstructionModule } from './components/construction/construction.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxTranslateModule,
    ConstructionModule,
    ToastrModule.forRoot()
  ],
  providers: [
    FunctionsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

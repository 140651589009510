import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {

  @Input() label: string;
  @Input() bgColor: string;
  @Input() textColor: string;

  constructor() { }

  ngOnInit(): void {
  }

}

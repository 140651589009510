import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AdminService } from 'src/app/pages/admin/admin.service';
import { Colors } from 'src/utils/colors';

@Component({
  selector: 'app-gs4-generate-new-id-dialog',
  templateUrl: './generate-new-id-dialog.component.html',
  styleUrls: ['./generate-new-id-dialog.component.scss']
})
export class GenerateNewIdDialogComponent implements OnInit {

  public newId = '';
  public Colors = Colors;
  public pageLoading = false;

  constructor(
    private toastr: ToastrService,
    private _adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GenerateNewIdDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.generateId(this.data.title)
  }

  public generateId(option: string) {
    this.pageLoading = true;
    this._adminService.generateNewId(option).subscribe((response: any) => {
      if (response.status === 'success') {
        this.newId = response.newId;
        this.pageLoading = false;
      }
    }, (error: any) => {
      this.toastr.error(error?.error?.message || 'Something went wrong, please try again later.', 'Error');
      this.pageLoading = false;
    });
  }
}
import * as _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  
  @Input() buttonLabel: string;
  @Input() buttonBGColor: string;
  @Input() buttonTextColor: string;
  @Input() loading: boolean = false;
  @Input() customWidth: string;

  public colorClone: string;

  ngOnInit(): void { 
    if (this.buttonBGColor) {
      this.colorClone = _.clone(this.buttonBGColor);
    }
  }

  hexToRgb() {
    const [r, g, b] = this.colorClone.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16))

    this.colorClone = `rgba(${r}, ${g}, ${b}, 0.5)`;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-gs4-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  public file: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadFileComponent>,
  ) { }

  ngOnInit(): void { }

  public uploadFile(file: any): void {
    const formData = new FormData();
    formData.append('file', file);
    this.data.onUpload(formData);

    setTimeout(() => {
      this.dialogRef.close();
    }, 200);
  }

  public onFileChange(event: any): void {
    this.uploadFile(event.target.files[0]);
  }
}

<div>
    <h3>{{ data.title | translate }}</h3><br />

    <div *ngIf="pageLoading" class="center">
        <div class="pageLoading">
            <mat-spinner></mat-spinner>
        </div>
        <p class="pageLoadingText">Generating id...</p>
    </div>

    <div [hidden]="pageLoading">
        <h4>New ID: {{ newId }}</h4>
    </div>

    <div [hidden]="pageLoading" class="right">
        <app-button mat-dialog-close buttonLabel="Exit" [buttonBGColor]="Colors.mediumGray"></app-button>
    </div>
</div>
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Colors } from 'src/utils/colors';
import { LoginService } from '../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { FunctionsService } from 'src/app/shared/functions.service';

@Component({
  selector: 'app-gs4-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss']
})
export class RecoveryPasswordComponent implements OnInit {

  @Output() onBackToLogin = new EventEmitter();

  public Colors = Colors;
  public email: string;
  public confirmEmail: string;
  public showInfoScreen = false;
  public loading = false;

  constructor(
    private _functions: FunctionsService,
    private loginService: LoginService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void { }

  public recoveryPassword(): void {
    let emailValid = true;

    if (!this.email) {
      this.toastr.error('Please fill in the email field.', 'Error');
      emailValid = false;

    } else if (!this._functions.validateEmail(this.email)) {
      this.toastr.error('The email field does not contain a valid email address.', 'Error');
      emailValid = false;
    }

    if (!this.email || !this.confirmEmail) {
      this.toastr.error('Please fill all the fields', 'Error');
      emailValid = false;
    }

    if (this.email !== this.confirmEmail) {
      this.toastr.error('The emails do not match', 'Error');
      emailValid = false;
    }

    if (emailValid) {
      this.loading = true;
      this.loginService.recoveryPassword({
        email: this.email,
        confirmEmail: this.confirmEmail
      }).subscribe((response) => {
        this.loading = false;
        if (response.status === 'success') {
          this.showInfoScreen = true;
        }
      }, error => {
        this.loading = false;
        this.toastr.error(error.error.message, 'Error');
      });
    }
  }

  public getButtonTitle(): string {
    return this.showInfoScreen ? 'logIn' : 'continue';
  }

  public navigateTo(path: string): void {
    this.onBackToLogin.emit(true);
    this.router.navigate([path]);
  }
}

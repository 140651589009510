import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConstructionComponent } from './construction.component';

@NgModule({
  declarations: [
    ConstructionComponent
  ],
  exports: [
    ConstructionComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ConstructionModule { }
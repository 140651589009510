<div class="login-container">
    <div *ngIf="!showInfoScreen" class="login mx-4">
        <div class="login__title pt-4 text-center">
            <h2>{{ 'passwordReset' | translate }}</h2>
            <div class="line-stronger mx-auto mt-4"></div>
        </div>

        <div class="mt-4">
            <app-input
                inputId="password" 
                inputLabel="{{ 'password' | translate }}:" 
                inputType="password" 
                inputPlaceholder=""
                (inputChange)="password = $event.target.value"
                [(ngModel)]="password"
                ngDefaultControl
                isPassword="true"
            ></app-input>
        </div>

        <div class="mt-4">
            <app-input
                inputId="confirmPassword" 
                inputLabel="{{ 'confirmNewPassword' | translate }}:" 
                inputType="password" 
                inputPlaceholder=""
                (inputChange)="confirmPassword = $event.target.value"
                [(ngModel)]="confirmPassword"
                ngDefaultControl
                isPassword="true"
            ></app-input>
        </div>
    </div>

    <div *ngIf="showInfoScreen" class="login mx-4">
        <div class="login__title pt-4 text-center">
            <h2>{{ 'passwordUpdated' | translate }}</h2>
            <div class="line-stronger mx-auto mt-4"></div>
        </div>
        <div class="mx-auto w-100 text-center mb-4">
            <img width="80" class="img-fluid" src="../../../assets/img/check-login.png" alt="" />
        </div>
        <p class="text-center">Your password has been updated! </p>
    </div>
    
    <div class="login__button pb-5 mt-5">
        <app-button 
            (click)="showInfoScreen ? navigateTo('purchase-flux/login-register') : updatePassword()" 
            buttonLabel="{{ getButtonTitle() | translate }}" 
            [buttonBGColor]="Colors.deepPurple"
            [loading]="loading"
        ></app-button>
    </div>
</div>
<div class="container-billing-info">
    <div class="billing-info mx-5 py-4">
        <div class="billing-info-header">
            <h2>{{ 'billing' | translate }}</h2>
            <p>{{ 'allInformationProvidedisProtected' | translate }}</p>
        </div>

        <div class="body">
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-input
                        inputId="firstName" 
                        inputLabel="{{ 'firstName' | translate }}" 
                        inputType="text" 
                        inputPlaceholder=""
                        (inputChange)="handleChange($event, 'firstName')"
                        [(ngModel)]="firstName"
                        ngDefaultControl
                    ></app-input>
                </div>
                <div class="col-12 col-md-6 mt-md-0 mt-4">
                    <app-input
                        inputId="lastName" 
                        inputLabel="{{ 'lastName' | translate }}" 
                        inputType="text" 
                        inputPlaceholder=""
                        (inputChange)="handleChange($event, 'lastName')"
                        [(ngModel)]="lastName"
                        ngDefaultControl
                    ></app-input>
                </div>
            </div>

            <div class="mt-4">
                <app-input
                    name="email"
                    inputId="email" 
                    inputLabel="{{ 'email' | translate }}" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="handleChange($event, 'email')"
                    [(ngModel)]="email"
                    ngDefaultControl
                ></app-input>
            </div>

            <div class="row mt-4">
                <div class="col-12 col-md-6">
                    <app-input
                        inputId="country" 
                        inputLabel="{{ 'country' | translate }}" 
                        inputType="text" 
                        inputPlaceholder=""
                        (inputChange)="handleChange($event, 'country')"
                        [(ngModel)]="country"
                        ngDefaultControl
                    ></app-input>
                </div>
                <div class="col-12 col-md-6 mt-md-0 mt-4">
                    <app-input
                        inputId="state" 
                        inputLabel="{{ 'state' | translate }}" 
                        inputType="text" 
                        inputPlaceholder=""
                        (inputChange)="handleChange($event, 'state')"
                        [(ngModel)]="state"
                        ngDefaultControl
                    ></app-input>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12 col-md-6">
                    <app-input
                        inputId="city" 
                        inputLabel="{{ 'city' | translate }}" 
                        inputType="text" 
                        inputPlaceholder=""
                        (inputChange)="handleChange($event, 'city')"
                        [(ngModel)]="city"
                        ngDefaultControl
                    ></app-input>
                </div>
                <div class="col-12 col-md-6 mt-md-0 mt-4">
                    <app-input
                        inputId="postalCode" 
                        inputLabel="{{ 'postalCode' | translate }}" 
                        inputType="text" 
                        inputPlaceholder=""
                        (inputChange)="handleChange($event, 'postalCode')"
                        [(ngModel)]="postalCode"
                        ngDefaultControl
                    ></app-input>
                </div>
            </div>

            <div class="mt-4">
                <app-input
                    inputId="address" 
                    inputLabel="{{ 'address' | translate }}" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="handleChange($event, 'address')"
                    [(ngModel)]="address"
                    ngDefaultControl
                ></app-input>
           </div>

           <div class="mt-4">
                <app-input
                    inputId="addressLine2" 
                    inputLabel="{{ 'addressLine2' | translate }}" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="handleChange($event, 'addressLine2')"
                    [(ngModel)]="addressLine2"
                    ngDefaultControl
                ></app-input>
            </div>
        </div>
    </div>
</div>
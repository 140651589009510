import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask'
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { SearchBarComponent } from './search-bar/search-bar.component';
import { TableListPlansComponent } from './table-list-plans/table-list-plans.component';
import { ButtonComponent } from './button/button.component';
import { InputComponent } from './input/input.component';
import { PlanDetailsComponent } from './modals/plan-details/plan-details.component';
import { RegisterComponent } from './register/register.component';
import { BadgeComponent } from './badge/badge.component';
import { CardPlanComponent } from './cards/card-plan/card-plan.component';
import { BreadcrumbPurchaseComponent } from './breadcrumb-purchase/breadcrumb-purchase.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SelectComponent } from './select/select.component';
import { TableCartComponent } from './table-cart/table-cart.component';
import { BillingInfoComponent } from './checkout/billing-info/billing-info.component';
import { PaymentComponent } from './checkout/payment/payment.component';
import { OrderReviewComponent } from './checkout/order-review/order-review.component';
import { TableMyPurchasesComponent } from './table-my-purchases/table-my-purchases.component';
import { UserFormComponent } from './user-form/user-form.component';
import { NgxTranslateModule } from '../translate/translate.module';
import { ProcessingPaymentComponent } from './processing-payment/processing-payment.component';
import { HeaderPagesComponent } from './header-pages/header-pages.component';
import { AdvertisementComponent } from './advertisement/advertisement.component';
import { AccordionComponent } from './accordion/accordion.component';
import { SearchBarHomeComponent } from './search-bar-home/search-bar-home.component';
import { NotFoundPlanComponent } from './not-found-plan/not-found-plan.component';
import { LoginComponent } from './login/login.component';
import { ConfirmDeleteComponent } from './modals/confirm-delete/confirm-delete.component';
import { UploadFileComponent } from './modals/upload-file/upload-file.component';
import { BlogPostComponent } from './blog-post/blog-post.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { GenerateNewIdDialogComponent } from './generate-new-id-dialog/generate-new-id-dialog.component';
import { UserDetailsComponent } from './modals/user-details/user-details.component';
import { PipesModule } from '../shared/application/pipes/pipes.module';
import { ViewDocsModule } from './view-docs/view-docs.module';

@NgModule({
  declarations: [
    SearchBarComponent,
    TableListPlansComponent,
    ButtonComponent,
    InputComponent,
    PlanDetailsComponent,
    LoginComponent,
    RegisterComponent,
    BadgeComponent,
    CardPlanComponent,
    BreadcrumbPurchaseComponent,
    CheckboxComponent,
    SelectComponent,
    TableCartComponent,
    PaymentComponent,
    OrderReviewComponent,
    BillingInfoComponent,
    TableMyPurchasesComponent,
    UserFormComponent,
    ProcessingPaymentComponent,
    HeaderPagesComponent,
    AdvertisementComponent,
    AccordionComponent,
    SearchBarHomeComponent,
    NotFoundPlanComponent,
    ConfirmDeleteComponent,
    UploadFileComponent,
    BlogPostComponent,
    RecoveryPasswordComponent,
    UpdatePasswordComponent,
    GenerateNewIdDialogComponent,
    UserDetailsComponent
  ],
  imports: [
    PipesModule,
    CommonModule,
    FormsModule,
    MatIconModule,
    ViewDocsModule,
    MatDialogModule,
    NgxTranslateModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    MatProgressSpinnerModule
  ],
  exports: [
    SearchBarComponent,
    TableListPlansComponent,
    ButtonComponent,
    InputComponent,
    PlanDetailsComponent,
    CardPlanComponent,
    BreadcrumbPurchaseComponent,
    LoginComponent,
    RegisterComponent,
    CheckboxComponent,
    SelectComponent,
    TableCartComponent,
    PaymentComponent,
    OrderReviewComponent,
    BillingInfoComponent,
    TableMyPurchasesComponent,
    UserFormComponent,
    ProcessingPaymentComponent,
    HeaderPagesComponent,
    AdvertisementComponent,
    AccordionComponent,
    SearchBarHomeComponent,
    NotFoundPlanComponent,
    BlogPostComponent,
    RecoveryPasswordComponent,
    UpdatePasswordComponent
  ]
})
export class ComponentsModule { }

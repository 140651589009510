import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../login/login.service';
import { Colors } from 'src/utils/colors';

@Component({
  selector: 'app-gs4-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  @Input() token: string;
  @Output() onBackToLogin = new EventEmitter();

  public Colors = Colors;
  public password: string;
  public confirmPassword: string;
  public showInfoScreen = false;
  public loading = false;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {}

  public updatePassword(): void {
    if (!this.password || !this.confirmPassword) {
      this.toastr.error('Please fill all the fields', 'Error');
      return;
    }

    if (this.password !== this.confirmPassword) {
      this.toastr.error('The passwords do not match', 'Error');
      return;
    }

    this.loading = true;

    this.loginService.changePassword({
      password: this.password,
      confirmPassword: this.confirmPassword,
      token: this.token
    }).subscribe((response) => {
      this.loading = false;
      if (response.status === 'success') {
        this.showInfoScreen = true;
      }
    }, error => {
      this.loading = false;
      this.toastr.error(error.error.message, 'Error');
    });
  }

  public getButtonTitle(): string {
    return this.showInfoScreen ? 'logIn' : 'createNewPassword';
  }

  public navigateTo(path: string): void {
    this.onBackToLogin.emit(true);
    this.router.navigate([path]);
  }
}

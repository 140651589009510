<div>
    <div class="text-center">
        <h3>{{ data.title | translate }}</h3>
        <p>{{ data.message | translate }}</p>
    </div>

    <div class="row">
        <div class="col-12 col-md-6 text-center">
            <app-button
                (click)="handleDelete()"
                buttonLabel="{{ 'delete' | translate }}"
                [buttonBGColor]="Colors.deepRed"
            ></app-button>
        </div>
        <div class="col-12 col-md-6 text-center">
            <app-button
                mat-dialog-close
                buttonLabel="{{ 'cancel' | translate }}"
                [buttonBGColor]="Colors.mediumGray"
            ></app-button>
        </div>
    </div>
</div>
export const StatesFromCountries = [
    {
        "country": {
            "label": "Afghanistan",
            "value": "afghanistan"
        },
        "states": [
            {
                "value": "badakhshan",
                "label": "Badakhshan"
            },
            {
                "value": "badghis",
                "label": "Badghis"
            },
            {
                "value": "baghlan",
                "label": "Baghlan"
            },
            {
                "value": "balkh",
                "label": "Balkh"
            },
            {
                "value": "bamian",
                "label": "Bamian"
            },
            {
                "value": "daykondi",
                "label": "Daykondi"
            },
            {
                "value": "farah",
                "label": "Farah"
            },
            {
                "value": "faryab",
                "label": "Faryab"
            },
            {
                "value": "ghazni",
                "label": "Ghazni"
            },
            {
                "value": "ghowr",
                "label": "Ghowr"
            },
            {
                "value": "helmand",
                "label": "Helmand"
            },
            {
                "value": "herat",
                "label": "Herat"
            },
            {
                "value": "jowzjan",
                "label": "Jowzjan"
            },
            {
                "value": "kabul",
                "label": "Kabul"
            },
            {
                "value": "kandahar",
                "label": "Kandahar"
            },
            {
                "value": "kapisa",
                "label": "Kapisa"
            },
            {
                "value": "khost",
                "label": "Khost"
            },
            {
                "value": "konar",
                "label": "Konar"
            },
            {
                "value": "kondoz",
                "label": "Kondoz"
            },
            {
                "value": "laghman",
                "label": "Laghman"
            },
            {
                "value": "lowgar",
                "label": "Lowgar"
            },
            {
                "value": "nangarhar",
                "label": "Nangarhar"
            },
            {
                "value": "nimruz",
                "label": "Nimruz"
            },
            {
                "value": "nurestan",
                "label": "Nurestan"
            },
            {
                "value": "oruzgan",
                "label": "Oruzgan"
            },
            {
                "value": "paktia",
                "label": "Paktia"
            },
            {
                "value": "paktika",
                "label": "Paktika"
            },
            {
                "value": "panjshir",
                "label": "Panjshir"
            },
            {
                "value": "parvan",
                "label": "Parvan"
            },
            {
                "value": "samangan",
                "label": "Samangan"
            },
            {
                "value": "sar-e pol",
                "label": "Sar-e Pol"
            },
            {
                "value": "takhar",
                "label": "Takhar"
            },
            {
                "value": "vardak",
                "label": "Vardak"
            },
            {
                "value": "zabol",
                "label": "Zabol"
            }
        ]
    },
    {
        "country": {
            "label": "Albania",
            "value": "albania"
        },
        "states": [
            {
                "value": "berat",
                "label": "Berat"
            },
            {
                "value": "dibres",
                "label": "Dibres"
            },
            {
                "value": "durres",
                "label": "Durres"
            },
            {
                "value": "elbasan",
                "label": "Elbasan"
            },
            {
                "value": "fier",
                "label": "Fier"
            },
            {
                "value": "gjirokastre",
                "label": "Gjirokastre"
            },
            {
                "value": "korce",
                "label": "Korce"
            },
            {
                "value": "kukes",
                "label": "Kukes"
            },
            {
                "value": "lezhe",
                "label": "Lezhe"
            },
            {
                "value": "shkoder",
                "label": "Shkoder"
            },
            {
                "value": "tirane",
                "label": "Tirane"
            },
            {
                "value": "vlore",
                "label": "Vlore"
            }
        ]
    },
    {
        "country": {
            "label": "Algeria",
            "value": "algeria"
        },
        "states": [
            {
                "value": "adrar",
                "label": "Adrar"
            },
            {
                "value": "ain defla",
                "label": "Ain Defla"
            },
            {
                "value": "ain temouchent",
                "label": "Ain Temouchent"
            },
            {
                "value": "alger",
                "label": "Alger"
            },
            {
                "value": "annaba",
                "label": "Annaba"
            },
            {
                "value": "batna",
                "label": "Batna"
            },
            {
                "value": "bechar",
                "label": "Bechar"
            },
            {
                "value": "bejaia",
                "label": "Bejaia"
            },
            {
                "value": "biskra",
                "label": "Biskra"
            },
            {
                "value": "blida",
                "label": "Blida"
            },
            {
                "value": "bordj bou arreridj",
                "label": "Bordj Bou Arreridj"
            },
            {
                "value": "bouira",
                "label": "Bouira"
            },
            {
                "value": "boumerdes",
                "label": "Boumerdes"
            },
            {
                "value": "chlef",
                "label": "Chlef"
            },
            {
                "value": "constantine",
                "label": "Constantine"
            },
            {
                "value": "djelfa",
                "label": "Djelfa"
            },
            {
                "value": "el bayadh",
                "label": "El Bayadh"
            },
            {
                "value": "el oued",
                "label": "El Oued"
            },
            {
                "value": "el tarf",
                "label": "El Tarf"
            },
            {
                "value": "ghardaia",
                "label": "Ghardaia"
            },
            {
                "value": "guelma",
                "label": "Guelma"
            },
            {
                "value": "illizi",
                "label": "Illizi"
            },
            {
                "value": "jijel",
                "label": "Jijel"
            },
            {
                "value": "khenchela",
                "label": "Khenchela"
            },
            {
                "value": "laghouat",
                "label": "Laghouat"
            },
            {
                "value": "muaskar",
                "label": "Muaskar"
            },
            {
                "value": "medea",
                "label": "Medea"
            },
            {
                "value": "mila",
                "label": "Mila"
            },
            {
                "value": "mostaganem",
                "label": "Mostaganem"
            },
            {
                "value": "m'sila",
                "label": "M'Sila"
            },
            {
                "value": "naama",
                "label": "Naama"
            },
            {
                "value": "oran",
                "label": "Oran"
            },
            {
                "value": "ouargla",
                "label": "Ouargla"
            },
            {
                "value": "oum el bouaghi",
                "label": "Oum el Bouaghi"
            },
            {
                "value": "relizane",
                "label": "Relizane"
            },
            {
                "value": "saida",
                "label": "Saida"
            },
            {
                "value": "setif",
                "label": "Setif"
            },
            {
                "value": "sidi bel abbes",
                "label": "Sidi Bel Abbes"
            },
            {
                "value": "skikda",
                "label": "Skikda"
            },
            {
                "value": "souk ahras",
                "label": "Souk Ahras"
            },
            {
                "value": "tamanghasset",
                "label": "Tamanghasset"
            },
            {
                "value": "tebessa",
                "label": "Tebessa"
            },
            {
                "value": "tiaret",
                "label": "Tiaret"
            },
            {
                "value": "tindouf",
                "label": "Tindouf"
            },
            {
                "value": "tipaza",
                "label": "Tipaza"
            },
            {
                "value": "tissemsilt",
                "label": "Tissemsilt"
            },
            {
                "value": "tizi ouzou",
                "label": "Tizi Ouzou"
            },
            {
                "value": "tlemcen",
                "label": "Tlemcen"
            }
        ]
    },
    {
        "country": {
            "label": "Andorra",
            "value": "andorra"
        },
        "states": [
            {
                "value": "andorra la vella",
                "label": "Andorra la Vella"
            },
            {
                "value": "canillo",
                "label": "Canillo"
            },
            {
                "value": "encamp",
                "label": "Encamp"
            },
            {
                "value": "escaldes-engordany",
                "label": "Escaldes-Engordany"
            },
            {
                "value": "la massana",
                "label": "La Massana"
            },
            {
                "value": "ordino",
                "label": "Ordino"
            },
            {
                "value": "sant julia de loria",
                "label": "Sant Julia de Loria"
            }
        ]
    },
    {
        "country": {
            "label": "Angola",
            "value": "angola"
        },
        "states": [
            {
                "value": "bengo",
                "label": "Bengo"
            },
            {
                "value": "benguela",
                "label": "Benguela"
            },
            {
                "value": "bie",
                "label": "Bie"
            },
            {
                "value": "cabinda",
                "label": "Cabinda"
            },
            {
                "value": "cuando cubango",
                "label": "Cuando Cubango"
            },
            {
                "value": "cuanza norte",
                "label": "Cuanza Norte"
            },
            {
                "value": "cuanza sul",
                "label": "Cuanza Sul"
            },
            {
                "value": "cunene",
                "label": "Cunene"
            },
            {
                "value": "huambo",
                "label": "Huambo"
            },
            {
                "value": "huila",
                "label": "Huila"
            },
            {
                "value": "luanda",
                "label": "Luanda"
            },
            {
                "value": "lunda norte",
                "label": "Lunda Norte"
            },
            {
                "value": "lunda sul",
                "label": "Lunda Sul"
            },
            {
                "value": "malanje",
                "label": "Malanje"
            },
            {
                "value": "moxico",
                "label": "Moxico"
            },
            {
                "value": "namibe",
                "label": "Namibe"
            },
            {
                "value": "uige",
                "label": "Uige"
            },
            {
                "value": "zaire",
                "label": "Zaire"
            }
        ]
    },
    {
        "country": {
            "label": "Antarctica",
            "value": "antarctica"
        },
        "states": []
    },
    {
        "country": {
            "label": "Antigua and Barbuda",
            "value": "antigua and barbuda"
        },
        "states": [
            {
                "value": "barbuda",
                "label": "Barbuda"
            },
            {
                "value": "redonda",
                "label": "Redonda"
            },
            {
                "value": "saint george",
                "label": "Saint George"
            },
            {
                "value": "saint john",
                "label": "Saint John"
            },
            {
                "value": "saint mary",
                "label": "Saint Mary"
            },
            {
                "value": "saint paul",
                "label": "Saint Paul"
            },
            {
                "value": "saint peter",
                "label": "Saint Peter"
            },
            {
                "value": "saint philip",
                "label": "Saint Philip"
            }
        ]
    },
    {
        "country": {
            "label": "Argentina",
            "value": "argentina"
        },
        "states": [
            {
                "value": "buenos aires",
                "label": "Buenos Aires"
            },
            {
                "value": "buenos aires capital",
                "label": "Buenos Aires Capital"
            },
            {
                "value": "catamarca",
                "label": "Catamarca"
            },
            {
                "value": "chaco",
                "label": "Chaco"
            },
            {
                "value": "chubut",
                "label": "Chubut"
            },
            {
                "value": "cordoba",
                "label": "Cordoba"
            },
            {
                "value": "corrientes",
                "label": "Corrientes"
            },
            {
                "value": "entre rios",
                "label": "Entre Rios"
            },
            {
                "value": "formosa",
                "label": "Formosa"
            },
            {
                "value": "jujuy",
                "label": "Jujuy"
            },
            {
                "value": "la pampa",
                "label": "La Pampa"
            },
            {
                "value": "la rioja",
                "label": "La Rioja"
            },
            {
                "value": "mendoza",
                "label": "Mendoza"
            },
            {
                "value": "misiones",
                "label": "Misiones"
            },
            {
                "value": "neuquen",
                "label": "Neuquen"
            },
            {
                "value": "rio negro",
                "label": "Rio Negro"
            },
            {
                "value": "salta",
                "label": "Salta"
            },
            {
                "value": "san juan",
                "label": "San Juan"
            },
            {
                "value": "san luis",
                "label": "San Luis"
            },
            {
                "value": "santa cruz",
                "label": "Santa Cruz"
            },
            {
                "value": "santa fe",
                "label": "Santa Fe"
            },
            {
                "value": "santiago del estero",
                "label": "Santiago del Estero"
            },
            {
                "value": "tierra del fuego",
                "label": "Tierra del Fuego"
            },
            {
                "value": "tucuman",
                "label": "Tucuman"
            }
        ]
    },
    {
        "country": {
            "label": "Armenia",
            "value": "armenia"
        },
        "states": [
            {
                "value": "aragatsotn",
                "label": "Aragatsotn"
            },
            {
                "value": "ararat",
                "label": "Ararat"
            },
            {
                "value": "armavir",
                "label": "Armavir"
            },
            {
                "value": "geghark'unik'",
                "label": "Geghark'unik'"
            },
            {
                "value": "kotayk'",
                "label": "Kotayk'"
            },
            {
                "value": "lorri",
                "label": "Lorri"
            },
            {
                "value": "shirak",
                "label": "Shirak"
            },
            {
                "value": "syunik'",
                "label": "Syunik'"
            },
            {
                "value": "tavush",
                "label": "Tavush"
            },
            {
                "value": "vayots' dzor",
                "label": "Vayots' Dzor"
            },
            {
                "value": "yerevan",
                "label": "Yerevan"
            }
        ]
    },
    {
        "country": {
            "label": "Australia",
            "value": "australia"
        },
        "states": []
    },
    {
        "country": {
            "label": "Austria",
            "value": "austria"
        },
        "states": [
            {
                "value": "burgenland",
                "label": "Burgenland"
            },
            {
                "value": "kaernten",
                "label": "Kaernten"
            },
            {
                "value": "niederoesterreich",
                "label": "Niederoesterreich"
            },
            {
                "value": "oberoesterreich",
                "label": "Oberoesterreich"
            },
            {
                "value": "salzburg",
                "label": "Salzburg"
            },
            {
                "value": "steiermark",
                "label": "Steiermark"
            },
            {
                "value": "tirol",
                "label": "Tirol"
            },
            {
                "value": "vorarlberg",
                "label": "Vorarlberg"
            },
            {
                "value": "wien",
                "label": "Wien"
            }
        ]
    },
    {
        "country": {
            "label": "Azerbaijan",
            "value": "azerbaijan"
        },
        "states": [
            {
                "value": "abseron rayonu",
                "label": "Abseron Rayonu"
            },
            {
                "value": "agcabadi rayonu",
                "label": "Agcabadi Rayonu"
            },
            {
                "value": "agdam rayonu",
                "label": "Agdam Rayonu"
            },
            {
                "value": "agdas rayonu",
                "label": "Agdas Rayonu"
            },
            {
                "value": "agstafa rayonu",
                "label": "Agstafa Rayonu"
            },
            {
                "value": "agsu rayonu",
                "label": "Agsu Rayonu"
            },
            {
                "value": "astara rayonu",
                "label": "Astara Rayonu"
            },
            {
                "value": "balakan rayonu",
                "label": "Balakan Rayonu"
            },
            {
                "value": "barda rayonu",
                "label": "Barda Rayonu"
            },
            {
                "value": "beylaqan rayonu",
                "label": "Beylaqan Rayonu"
            },
            {
                "value": "bilasuvar rayonu",
                "label": "Bilasuvar Rayonu"
            },
            {
                "value": "cabrayil rayonu",
                "label": "Cabrayil Rayonu"
            },
            {
                "value": "calilabad rayonu",
                "label": "Calilabad Rayonu"
            },
            {
                "value": "daskasan rayonu",
                "label": "Daskasan Rayonu"
            },
            {
                "value": "davaci rayonu",
                "label": "Davaci Rayonu"
            },
            {
                "value": "fuzuli rayonu",
                "label": "Fuzuli Rayonu"
            },
            {
                "value": "gadabay rayonu",
                "label": "Gadabay Rayonu"
            },
            {
                "value": "goranboy rayonu",
                "label": "Goranboy Rayonu"
            },
            {
                "value": "goycay rayonu",
                "label": "Goycay Rayonu"
            },
            {
                "value": "haciqabul rayonu",
                "label": "Haciqabul Rayonu"
            },
            {
                "value": "imisli rayonu",
                "label": "Imisli Rayonu"
            },
            {
                "value": "ismayilli rayonu",
                "label": "Ismayilli Rayonu"
            },
            {
                "value": "kalbacar rayonu",
                "label": "Kalbacar Rayonu"
            },
            {
                "value": "kurdamir rayonu",
                "label": "Kurdamir Rayonu"
            },
            {
                "value": "lacin rayonu",
                "label": "Lacin Rayonu"
            },
            {
                "value": "lankaran rayonu",
                "label": "Lankaran Rayonu"
            },
            {
                "value": "lerik rayonu",
                "label": "Lerik Rayonu"
            },
            {
                "value": "masalli rayonu",
                "label": "Masalli Rayonu"
            },
            {
                "value": "neftcala rayonu",
                "label": "Neftcala Rayonu"
            },
            {
                "value": "oguz rayonu",
                "label": "Oguz Rayonu"
            },
            {
                "value": "qabala rayonu",
                "label": "Qabala Rayonu"
            },
            {
                "value": "qax rayonu",
                "label": "Qax Rayonu"
            },
            {
                "value": "qazax rayonu",
                "label": "Qazax Rayonu"
            },
            {
                "value": "qobustan rayonu",
                "label": "Qobustan Rayonu"
            },
            {
                "value": "quba rayonu",
                "label": "Quba Rayonu"
            },
            {
                "value": "qubadli rayonu",
                "label": "Qubadli Rayonu"
            },
            {
                "value": "qusar rayonu",
                "label": "Qusar Rayonu"
            },
            {
                "value": "saatli rayonu",
                "label": "Saatli Rayonu"
            },
            {
                "value": "sabirabad rayonu",
                "label": "Sabirabad Rayonu"
            },
            {
                "value": "saki rayonu",
                "label": "Saki Rayonu"
            },
            {
                "value": "salyan rayonu",
                "label": "Salyan Rayonu"
            },
            {
                "value": "samaxi rayonu",
                "label": "Samaxi Rayonu"
            },
            {
                "value": "samkir rayonu",
                "label": "Samkir Rayonu"
            },
            {
                "value": "samux rayonu",
                "label": "Samux Rayonu"
            },
            {
                "value": "siyazan rayonu",
                "label": "Siyazan Rayonu"
            },
            {
                "value": "susa rayonu",
                "label": "Susa Rayonu"
            },
            {
                "value": "tartar rayonu",
                "label": "Tartar Rayonu"
            },
            {
                "value": "tovuz rayonu",
                "label": "Tovuz Rayonu"
            },
            {
                "value": "ucar rayonu",
                "label": "Ucar Rayonu"
            },
            {
                "value": "xacmaz rayonu",
                "label": "Xacmaz Rayonu"
            },
            {
                "value": "xanlar rayonu",
                "label": "Xanlar Rayonu"
            },
            {
                "value": "xizi rayonu",
                "label": "Xizi Rayonu"
            },
            {
                "value": "xocali rayonu",
                "label": "Xocali Rayonu"
            },
            {
                "value": "xocavand rayonu",
                "label": "Xocavand Rayonu"
            },
            {
                "value": "yardimli rayonu",
                "label": "Yardimli Rayonu"
            },
            {
                "value": "yevlax rayonu",
                "label": "Yevlax Rayonu"
            },
            {
                "value": "zangilan rayonu",
                "label": "Zangilan Rayonu"
            },
            {
                "value": "zaqatala rayonu",
                "label": "Zaqatala Rayonu"
            },
            {
                "value": "zardab rayonu",
                "label": "Zardab Rayonu"
            },
            {
                "value": "ali bayramli sahari",
                "label": "Ali Bayramli Sahari"
            },
            {
                "value": "baki sahari",
                "label": "Baki Sahari"
            },
            {
                "value": "ganca sahari",
                "label": "Ganca Sahari"
            },
            {
                "value": "lankaran sahari",
                "label": "Lankaran Sahari"
            },
            {
                "value": "mingacevir sahari",
                "label": "Mingacevir Sahari"
            },
            {
                "value": "naftalan sahari",
                "label": "Naftalan Sahari"
            },
            {
                "value": "saki sahari",
                "label": "Saki Sahari"
            },
            {
                "value": "sumqayit sahari",
                "label": "Sumqayit Sahari"
            },
            {
                "value": "susa sahari",
                "label": "Susa Sahari"
            },
            {
                "value": "xankandi sahari",
                "label": "Xankandi Sahari"
            },
            {
                "value": "yevlax sahari",
                "label": "Yevlax Sahari"
            },
            {
                "value": "naxcivan muxtar",
                "label": "Naxcivan Muxtar"
            }
        ]
    },
    {
        "country": {
            "label": "Bahamas",
            "value": "bahamas"
        },
        "states": [
            {
                "value": "acklins and crooked islands",
                "label": "Acklins and Crooked Islands"
            },
            {
                "value": "bimini",
                "label": "Bimini"
            },
            {
                "value": "cat island",
                "label": "Cat Island"
            },
            {
                "value": "exuma",
                "label": "Exuma"
            },
            {
                "value": "freeport",
                "label": "Freeport"
            },
            {
                "value": "fresh creek",
                "label": "Fresh Creek"
            },
            {
                "value": "governor's harbour",
                "label": "Governor's Harbour"
            },
            {
                "value": "green turtle cay",
                "label": "Green Turtle Cay"
            },
            {
                "value": "harbour island",
                "label": "Harbour Island"
            },
            {
                "value": "high rock",
                "label": "High Rock"
            },
            {
                "value": "inagua",
                "label": "Inagua"
            },
            {
                "value": "kemps bay",
                "label": "Kemps Bay"
            },
            {
                "value": "long island",
                "label": "Long Island"
            },
            {
                "value": "marsh harbour",
                "label": "Marsh Harbour"
            },
            {
                "value": "mayaguana",
                "label": "Mayaguana"
            },
            {
                "value": "new providence",
                "label": "New Providence"
            },
            {
                "value": "nichollstown and berry islands",
                "label": "Nichollstown and Berry Islands"
            },
            {
                "value": "ragged island",
                "label": "Ragged Island"
            },
            {
                "value": "rock sound",
                "label": "Rock Sound"
            },
            {
                "value": "sandy point",
                "label": "Sandy Point"
            },
            {
                "value": "san salvador and rum cay",
                "label": "San Salvador and Rum Cay"
            }
        ]
    },
    {
        "country": {
            "label": "Bahrain",
            "value": "bahrain"
        },
        "states": [
            {
                "value": "al hadd",
                "label": "Al Hadd"
            },
            {
                "value": "al manamah",
                "label": "Al Manamah"
            },
            {
                "value": "al mintaqah al gharbiyah",
                "label": "Al Mintaqah al Gharbiyah"
            },
            {
                "value": "al mintaqah al wusta",
                "label": "Al Mintaqah al Wusta"
            },
            {
                "value": "al mintaqah ash shamaliyah",
                "label": "Al Mintaqah ash Shamaliyah"
            },
            {
                "value": "al muharraq",
                "label": "Al Muharraq"
            },
            {
                "value": "ar rifa' wa al mintaqah al janubiyah",
                "label": "Ar Rifa' wa al Mintaqah al Janubiyah"
            },
            {
                "value": "jidd hafs",
                "label": "Jidd Hafs"
            },
            {
                "value": "madinat hamad",
                "label": "Madinat Hamad"
            },
            {
                "value": "madinat 'isa",
                "label": "Madinat 'Isa"
            },
            {
                "value": "juzur hawar",
                "label": "Juzur Hawar"
            },
            {
                "value": "sitrah",
                "label": "Sitrah"
            }
        ]
    },
    {
        "country": {
            "label": "Bangladesh",
            "value": "bangladesh"
        },
        "states": [
            {
                "value": "barisal",
                "label": "Barisal"
            },
            {
                "value": "chittagong",
                "label": "Chittagong"
            },
            {
                "value": "dhaka",
                "label": "Dhaka"
            },
            {
                "value": "khulna",
                "label": "Khulna"
            },
            {
                "value": "rajshahi",
                "label": "Rajshahi"
            },
            {
                "value": "sylhet",
                "label": "Sylhet"
            }
        ]
    },
    {
        "country": {
            "label": "Barbados",
            "value": "barbados"
        },
        "states": [
            {
                "value": "christ church",
                "label": "Christ Church"
            },
            {
                "value": "saint andrew",
                "label": "Saint Andrew"
            },
            {
                "value": "saint george",
                "label": "Saint George"
            },
            {
                "value": "saint james",
                "label": "Saint James"
            },
            {
                "value": "saint john",
                "label": "Saint John"
            },
            {
                "value": "saint joseph",
                "label": "Saint Joseph"
            },
            {
                "value": "saint lucy",
                "label": "Saint Lucy"
            },
            {
                "value": "saint michael",
                "label": "Saint Michael"
            },
            {
                "value": "saint peter",
                "label": "Saint Peter"
            },
            {
                "value": "saint philip",
                "label": "Saint Philip"
            },
            {
                "value": "saint thomas",
                "label": "Saint Thomas"
            }
        ]
    },
    {
        "country": {
            "label": "Belarus",
            "value": "belarus"
        },
        "states": [
            {
                "value": "brest",
                "label": "Brest"
            },
            {
                "value": "homyel",
                "label": "Homyel"
            },
            {
                "value": "horad minsk",
                "label": "Horad Minsk"
            },
            {
                "value": "hrodna",
                "label": "Hrodna"
            },
            {
                "value": "mahilyow",
                "label": "Mahilyow"
            },
            {
                "value": "minsk",
                "label": "Minsk"
            },
            {
                "value": "vitsyebsk",
                "label": "Vitsyebsk"
            }
        ]
    },
    {
        "country": {
            "label": "Belgium",
            "value": "belgium"
        },
        "states": [
            {
                "value": "antwerpen",
                "label": "Antwerpen"
            },
            {
                "value": "brabant wallon",
                "label": "Brabant Wallon"
            },
            {
                "value": "brussels",
                "label": "Brussels"
            },
            {
                "value": "flanders",
                "label": "Flanders"
            },
            {
                "value": "hainaut",
                "label": "Hainaut"
            },
            {
                "value": "liege",
                "label": "Liege"
            },
            {
                "value": "limburg",
                "label": "Limburg"
            },
            {
                "value": "luxembourg",
                "label": "Luxembourg"
            },
            {
                "value": "namur",
                "label": "Namur"
            },
            {
                "value": "oost-vlaanderen",
                "label": "Oost-Vlaanderen"
            },
            {
                "value": "vlaams-brabant",
                "label": "Vlaams-Brabant"
            },
            {
                "value": "wallonia",
                "label": "Wallonia"
            },
            {
                "value": "west-vlaanderen",
                "label": "West-Vlaanderen"
            }
        ]
    },
    {
        "country": {
            "label": "Belize",
            "value": "belize"
        },
        "states": [
            {
                "value": "belize",
                "label": "Belize"
            },
            {
                "value": "cayo",
                "label": "Cayo"
            },
            {
                "value": "corozal",
                "label": "Corozal"
            },
            {
                "value": "orange walk",
                "label": "Orange Walk"
            },
            {
                "value": "stann creek",
                "label": "Stann Creek"
            },
            {
                "value": "toledo",
                "label": "Toledo"
            }
        ]
    },
    {
        "country": {
            "label": "Benin",
            "value": "benin"
        },
        "states": [
            {
                "value": "alibori",
                "label": "Alibori"
            },
            {
                "value": "atakora",
                "label": "Atakora"
            },
            {
                "value": "atlantique",
                "label": "Atlantique"
            },
            {
                "value": "borgou",
                "label": "Borgou"
            },
            {
                "value": "collines",
                "label": "Collines"
            },
            {
                "value": "donga",
                "label": "Donga"
            },
            {
                "value": "kouffo",
                "label": "Kouffo"
            },
            {
                "value": "littoral",
                "label": "Littoral"
            },
            {
                "value": "mono",
                "label": "Mono"
            },
            {
                "value": "oueme",
                "label": "Oueme"
            },
            {
                "value": "plateau",
                "label": "Plateau"
            },
            {
                "value": "zou",
                "label": "Zou"
            }
        ]
    },
    {
        "country": {
            "label": "Bermuda",
            "value": "bermuda"
        },
        "states": [
            {
                "value": "devonshire",
                "label": "Devonshire"
            },
            {
                "value": "hamilton",
                "label": "Hamilton"
            },
            {
                "value": "hamilton",
                "label": "Hamilton"
            },
            {
                "value": "paget",
                "label": "Paget"
            },
            {
                "value": "pembroke",
                "label": "Pembroke"
            },
            {
                "value": "saint george",
                "label": "Saint George"
            },
            {
                "value": "saint george's",
                "label": "Saint George's"
            },
            {
                "value": "sandys",
                "label": "Sandys"
            },
            {
                "value": "smith's",
                "label": "Smith's"
            },
            {
                "value": "southampton",
                "label": "Southampton"
            },
            {
                "value": "warwick",
                "label": "Warwick"
            }
        ]
    },
    {
        "country": {
            "label": "Bhutan",
            "value": "bhutan"
        },
        "states": [
            {
                "value": "bumthang",
                "label": "Bumthang"
            },
            {
                "value": "chukha",
                "label": "Chukha"
            },
            {
                "value": "dagana",
                "label": "Dagana"
            },
            {
                "value": "gasa",
                "label": "Gasa"
            },
            {
                "value": "haa",
                "label": "Haa"
            },
            {
                "value": "lhuntse",
                "label": "Lhuntse"
            },
            {
                "value": "mongar",
                "label": "Mongar"
            },
            {
                "value": "paro",
                "label": "Paro"
            },
            {
                "value": "pemagatshel",
                "label": "Pemagatshel"
            },
            {
                "value": "punakha",
                "label": "Punakha"
            },
            {
                "value": "samdrup jongkhar",
                "label": "Samdrup Jongkhar"
            },
            {
                "value": "samtse",
                "label": "Samtse"
            },
            {
                "value": "sarpang",
                "label": "Sarpang"
            },
            {
                "value": "thimphu",
                "label": "Thimphu"
            },
            {
                "value": "trashigang",
                "label": "Trashigang"
            },
            {
                "value": "trashiyangste",
                "label": "Trashiyangste"
            },
            {
                "value": "trongsa",
                "label": "Trongsa"
            },
            {
                "value": "tsirang",
                "label": "Tsirang"
            },
            {
                "value": "wangdue phodrang",
                "label": "Wangdue Phodrang"
            },
            {
                "value": "zhemgang",
                "label": "Zhemgang"
            }
        ]
    },
    {
        "country": {
            "label": "Bolivia",
            "value": "bolivia"
        },
        "states": [
            {
                "value": "chuquisaca",
                "label": "Chuquisaca"
            },
            {
                "value": "cochabamba",
                "label": "Cochabamba"
            },
            {
                "value": "beni",
                "label": "Beni"
            },
            {
                "value": "la paz",
                "label": "La Paz"
            },
            {
                "value": "oruro",
                "label": "Oruro"
            },
            {
                "value": "pando",
                "label": "Pando"
            },
            {
                "value": "potosi",
                "label": "Potosi"
            },
            {
                "value": "santa cruz",
                "label": "Santa Cruz"
            },
            {
                "value": "tarija",
                "label": "Tarija"
            }
        ]
    },
    {
        "country": {
            "label": "Bosnia and Herzegovina",
            "value": "bosnia and herzegovina"
        },
        "states": [
            {
                "value": "una-sana [federation]",
                "label": "Una-Sana [Federation]"
            },
            {
                "value": "posavina [federation]",
                "label": "Posavina [Federation]"
            },
            {
                "value": "tuzla [federation]",
                "label": "Tuzla [Federation]"
            },
            {
                "value": "zenica-doboj [federation]",
                "label": "Zenica-Doboj [Federation]"
            },
            {
                "value": "bosnian podrinje [federation]",
                "label": "Bosnian Podrinje [Federation]"
            },
            {
                "value": "central bosnia [federation]",
                "label": "Central Bosnia [Federation]"
            },
            {
                "value": "herzegovina-neretva [federation]",
                "label": "Herzegovina-Neretva [Federation]"
            },
            {
                "value": "west herzegovina [federation]",
                "label": "West Herzegovina [Federation]"
            },
            {
                "value": "sarajevo [federation]",
                "label": "Sarajevo [Federation]"
            },
            {
                "value": " west bosnia [federation]",
                "label": " West Bosnia [Federation]"
            },
            {
                "value": "banja luka [rs]",
                "label": "Banja Luka [RS]"
            },
            {
                "value": "bijeljina [rs]",
                "label": "Bijeljina [RS]"
            },
            {
                "value": "doboj [rs]",
                "label": "Doboj [RS]"
            },
            {
                "value": "fo?a [rs]",
                "label": "Fo?a [RS]"
            },
            {
                "value": "sarajevo-romanija [rs]",
                "label": "Sarajevo-Romanija [RS]"
            },
            {
                "value": "trebinje [rs]",
                "label": "Trebinje [RS]"
            },
            {
                "value": "vlasenica [rs]",
                "label": "Vlasenica [RS]"
            }
        ]
    },
    {
        "country": {
            "label": "Botswana",
            "value": "botswana"
        },
        "states": [
            {
                "value": "central",
                "label": "Central"
            },
            {
                "value": "ghanzi",
                "label": "Ghanzi"
            },
            {
                "value": "kgalagadi",
                "label": "Kgalagadi"
            },
            {
                "value": "kgatleng",
                "label": "Kgatleng"
            },
            {
                "value": "kweneng",
                "label": "Kweneng"
            },
            {
                "value": "north east",
                "label": "North East"
            },
            {
                "value": "north west",
                "label": "North West"
            },
            {
                "value": "south east",
                "label": "South East"
            },
            {
                "value": "southern",
                "label": "Southern"
            }
        ]
    },
    {
        "country": {
            "label": "Brazil",
            "value": "brazil"
        },
        "states": [
            {
                "value": "acre",
                "label": "Acre"
            },
            {
                "value": "alagoas",
                "label": "Alagoas"
            },
            {
                "value": "amapa",
                "label": "Amapa"
            },
            {
                "value": "amazonas",
                "label": "Amazonas"
            },
            {
                "value": "bahia",
                "label": "Bahia"
            },
            {
                "value": "ceara",
                "label": "Ceara"
            },
            {
                "value": "distrito federal",
                "label": "Distrito Federal"
            },
            {
                "value": "espirito santo",
                "label": "Espirito Santo"
            },
            {
                "value": "goias",
                "label": "Goias"
            },
            {
                "value": "maranhao",
                "label": "Maranhao"
            },
            {
                "value": "mato grosso",
                "label": "Mato Grosso"
            },
            {
                "value": "mato grosso do sul",
                "label": "Mato Grosso do Sul"
            },
            {
                "value": "minas gerais",
                "label": "Minas Gerais"
            },
            {
                "value": "para",
                "label": "Para"
            },
            {
                "value": "paraiba",
                "label": "Paraiba"
            },
            {
                "value": "parana",
                "label": "Parana"
            },
            {
                "value": "pernambuco",
                "label": "Pernambuco"
            },
            {
                "value": "piaui",
                "label": "Piaui"
            },
            {
                "value": "rio de janeiro",
                "label": "Rio de Janeiro"
            },
            {
                "value": "rio grande do norte",
                "label": "Rio Grande do Norte"
            },
            {
                "value": "rio grande do sul",
                "label": "Rio Grande do Sul"
            },
            {
                "value": "rondonia",
                "label": "Rondonia"
            },
            {
                "value": "roraima",
                "label": "Roraima"
            },
            {
                "value": "santa catarina",
                "label": "Santa Catarina"
            },
            {
                "value": "sao paulo",
                "label": "Sao Paulo"
            },
            {
                "value": "sergipe",
                "label": "Sergipe"
            },
            {
                "value": "tocantins",
                "label": "Tocantins"
            }
        ]
    },
    {
        "country": {
            "label": "Brunei",
            "value": "brunei"
        },
        "states": [
            {
                "value": "belait",
                "label": "Belait"
            },
            {
                "value": "brunei and muara",
                "label": "Brunei and Muara"
            },
            {
                "value": "temburong",
                "label": "Temburong"
            },
            {
                "value": "tutong",
                "label": "Tutong"
            }
        ]
    },
    {
        "country": {
            "label": "Bulgaria",
            "value": "bulgaria"
        },
        "states": [
            {
                "value": "blagoevgrad",
                "label": "Blagoevgrad"
            },
            {
                "value": "burgas",
                "label": "Burgas"
            },
            {
                "value": "dobrich",
                "label": "Dobrich"
            },
            {
                "value": "gabrovo",
                "label": "Gabrovo"
            },
            {
                "value": "khaskovo",
                "label": "Khaskovo"
            },
            {
                "value": "kurdzhali",
                "label": "Kurdzhali"
            },
            {
                "value": "kyustendil",
                "label": "Kyustendil"
            },
            {
                "value": "lovech",
                "label": "Lovech"
            },
            {
                "value": "montana",
                "label": "Montana"
            },
            {
                "value": "pazardzhik",
                "label": "Pazardzhik"
            },
            {
                "value": "pernik",
                "label": "Pernik"
            },
            {
                "value": "pleven",
                "label": "Pleven"
            },
            {
                "value": "plovdiv",
                "label": "Plovdiv"
            },
            {
                "value": "razgrad",
                "label": "Razgrad"
            },
            {
                "value": "ruse",
                "label": "Ruse"
            },
            {
                "value": "shumen",
                "label": "Shumen"
            },
            {
                "value": "silistra",
                "label": "Silistra"
            },
            {
                "value": "sliven",
                "label": "Sliven"
            },
            {
                "value": "smolyan",
                "label": "Smolyan"
            },
            {
                "value": "sofiya",
                "label": "Sofiya"
            },
            {
                "value": "sofiya-grad",
                "label": "Sofiya-Grad"
            },
            {
                "value": "stara zagora",
                "label": "Stara Zagora"
            },
            {
                "value": "turgovishte",
                "label": "Turgovishte"
            },
            {
                "value": "varna",
                "label": "Varna"
            },
            {
                "value": "veliko turnovo",
                "label": "Veliko Turnovo"
            },
            {
                "value": "vidin",
                "label": "Vidin"
            },
            {
                "value": "vratsa",
                "label": "Vratsa"
            },
            {
                "value": "yambol",
                "label": "Yambol"
            }
        ]
    },
    {
        "country": {
            "label": "Burkina Faso",
            "value": "burkina faso"
        },
        "states": [
            {
                "value": "bale",
                "label": "Bale"
            },
            {
                "value": "bam",
                "label": "Bam"
            },
            {
                "value": "banwa",
                "label": "Banwa"
            },
            {
                "value": "bazega",
                "label": "Bazega"
            },
            {
                "value": "bougouriba",
                "label": "Bougouriba"
            },
            {
                "value": "boulgou",
                "label": "Boulgou"
            },
            {
                "value": "boulkiemde",
                "label": "Boulkiemde"
            },
            {
                "value": "comoe",
                "label": "Comoe"
            },
            {
                "value": "ganzourgou",
                "label": "Ganzourgou"
            },
            {
                "value": "gnagna",
                "label": "Gnagna"
            },
            {
                "value": "gourma",
                "label": "Gourma"
            },
            {
                "value": "houet",
                "label": "Houet"
            },
            {
                "value": "ioba",
                "label": "Ioba"
            },
            {
                "value": "kadiogo",
                "label": "Kadiogo"
            },
            {
                "value": "kenedougou",
                "label": "Kenedougou"
            },
            {
                "value": "komondjari",
                "label": "Komondjari"
            },
            {
                "value": "kompienga",
                "label": "Kompienga"
            },
            {
                "value": "kossi",
                "label": "Kossi"
            },
            {
                "value": "koulpelogo",
                "label": "Koulpelogo"
            },
            {
                "value": "kouritenga",
                "label": "Kouritenga"
            },
            {
                "value": "kourweogo",
                "label": "Kourweogo"
            },
            {
                "value": "leraba",
                "label": "Leraba"
            },
            {
                "value": "loroum",
                "label": "Loroum"
            },
            {
                "value": "mouhoun",
                "label": "Mouhoun"
            },
            {
                "value": "namentenga",
                "label": "Namentenga"
            },
            {
                "value": "nahouri",
                "label": "Nahouri"
            },
            {
                "value": "nayala",
                "label": "Nayala"
            },
            {
                "value": "noumbiel",
                "label": "Noumbiel"
            },
            {
                "value": "oubritenga",
                "label": "Oubritenga"
            },
            {
                "value": "oudalan",
                "label": "Oudalan"
            },
            {
                "value": "passore",
                "label": "Passore"
            },
            {
                "value": "poni",
                "label": "Poni"
            },
            {
                "value": "sanguie",
                "label": "Sanguie"
            },
            {
                "value": "sanmatenga",
                "label": "Sanmatenga"
            },
            {
                "value": "seno",
                "label": "Seno"
            },
            {
                "value": "sissili",
                "label": "Sissili"
            },
            {
                "value": "soum",
                "label": "Soum"
            },
            {
                "value": "sourou",
                "label": "Sourou"
            },
            {
                "value": "tapoa",
                "label": "Tapoa"
            },
            {
                "value": "tuy",
                "label": "Tuy"
            },
            {
                "value": "yagha",
                "label": "Yagha"
            },
            {
                "value": "yatenga",
                "label": "Yatenga"
            },
            {
                "value": "ziro",
                "label": "Ziro"
            },
            {
                "value": "zondoma",
                "label": "Zondoma"
            },
            {
                "value": "zoundweogo",
                "label": "Zoundweogo"
            }
        ]
    },
    {
        "country": {
            "label": "Burma",
            "value": "burma"
        },
        "states": [
            {
                "value": "ayeyarwady",
                "label": "Ayeyarwady"
            },
            {
                "value": "bago",
                "label": "Bago"
            },
            {
                "value": "magway",
                "label": "Magway"
            },
            {
                "value": "mandalay",
                "label": "Mandalay"
            },
            {
                "value": "sagaing",
                "label": "Sagaing"
            },
            {
                "value": "tanintharyi",
                "label": "Tanintharyi"
            },
            {
                "value": "yangon",
                "label": "Yangon"
            },
            {
                "value": "chin state",
                "label": "Chin State"
            },
            {
                "value": "kachin state",
                "label": "Kachin State"
            },
            {
                "value": "kayin state",
                "label": "Kayin State"
            },
            {
                "value": "kayah state",
                "label": "Kayah State"
            },
            {
                "value": "mon state",
                "label": "Mon State"
            },
            {
                "value": "rakhine state",
                "label": "Rakhine State"
            },
            {
                "value": "shan state",
                "label": "Shan State"
            }
        ]
    },
    {
        "country": {
            "label": "Burundi",
            "value": "burundi"
        },
        "states": [
            {
                "value": "bubanza",
                "label": "Bubanza"
            },
            {
                "value": "bujumbura mairie",
                "label": "Bujumbura Mairie"
            },
            {
                "value": "bujumbura rural",
                "label": "Bujumbura Rural"
            },
            {
                "value": "bururi",
                "label": "Bururi"
            },
            {
                "value": "cankuzo",
                "label": "Cankuzo"
            },
            {
                "value": "cibitoke",
                "label": "Cibitoke"
            },
            {
                "value": "gitega",
                "label": "Gitega"
            },
            {
                "value": "karuzi",
                "label": "Karuzi"
            },
            {
                "value": "kayanza",
                "label": "Kayanza"
            },
            {
                "value": "kirundo",
                "label": "Kirundo"
            },
            {
                "value": "makamba",
                "label": "Makamba"
            },
            {
                "value": "muramvya",
                "label": "Muramvya"
            },
            {
                "value": "muyinga",
                "label": "Muyinga"
            },
            {
                "value": "mwaro",
                "label": "Mwaro"
            },
            {
                "value": "ngozi",
                "label": "Ngozi"
            },
            {
                "value": "rutana",
                "label": "Rutana"
            },
            {
                "value": "ruyigi",
                "label": "Ruyigi"
            }
        ]
    },
    {
        "country": {
            "label": "Cambodia",
            "value": "cambodia"
        },
        "states": [
            {
                "value": "banteay mean chey",
                "label": "Banteay Mean Chey"
            },
            {
                "value": "batdambang",
                "label": "Batdambang"
            },
            {
                "value": "kampong cham",
                "label": "Kampong Cham"
            },
            {
                "value": "kampong chhnang",
                "label": "Kampong Chhnang"
            },
            {
                "value": "kampong spoe",
                "label": "Kampong Spoe"
            },
            {
                "value": "kampong thum",
                "label": "Kampong Thum"
            },
            {
                "value": "kampot",
                "label": "Kampot"
            },
            {
                "value": "kandal",
                "label": "Kandal"
            },
            {
                "value": "koh kong",
                "label": "Koh Kong"
            },
            {
                "value": "kracheh",
                "label": "Kracheh"
            },
            {
                "value": "mondol kiri",
                "label": "Mondol Kiri"
            },
            {
                "value": "otdar mean chey",
                "label": "Otdar Mean Chey"
            },
            {
                "value": "pouthisat",
                "label": "Pouthisat"
            },
            {
                "value": "preah vihear",
                "label": "Preah Vihear"
            },
            {
                "value": "prey veng",
                "label": "Prey Veng"
            },
            {
                "value": "rotanakir",
                "label": "Rotanakir"
            },
            {
                "value": "siem reab",
                "label": "Siem Reab"
            },
            {
                "value": "stoeng treng",
                "label": "Stoeng Treng"
            },
            {
                "value": "svay rieng",
                "label": "Svay Rieng"
            },
            {
                "value": "takao",
                "label": "Takao"
            },
            {
                "value": "keb",
                "label": "Keb"
            },
            {
                "value": "pailin",
                "label": "Pailin"
            },
            {
                "value": "phnom penh",
                "label": "Phnom Penh"
            },
            {
                "value": "preah seihanu",
                "label": "Preah Seihanu"
            }
        ]
    },
    {
        "country": {
            "label": "Cameroon",
            "value": "cameroon"
        },
        "states": [
            {
                "value": "adamaoua",
                "label": "Adamaoua"
            },
            {
                "value": "centre",
                "label": "Centre"
            },
            {
                "value": "est",
                "label": "Est"
            },
            {
                "value": "extreme-nord",
                "label": "Extreme-Nord"
            },
            {
                "value": "littoral",
                "label": "Littoral"
            },
            {
                "value": "nord",
                "label": "Nord"
            },
            {
                "value": "nord-ouest",
                "label": "Nord-Ouest"
            },
            {
                "value": "ouest",
                "label": "Ouest"
            },
            {
                "value": "sud",
                "label": "Sud"
            },
            {
                "value": "sud-ouest",
                "label": "Sud-Ouest"
            }
        ]
    },
    {
        "country": {
            "label": "Canada",
            "value": "canada"
        },
        "states": [
            {
                "value": "alberta",
                "label": "Alberta"
            },
            {
                "value": "british columbia",
                "label": "British Columbia"
            },
            {
                "value": "manitoba",
                "label": "Manitoba"
            },
            {
                "value": "new brunswick",
                "label": "New Brunswick"
            },
            {
                "value": "newfoundland and labrador",
                "label": "Newfoundland and Labrador"
            },
            {
                "value": "northwest territories",
                "label": "Northwest Territories"
            },
            {
                "value": "nova scotia",
                "label": "Nova Scotia"
            },
            {
                "value": "nunavut",
                "label": "Nunavut"
            },
            {
                "value": "ontario",
                "label": "Ontario"
            },
            {
                "value": "prince edward island",
                "label": "Prince Edward Island"
            },
            {
                "value": "quebec",
                "label": "Quebec"
            },
            {
                "value": "saskatchewan",
                "label": "Saskatchewan"
            },
            {
                "value": "yukon territory",
                "label": "Yukon Territory"
            }
        ]
    },
    {
        "country": {
            "label": "Cape Verde",
            "value": "cape verde"
        },
        "states": []
    },
    {
        "country": {
            "label": "Central African Republic",
            "value": "central african republic"
        },
        "states": [
            {
                "value": "bamingui-bangoran",
                "label": "Bamingui-Bangoran"
            },
            {
                "value": "bangui",
                "label": "Bangui"
            },
            {
                "value": "basse-kotto",
                "label": "Basse-Kotto"
            },
            {
                "value": "haute-kotto",
                "label": "Haute-Kotto"
            },
            {
                "value": "haut-mbomou",
                "label": "Haut-Mbomou"
            },
            {
                "value": "kemo",
                "label": "Kemo"
            },
            {
                "value": "lobaye",
                "label": "Lobaye"
            },
            {
                "value": "mambere-kadei",
                "label": "Mambere-Kadei"
            },
            {
                "value": "mbomou",
                "label": "Mbomou"
            },
            {
                "value": "nana-grebizi",
                "label": "Nana-Grebizi"
            },
            {
                "value": "nana-mambere",
                "label": "Nana-Mambere"
            },
            {
                "value": "ombella-mpoko",
                "label": "Ombella-Mpoko"
            },
            {
                "value": "ouaka",
                "label": "Ouaka"
            },
            {
                "value": "ouham",
                "label": "Ouham"
            },
            {
                "value": "ouham-pende",
                "label": "Ouham-Pende"
            },
            {
                "value": "sangha-mbaere",
                "label": "Sangha-Mbaere"
            },
            {
                "value": "vakaga",
                "label": "Vakaga"
            }
        ]
    },
    {
        "country": {
            "label": "Chad",
            "value": "chad"
        },
        "states": [
            {
                "value": "batha",
                "label": "Batha"
            },
            {
                "value": "biltine",
                "label": "Biltine"
            },
            {
                "value": "borkou-ennedi-tibesti",
                "label": "Borkou-Ennedi-Tibesti"
            },
            {
                "value": "chari-baguirmi",
                "label": "Chari-Baguirmi"
            },
            {
                "value": "guéra",
                "label": "Guéra"
            },
            {
                "value": "kanem",
                "label": "Kanem"
            },
            {
                "value": "lac",
                "label": "Lac"
            },
            {
                "value": "logone occidental",
                "label": "Logone Occidental"
            },
            {
                "value": "logone oriental",
                "label": "Logone Oriental"
            },
            {
                "value": "mayo-kebbi",
                "label": "Mayo-Kebbi"
            },
            {
                "value": "moyen-chari",
                "label": "Moyen-Chari"
            },
            {
                "value": "ouaddaï",
                "label": "Ouaddaï"
            },
            {
                "value": "salamat",
                "label": "Salamat"
            },
            {
                "value": "tandjile",
                "label": "Tandjile"
            }
        ]
    },
    {
        "country": {
            "label": "Chile",
            "value": "chile"
        },
        "states": [
            {
                "value": "aysen",
                "label": "Aysen"
            },
            {
                "value": "antofagasta",
                "label": "Antofagasta"
            },
            {
                "value": "araucania",
                "label": "Araucania"
            },
            {
                "value": "atacama",
                "label": "Atacama"
            },
            {
                "value": "bio-bio",
                "label": "Bio-Bio"
            },
            {
                "value": "coquimbo",
                "label": "Coquimbo"
            },
            {
                "value": "o'higgins",
                "label": "O'Higgins"
            },
            {
                "value": "los lagos",
                "label": "Los Lagos"
            },
            {
                "value": "magallanes y la antartica chilena",
                "label": "Magallanes y la Antartica Chilena"
            },
            {
                "value": "maule",
                "label": "Maule"
            },
            {
                "value": "santiago region metropolitana",
                "label": "Santiago Region Metropolitana"
            },
            {
                "value": "tarapaca",
                "label": "Tarapaca"
            },
            {
                "value": "valparaiso",
                "label": "Valparaiso"
            }
        ]
    },
    {
        "country": {
            "label": "China",
            "value": "china"
        },
        "states": [
            {
                "value": "anhui",
                "label": "Anhui"
            },
            {
                "value": "fujian",
                "label": "Fujian"
            },
            {
                "value": "gansu",
                "label": "Gansu"
            },
            {
                "value": "guangdong",
                "label": "Guangdong"
            },
            {
                "value": "guizhou",
                "label": "Guizhou"
            },
            {
                "value": "hainan",
                "label": "Hainan"
            },
            {
                "value": "hebei",
                "label": "Hebei"
            },
            {
                "value": "heilongjiang",
                "label": "Heilongjiang"
            },
            {
                "value": "henan",
                "label": "Henan"
            },
            {
                "value": "hubei",
                "label": "Hubei"
            },
            {
                "value": "hunan",
                "label": "Hunan"
            },
            {
                "value": "jiangsu",
                "label": "Jiangsu"
            },
            {
                "value": "jiangxi",
                "label": "Jiangxi"
            },
            {
                "value": "jilin",
                "label": "Jilin"
            },
            {
                "value": "liaoning",
                "label": "Liaoning"
            },
            {
                "value": "qinghai",
                "label": "Qinghai"
            },
            {
                "value": "shaanxi",
                "label": "Shaanxi"
            },
            {
                "value": "shandong",
                "label": "Shandong"
            },
            {
                "value": "shanxi",
                "label": "Shanxi"
            },
            {
                "value": "sichuan",
                "label": "Sichuan"
            },
            {
                "value": "yunnan",
                "label": "Yunnan"
            },
            {
                "value": "zhejiang",
                "label": "Zhejiang"
            },
            {
                "value": "guangxi",
                "label": "Guangxi"
            },
            {
                "value": "nei mongol",
                "label": "Nei Mongol"
            },
            {
                "value": "ningxia",
                "label": "Ningxia"
            },
            {
                "value": "xinjiang",
                "label": "Xinjiang"
            },
            {
                "value": "xizang (tibet)",
                "label": "Xizang (Tibet)"
            },
            {
                "value": "beijing",
                "label": "Beijing"
            },
            {
                "value": "chongqing",
                "label": "Chongqing"
            },
            {
                "value": "shanghai",
                "label": "Shanghai"
            },
            {
                "value": "tianjin",
                "label": "Tianjin"
            }
        ]
    },
    {
        "country": {
            "label": "Colombia",
            "value": "colombia"
        },
        "states": [
            {
                "value": "amazonas",
                "label": "Amazonas"
            },
            {
                "value": "antioquia",
                "label": "Antioquia"
            },
            {
                "value": "arauca",
                "label": "Arauca"
            },
            {
                "value": "atlantico",
                "label": "Atlantico"
            },
            {
                "value": "bogota district capital",
                "label": "Bogota District Capital"
            },
            {
                "value": "bolivar",
                "label": "Bolivar"
            },
            {
                "value": "boyaca",
                "label": "Boyaca"
            },
            {
                "value": "caldas",
                "label": "Caldas"
            },
            {
                "value": "caqueta",
                "label": "Caqueta"
            },
            {
                "value": "casanare",
                "label": "Casanare"
            },
            {
                "value": "cauca",
                "label": "Cauca"
            },
            {
                "value": "cesar",
                "label": "Cesar"
            },
            {
                "value": "choco",
                "label": "Choco"
            },
            {
                "value": "cordoba",
                "label": "Cordoba"
            },
            {
                "value": "cundinamarca",
                "label": "Cundinamarca"
            },
            {
                "value": "guainia",
                "label": "Guainia"
            },
            {
                "value": "guaviare",
                "label": "Guaviare"
            },
            {
                "value": "huila",
                "label": "Huila"
            },
            {
                "value": "la guajira",
                "label": "La Guajira"
            },
            {
                "value": "magdalena",
                "label": "Magdalena"
            },
            {
                "value": "meta",
                "label": "Meta"
            },
            {
                "value": "narino",
                "label": "Narino"
            },
            {
                "value": "norte de santander",
                "label": "Norte de Santander"
            },
            {
                "value": "putumayo",
                "label": "Putumayo"
            },
            {
                "value": "quindio",
                "label": "Quindio"
            },
            {
                "value": "risaralda",
                "label": "Risaralda"
            },
            {
                "value": "san andres & providencia",
                "label": "San Andres & Providencia"
            },
            {
                "value": "santander",
                "label": "Santander"
            },
            {
                "value": "sucre",
                "label": "Sucre"
            },
            {
                "value": "tolima",
                "label": "Tolima"
            },
            {
                "value": "valle del cauca",
                "label": "Valle del Cauca"
            },
            {
                "value": "vaupes",
                "label": "Vaupes"
            },
            {
                "value": "vichada",
                "label": "Vichada"
            }
        ]
    },
    {
        "country": {
            "label": "Comoros",
            "value": "comoros"
        },
        "states": [
            {
                "value": "grande comore (njazidja)",
                "label": "Grande Comore (Njazidja)"
            },
            {
                "value": "anjouan (nzwani)",
                "label": "Anjouan (Nzwani)"
            },
            {
                "value": "moheli (mwali)",
                "label": "Moheli (Mwali)"
            }
        ]
    },
    {
        "country": {
            "label": "Congo, Democratic Republic",
            "value": "congo, democratic republic"
        },
        "states": [
            {
                "value": "bandundu",
                "label": "Bandundu"
            },
            {
                "value": "bas-congo",
                "label": "Bas-Congo"
            },
            {
                "value": "equateur",
                "label": "Equateur"
            },
            {
                "value": "kasai-occidental",
                "label": "Kasai-Occidental"
            },
            {
                "value": "kasai-oriental",
                "label": "Kasai-Oriental"
            },
            {
                "value": "katanga",
                "label": "Katanga"
            },
            {
                "value": "kinshasa",
                "label": "Kinshasa"
            },
            {
                "value": "maniema",
                "label": "Maniema"
            },
            {
                "value": "nord-kivu",
                "label": "Nord-Kivu"
            },
            {
                "value": "orientale",
                "label": "Orientale"
            },
            {
                "value": "sud-kivu",
                "label": "Sud-Kivu"
            }
        ]
    },
    {
        "country": {
            "label": "Congo, Republic of the",
            "value": "congo, republic of the"
        },
        "states": [
            {
                "value": "bouenza",
                "label": "Bouenza"
            },
            {
                "value": "brazzaville",
                "label": "Brazzaville"
            },
            {
                "value": "cuvette",
                "label": "Cuvette"
            },
            {
                "value": "cuvette-ouest",
                "label": "Cuvette-Ouest"
            },
            {
                "value": "kouilou",
                "label": "Kouilou"
            },
            {
                "value": "lekoumou",
                "label": "Lekoumou"
            },
            {
                "value": "likouala",
                "label": "Likouala"
            },
            {
                "value": "niari",
                "label": "Niari"
            },
            {
                "value": "plateaux",
                "label": "Plateaux"
            },
            {
                "value": "pool",
                "label": "Pool"
            },
            {
                "value": "sangha",
                "label": "Sangha"
            }
        ]
    },
    {
        "country": {
            "label": "Costa Rica",
            "value": "costa rica"
        },
        "states": [
            {
                "value": "alajuela",
                "label": "Alajuela"
            },
            {
                "value": "cartago",
                "label": "Cartago"
            },
            {
                "value": "guanacaste",
                "label": "Guanacaste"
            },
            {
                "value": "heredia",
                "label": "Heredia"
            },
            {
                "value": "limon",
                "label": "Limon"
            },
            {
                "value": "puntarenas",
                "label": "Puntarenas"
            },
            {
                "value": "san jose",
                "label": "San Jose"
            }
        ]
    },
    {
        "country": {
            "label": "Cote d'Ivoire",
            "value": "cote d'ivoire"
        },
        "states": []
    },
    {
        "country": {
            "label": "Croatia",
            "value": "croatia"
        },
        "states": [
            {
                "value": "bjelovarsko-bilogorska",
                "label": "Bjelovarsko-Bilogorska"
            },
            {
                "value": "brodsko-posavska",
                "label": "Brodsko-Posavska"
            },
            {
                "value": "dubrovacko-neretvanska",
                "label": "Dubrovacko-Neretvanska"
            },
            {
                "value": "istarska",
                "label": "Istarska"
            },
            {
                "value": "karlovacka",
                "label": "Karlovacka"
            },
            {
                "value": "koprivnicko-krizevacka",
                "label": "Koprivnicko-Krizevacka"
            },
            {
                "value": "krapinsko-zagorska",
                "label": "Krapinsko-Zagorska"
            },
            {
                "value": "licko-senjska",
                "label": "Licko-Senjska"
            },
            {
                "value": "medimurska",
                "label": "Medimurska"
            },
            {
                "value": "osjecko-baranjska",
                "label": "Osjecko-Baranjska"
            },
            {
                "value": "pozesko-slavonska",
                "label": "Pozesko-Slavonska"
            },
            {
                "value": "primorsko-goranska",
                "label": "Primorsko-Goranska"
            },
            {
                "value": "sibensko-kninska",
                "label": "Sibensko-Kninska"
            },
            {
                "value": "sisacko-moslavacka",
                "label": "Sisacko-Moslavacka"
            },
            {
                "value": "splitsko-dalmatinska",
                "label": "Splitsko-Dalmatinska"
            },
            {
                "value": "varazdinska",
                "label": "Varazdinska"
            },
            {
                "value": "viroviticko-podravska",
                "label": "Viroviticko-Podravska"
            },
            {
                "value": "vukovarsko-srijemska",
                "label": "Vukovarsko-Srijemska"
            },
            {
                "value": "zadarska",
                "label": "Zadarska"
            },
            {
                "value": "zagreb",
                "label": "Zagreb"
            },
            {
                "value": "zagrebacka",
                "label": "Zagrebacka"
            }
        ]
    },
    {
        "country": {
            "label": "Cuba",
            "value": "cuba"
        },
        "states": [
            {
                "value": "camaguey",
                "label": "Camaguey"
            },
            {
                "value": "ciego de avila",
                "label": "Ciego de Avila"
            },
            {
                "value": "cienfuegos",
                "label": "Cienfuegos"
            },
            {
                "value": "ciudad de la habana",
                "label": "Ciudad de La Habana"
            },
            {
                "value": "granma",
                "label": "Granma"
            },
            {
                "value": "guantanamo",
                "label": "Guantanamo"
            },
            {
                "value": "holguin",
                "label": "Holguin"
            },
            {
                "value": "isla de la juventud",
                "label": "Isla de la Juventud"
            },
            {
                "value": "la habana",
                "label": "La Habana"
            },
            {
                "value": "las tunas",
                "label": "Las Tunas"
            },
            {
                "value": "matanzas",
                "label": "Matanzas"
            },
            {
                "value": "pinar del rio",
                "label": "Pinar del Rio"
            },
            {
                "value": "sancti spiritus",
                "label": "Sancti Spiritus"
            },
            {
                "value": "santiago de cuba",
                "label": "Santiago de Cuba"
            },
            {
                "value": "villa clara",
                "label": "Villa Clara"
            }
        ]
    },
    {
        "country": {
            "label": "Cyprus",
            "value": "cyprus"
        },
        "states": [
            {
                "value": "famagusta",
                "label": "Famagusta"
            },
            {
                "value": "kyrenia",
                "label": "Kyrenia"
            },
            {
                "value": "larnaca",
                "label": "Larnaca"
            },
            {
                "value": "limassol",
                "label": "Limassol"
            },
            {
                "value": "nicosia",
                "label": "Nicosia"
            },
            {
                "value": "paphos",
                "label": "Paphos"
            }
        ]
    },
    {
        "country": {
            "label": "Czech Republic",
            "value": "czech republic"
        },
        "states": [
            {
                "value": "jihocesky kraj",
                "label": "Jihocesky Kraj"
            },
            {
                "value": "jihomoravsky kraj",
                "label": "Jihomoravsky Kraj"
            },
            {
                "value": "karlovarsky kraj",
                "label": "Karlovarsky Kraj"
            },
            {
                "value": "kralovehradecky kraj",
                "label": "Kralovehradecky Kraj"
            },
            {
                "value": "liberecky kraj",
                "label": "Liberecky Kraj"
            },
            {
                "value": "moravskoslezsky kraj",
                "label": "Moravskoslezsky Kraj"
            },
            {
                "value": "olomoucky kraj",
                "label": "Olomoucky Kraj"
            },
            {
                "value": "pardubicky kraj",
                "label": "Pardubicky Kraj"
            },
            {
                "value": "plzensky kraj",
                "label": "Plzensky Kraj"
            },
            {
                "value": "praha",
                "label": "Praha"
            },
            {
                "value": "stredocesky kraj",
                "label": "Stredocesky Kraj"
            },
            {
                "value": "ustecky kraj",
                "label": "Ustecky Kraj"
            },
            {
                "value": "vysocina",
                "label": "Vysocina"
            },
            {
                "value": "zlinsky kraj",
                "label": "Zlinsky Kraj"
            }
        ]
    },
    {
        "country": {
            "label": "Denmark",
            "value": "denmark"
        },
        "states": [
            {
                "value": "arhus",
                "label": "Arhus"
            },
            {
                "value": "bornholm",
                "label": "Bornholm"
            },
            {
                "value": "frederiksberg",
                "label": "Frederiksberg"
            },
            {
                "value": "frederiksborg",
                "label": "Frederiksborg"
            },
            {
                "value": "fyn",
                "label": "Fyn"
            },
            {
                "value": "kobenhavn",
                "label": "Kobenhavn"
            },
            {
                "value": "kobenhavns",
                "label": "Kobenhavns"
            },
            {
                "value": "nordjylland",
                "label": "Nordjylland"
            },
            {
                "value": "ribe",
                "label": "Ribe"
            },
            {
                "value": "ringkobing",
                "label": "Ringkobing"
            },
            {
                "value": "roskilde",
                "label": "Roskilde"
            },
            {
                "value": "sonderjylland",
                "label": "Sonderjylland"
            },
            {
                "value": "storstrom",
                "label": "Storstrom"
            },
            {
                "value": "vejle",
                "label": "Vejle"
            },
            {
                "value": "vestsjalland",
                "label": "Vestsjalland"
            },
            {
                "value": "viborg",
                "label": "Viborg"
            }
        ]
    },
    {
        "country": {
            "label": "Djibouti",
            "value": "djibouti"
        },
        "states": [
            {
                "value": "ali sabih",
                "label": "Ali Sabih"
            },
            {
                "value": "dikhil",
                "label": "Dikhil"
            },
            {
                "value": "djibouti",
                "label": "Djibouti"
            },
            {
                "value": "obock",
                "label": "Obock"
            },
            {
                "value": "tadjoura",
                "label": "Tadjoura"
            }
        ]
    },
    {
        "country": {
            "label": "Dominica",
            "value": "dominica"
        },
        "states": [
            {
                "value": "saint andrew",
                "label": "Saint Andrew"
            },
            {
                "value": "saint david",
                "label": "Saint David"
            },
            {
                "value": "saint george",
                "label": "Saint George"
            },
            {
                "value": "saint john",
                "label": "Saint John"
            },
            {
                "value": "saint joseph",
                "label": "Saint Joseph"
            },
            {
                "value": "saint luke",
                "label": "Saint Luke"
            },
            {
                "value": "saint mark",
                "label": "Saint Mark"
            },
            {
                "value": "saint patrick",
                "label": "Saint Patrick"
            },
            {
                "value": "saint paul",
                "label": "Saint Paul"
            },
            {
                "value": "saint peter",
                "label": "Saint Peter"
            }
        ]
    },
    {
        "country": {
            "label": "Dominican Republic",
            "value": "dominican republic"
        },
        "states": [
            {
                "value": "azua",
                "label": "Azua"
            },
            {
                "value": "baoruco",
                "label": "Baoruco"
            },
            {
                "value": "barahona",
                "label": "Barahona"
            },
            {
                "value": "dajabon",
                "label": "Dajabon"
            },
            {
                "value": "distrito nacional",
                "label": "Distrito Nacional"
            },
            {
                "value": "duarte",
                "label": "Duarte"
            },
            {
                "value": "elias pina",
                "label": "Elias Pina"
            },
            {
                "value": "el seibo",
                "label": "El Seibo"
            },
            {
                "value": "espaillat",
                "label": "Espaillat"
            },
            {
                "value": "hato mayor",
                "label": "Hato Mayor"
            },
            {
                "value": "independencia",
                "label": "Independencia"
            },
            {
                "value": "la altagracia",
                "label": "La Altagracia"
            },
            {
                "value": "la romana",
                "label": "La Romana"
            },
            {
                "value": "la vega",
                "label": "La Vega"
            },
            {
                "value": "maria trinidad sanchez",
                "label": "Maria Trinidad Sanchez"
            },
            {
                "value": "monsenor nouel",
                "label": "Monsenor Nouel"
            },
            {
                "value": "monte cristi",
                "label": "Monte Cristi"
            },
            {
                "value": "monte plata",
                "label": "Monte Plata"
            },
            {
                "value": "pedernales",
                "label": "Pedernales"
            },
            {
                "value": "peravia",
                "label": "Peravia"
            },
            {
                "value": "puerto plata",
                "label": "Puerto Plata"
            },
            {
                "value": "salcedo",
                "label": "Salcedo"
            },
            {
                "value": "samana",
                "label": "Samana"
            },
            {
                "value": "sanchez ramirez",
                "label": "Sanchez Ramirez"
            },
            {
                "value": "san cristobal",
                "label": "San Cristobal"
            },
            {
                "value": "san jose de ocoa",
                "label": "San Jose de Ocoa"
            },
            {
                "value": "san juan",
                "label": "San Juan"
            },
            {
                "value": "san pedro de macoris",
                "label": "San Pedro de Macoris"
            },
            {
                "value": "santiago",
                "label": "Santiago"
            },
            {
                "value": "santiago rodriguez",
                "label": "Santiago Rodriguez"
            },
            {
                "value": "santo domingo",
                "label": "Santo Domingo"
            },
            {
                "value": "valverde",
                "label": "Valverde"
            }
        ]
    },
    {
        "country": {
            "label": "East Timor",
            "value": "east timor"
        },
        "states": [
            {
                "value": "aileu",
                "label": "Aileu"
            },
            {
                "value": "ainaro",
                "label": "Ainaro"
            },
            {
                "value": "baucau",
                "label": "Baucau"
            },
            {
                "value": "bobonaro",
                "label": "Bobonaro"
            },
            {
                "value": "cova-lima",
                "label": "Cova-Lima"
            },
            {
                "value": "dili",
                "label": "Dili"
            },
            {
                "value": "ermera",
                "label": "Ermera"
            },
            {
                "value": "lautem",
                "label": "Lautem"
            },
            {
                "value": "liquica",
                "label": "Liquica"
            },
            {
                "value": "manatuto",
                "label": "Manatuto"
            },
            {
                "value": "manufahi",
                "label": "Manufahi"
            },
            {
                "value": "oecussi",
                "label": "Oecussi"
            },
            {
                "value": "viqueque",
                "label": "Viqueque"
            }
        ]
    },
    {
        "country": {
            "label": "Ecuador",
            "value": "ecuador"
        },
        "states": [
            {
                "value": "azuay",
                "label": "Azuay"
            },
            {
                "value": "bolivar",
                "label": "Bolivar"
            },
            {
                "value": "canar",
                "label": "Canar"
            },
            {
                "value": "carchi",
                "label": "Carchi"
            },
            {
                "value": "chimborazo",
                "label": "Chimborazo"
            },
            {
                "value": "cotopaxi",
                "label": "Cotopaxi"
            },
            {
                "value": "el oro",
                "label": "El Oro"
            },
            {
                "value": "esmeraldas",
                "label": "Esmeraldas"
            },
            {
                "value": "galapagos",
                "label": "Galapagos"
            },
            {
                "value": "guayas",
                "label": "Guayas"
            },
            {
                "value": "imbabura",
                "label": "Imbabura"
            },
            {
                "value": "loja",
                "label": "Loja"
            },
            {
                "value": "los rios",
                "label": "Los Rios"
            },
            {
                "value": "manabi",
                "label": "Manabi"
            },
            {
                "value": "morona-santiago",
                "label": "Morona-Santiago"
            },
            {
                "value": "napo",
                "label": "Napo"
            },
            {
                "value": "orellana",
                "label": "Orellana"
            },
            {
                "value": "pastaza",
                "label": "Pastaza"
            },
            {
                "value": "pichincha",
                "label": "Pichincha"
            },
            {
                "value": "sucumbios",
                "label": "Sucumbios"
            },
            {
                "value": "tungurahua",
                "label": "Tungurahua"
            },
            {
                "value": "zamora-chinchipe",
                "label": "Zamora-Chinchipe"
            }
        ]
    },
    {
        "country": {
            "label": "Egypt",
            "value": "egypt"
        },
        "states": [
            {
                "value": "ad daqahliyah",
                "label": "Ad Daqahliyah"
            },
            {
                "value": "al bahr al ahmar",
                "label": "Al Bahr al Ahmar"
            },
            {
                "value": "al buhayrah",
                "label": "Al Buhayrah"
            },
            {
                "value": "al fayyum",
                "label": "Al Fayyum"
            },
            {
                "value": "al gharbiyah",
                "label": "Al Gharbiyah"
            },
            {
                "value": "al iskandariyah",
                "label": "Al Iskandariyah"
            },
            {
                "value": "al isma'iliyah",
                "label": "Al Isma'iliyah"
            },
            {
                "value": "al jizah",
                "label": "Al Jizah"
            },
            {
                "value": "al minufiyah",
                "label": "Al Minufiyah"
            },
            {
                "value": "al minya",
                "label": "Al Minya"
            },
            {
                "value": "al qahirah",
                "label": "Al Qahirah"
            },
            {
                "value": "al qalyubiyah",
                "label": "Al Qalyubiyah"
            },
            {
                "value": "al wadi al jadid",
                "label": "Al Wadi al Jadid"
            },
            {
                "value": "ash sharqiyah",
                "label": "Ash Sharqiyah"
            },
            {
                "value": "as suways",
                "label": "As Suways"
            },
            {
                "value": "aswan",
                "label": "Aswan"
            },
            {
                "value": "asyut",
                "label": "Asyut"
            },
            {
                "value": "bani suwayf",
                "label": "Bani Suwayf"
            },
            {
                "value": "bur sa'id",
                "label": "Bur Sa'id"
            },
            {
                "value": "dumyat",
                "label": "Dumyat"
            },
            {
                "value": "janub sina'",
                "label": "Janub Sina'"
            },
            {
                "value": "kafr ash shaykh",
                "label": "Kafr ash Shaykh"
            },
            {
                "value": "matruh",
                "label": "Matruh"
            },
            {
                "value": "qina",
                "label": "Qina"
            },
            {
                "value": "shamal sina'",
                "label": "Shamal Sina'"
            },
            {
                "value": "suhaj",
                "label": "Suhaj"
            }
        ]
    },
    {
        "country": {
            "label": "El Salvador",
            "value": "el salvador"
        },
        "states": [
            {
                "value": "ahuachapan",
                "label": "Ahuachapan"
            },
            {
                "value": "cabanas",
                "label": "Cabanas"
            },
            {
                "value": "chalatenango",
                "label": "Chalatenango"
            },
            {
                "value": "cuscatlan",
                "label": "Cuscatlan"
            },
            {
                "value": "la libertad",
                "label": "La Libertad"
            },
            {
                "value": "la paz",
                "label": "La Paz"
            },
            {
                "value": "la union",
                "label": "La Union"
            },
            {
                "value": "morazan",
                "label": "Morazan"
            },
            {
                "value": "san miguel",
                "label": "San Miguel"
            },
            {
                "value": "san salvador",
                "label": "San Salvador"
            },
            {
                "value": "santa ana",
                "label": "Santa Ana"
            },
            {
                "value": "san vicente",
                "label": "San Vicente"
            },
            {
                "value": "sonsonate",
                "label": "Sonsonate"
            },
            {
                "value": "usulutan",
                "label": "Usulutan"
            }
        ]
    },
    {
        "country": {
            "label": "Equatorial Guinea",
            "value": "equatorial guinea"
        },
        "states": [
            {
                "value": "annobon",
                "label": "Annobon"
            },
            {
                "value": "bioko norte",
                "label": "Bioko Norte"
            },
            {
                "value": "bioko sur",
                "label": "Bioko Sur"
            },
            {
                "value": "centro sur",
                "label": "Centro Sur"
            },
            {
                "value": "kie-ntem",
                "label": "Kie-Ntem"
            },
            {
                "value": "litoral",
                "label": "Litoral"
            },
            {
                "value": "wele-nzas",
                "label": "Wele-Nzas"
            }
        ]
    },
    {
        "country": {
            "label": "Eritrea",
            "value": "eritrea"
        },
        "states": [
            {
                "value": "anseba",
                "label": "Anseba"
            },
            {
                "value": "debub",
                "label": "Debub"
            },
            {
                "value": "debubawi k'eyih bahri",
                "label": "Debubawi K'eyih Bahri"
            },
            {
                "value": "gash barka",
                "label": "Gash Barka"
            },
            {
                "value": "ma'akel",
                "label": "Ma'akel"
            },
            {
                "value": "semenawi keyih bahri",
                "label": "Semenawi Keyih Bahri"
            }
        ]
    },
    {
        "country": {
            "label": "Estonia",
            "value": "estonia"
        },
        "states": [
            {
                "value": "harjumaa (tallinn)",
                "label": "Harjumaa (Tallinn)"
            },
            {
                "value": "hiiumaa (kardla)",
                "label": "Hiiumaa (Kardla)"
            },
            {
                "value": "ida-virumaa (johvi)",
                "label": "Ida-Virumaa (Johvi)"
            },
            {
                "value": "jarvamaa (paide)",
                "label": "Jarvamaa (Paide)"
            },
            {
                "value": "jogevamaa (jogeva)",
                "label": "Jogevamaa (Jogeva)"
            },
            {
                "value": "laanemaa (haapsalu)",
                "label": "Laanemaa (Haapsalu)"
            },
            {
                "value": "laane-virumaa (rakvere)",
                "label": "Laane-Virumaa (Rakvere)"
            },
            {
                "value": "parnumaa (parnu)",
                "label": "Parnumaa (Parnu)"
            },
            {
                "value": "polvamaa (polva)",
                "label": "Polvamaa (Polva)"
            },
            {
                "value": "raplamaa (rapla)",
                "label": "Raplamaa (Rapla)"
            },
            {
                "value": "saaremaa (kuressaare)",
                "label": "Saaremaa (Kuressaare)"
            },
            {
                "value": "tartumaa (tartu)",
                "label": "Tartumaa (Tartu)"
            },
            {
                "value": "valgamaa (valga)",
                "label": "Valgamaa (Valga)"
            },
            {
                "value": "viljandimaa (viljandi)",
                "label": "Viljandimaa (Viljandi)"
            },
            {
                "value": "vorumaa (voru)",
                "label": "Vorumaa (Voru)"
            }
        ]
    },
    {
        "country": {
            "label": "Ethiopia",
            "value": "ethiopia"
        },
        "states": [
            {
                "value": "addis ababa",
                "label": "Addis Ababa"
            },
            {
                "value": "afar",
                "label": "Afar"
            },
            {
                "value": "amhara",
                "label": "Amhara"
            },
            {
                "value": "binshangul gumuz",
                "label": "Binshangul Gumuz"
            },
            {
                "value": "dire dawa",
                "label": "Dire Dawa"
            },
            {
                "value": "gambela hizboch",
                "label": "Gambela Hizboch"
            },
            {
                "value": "harari",
                "label": "Harari"
            },
            {
                "value": "oromia",
                "label": "Oromia"
            },
            {
                "value": "somali",
                "label": "Somali"
            },
            {
                "value": "tigray",
                "label": "Tigray"
            },
            {
                "value": "southern nations, nationalities, and peoples region",
                "label": "Southern Nations, Nationalities, and Peoples Region"
            }
        ]
    },
    {
        "country": {
            "label": "Fiji",
            "value": "fiji"
        },
        "states": [
            {
                "value": "central (suva)",
                "label": "Central (Suva)"
            },
            {
                "value": "eastern (levuka)",
                "label": "Eastern (Levuka)"
            },
            {
                "value": "northern (labasa)",
                "label": "Northern (Labasa)"
            },
            {
                "value": "rotuma",
                "label": "Rotuma"
            },
            {
                "value": "western (lautoka)",
                "label": "Western (Lautoka)"
            }
        ]
    },
    {
        "country": {
            "label": "Finland",
            "value": "finland"
        },
        "states": [
            {
                "value": "aland",
                "label": "Aland"
            },
            {
                "value": "etela-suomen laani",
                "label": "Etela-Suomen Laani"
            },
            {
                "value": "ita-suomen laani",
                "label": "Ita-Suomen Laani"
            },
            {
                "value": "lansi-suomen laani",
                "label": "Lansi-Suomen Laani"
            },
            {
                "value": "lappi",
                "label": "Lappi"
            },
            {
                "value": "oulun laani",
                "label": "Oulun Laani"
            }
        ]
    },
    {
        "country": {
            "label": "France",
            "value": "france"
        },
        "states": [
            {
                "value": "alsace",
                "label": "Alsace"
            },
            {
                "value": "aquitaine",
                "label": "Aquitaine"
            },
            {
                "value": "auvergne",
                "label": "Auvergne"
            },
            {
                "value": "basse-normandie",
                "label": "Basse-Normandie"
            },
            {
                "value": "bourgogne",
                "label": "Bourgogne"
            },
            {
                "value": "bretagne",
                "label": "Bretagne"
            },
            {
                "value": "centre",
                "label": "Centre"
            },
            {
                "value": "champagne-ardenne",
                "label": "Champagne-Ardenne"
            },
            {
                "value": "corse",
                "label": "Corse"
            },
            {
                "value": "franche-comte",
                "label": "Franche-Comte"
            },
            {
                "value": "haute-normandie",
                "label": "Haute-Normandie"
            },
            {
                "value": "ile-de-france",
                "label": "Ile-de-France"
            },
            {
                "value": "languedoc-roussillon",
                "label": "Languedoc-Roussillon"
            },
            {
                "value": "limousin",
                "label": "Limousin"
            },
            {
                "value": "lorraine",
                "label": "Lorraine"
            },
            {
                "value": "midi-pyrenees",
                "label": "Midi-Pyrenees"
            },
            {
                "value": "nord-pas-de-calais",
                "label": "Nord-Pas-de-Calais"
            },
            {
                "value": "pays de la loire",
                "label": "Pays de la Loire"
            },
            {
                "value": "picardie",
                "label": "Picardie"
            },
            {
                "value": "poitou-charentes",
                "label": "Poitou-Charentes"
            },
            {
                "value": "provence-alpes-cote d'azur",
                "label": "Provence-Alpes-Cote d'Azur"
            },
            {
                "value": "rhone-alpes",
                "label": "Rhone-Alpes"
            }
        ]
    },
    {
        "country": {
            "label": "Gabon",
            "value": "gabon"
        },
        "states": [
            {
                "value": "estuaire",
                "label": "Estuaire"
            },
            {
                "value": "haut-ogooue",
                "label": "Haut-Ogooue"
            },
            {
                "value": "moyen-ogooue",
                "label": "Moyen-Ogooue"
            },
            {
                "value": "ngounie",
                "label": "Ngounie"
            },
            {
                "value": "nyanga",
                "label": "Nyanga"
            },
            {
                "value": "ogooue-ivindo",
                "label": "Ogooue-Ivindo"
            },
            {
                "value": "ogooue-lolo",
                "label": "Ogooue-Lolo"
            },
            {
                "value": "ogooue-maritime",
                "label": "Ogooue-Maritime"
            },
            {
                "value": "woleu-ntem",
                "label": "Woleu-Ntem"
            }
        ]
    },
    {
        "country": {
            "label": "Gambia",
            "value": "gambia"
        },
        "states": [
            {
                "value": "banjul",
                "label": "Banjul"
            },
            {
                "value": "central river",
                "label": "Central River"
            },
            {
                "value": "lower river",
                "label": "Lower River"
            },
            {
                "value": "north bank",
                "label": "North Bank"
            },
            {
                "value": "upper river",
                "label": "Upper River"
            },
            {
                "value": "western",
                "label": "Western"
            }
        ]
    },
    {
        "country": {
            "label": "Georgia",
            "value": "georgia"
        },
        "states": []
    },
    {
        "country": {
            "label": "Germany",
            "value": "germany"
        },
        "states": [
            {
                "value": "baden-wuerttemberg",
                "label": "Baden-Wuerttemberg"
            },
            {
                "value": "bayern",
                "label": "Bayern"
            },
            {
                "value": "berlin",
                "label": "Berlin"
            },
            {
                "value": "brandenburg",
                "label": "Brandenburg"
            },
            {
                "value": "bremen",
                "label": "Bremen"
            },
            {
                "value": "hamburg",
                "label": "Hamburg"
            },
            {
                "value": "hessen",
                "label": "Hessen"
            },
            {
                "value": "mecklenburg-vorpommern",
                "label": "Mecklenburg-Vorpommern"
            },
            {
                "value": "niedersachsen",
                "label": "Niedersachsen"
            },
            {
                "value": "nordrhein-westfalen",
                "label": "Nordrhein-Westfalen"
            },
            {
                "value": "rheinland-pfalz",
                "label": "Rheinland-Pfalz"
            },
            {
                "value": "saarland",
                "label": "Saarland"
            },
            {
                "value": "sachsen",
                "label": "Sachsen"
            },
            {
                "value": "sachsen-anhalt",
                "label": "Sachsen-Anhalt"
            },
            {
                "value": "schleswig-holstein",
                "label": "Schleswig-Holstein"
            },
            {
                "value": "thueringen",
                "label": "Thueringen"
            }
        ]
    },
    {
        "country": {
            "label": "Ghana",
            "value": "ghana"
        },
        "states": [
            {
                "value": "ashanti",
                "label": "Ashanti"
            },
            {
                "value": "brong-ahafo",
                "label": "Brong-Ahafo"
            },
            {
                "value": "central",
                "label": "Central"
            },
            {
                "value": "eastern",
                "label": "Eastern"
            },
            {
                "value": "greater accra",
                "label": "Greater Accra"
            },
            {
                "value": "northern",
                "label": "Northern"
            },
            {
                "value": "upper east",
                "label": "Upper East"
            },
            {
                "value": "upper west",
                "label": "Upper West"
            },
            {
                "value": "volta",
                "label": "Volta"
            },
            {
                "value": "western",
                "label": "Western"
            }
        ]
    },
    {
        "country": {
            "label": "Greece",
            "value": "greece"
        },
        "states": [
            {
                "value": "agion oros",
                "label": "Agion Oros"
            },
            {
                "value": "achaia",
                "label": "Achaia"
            },
            {
                "value": "aitolia kai akarmania",
                "label": "Aitolia kai Akarmania"
            },
            {
                "value": "argolis",
                "label": "Argolis"
            },
            {
                "value": "arkadia",
                "label": "Arkadia"
            },
            {
                "value": "arta",
                "label": "Arta"
            },
            {
                "value": "attiki",
                "label": "Attiki"
            },
            {
                "value": "chalkidiki",
                "label": "Chalkidiki"
            },
            {
                "value": "chanion",
                "label": "Chanion"
            },
            {
                "value": "chios",
                "label": "Chios"
            },
            {
                "value": "dodekanisos",
                "label": "Dodekanisos"
            },
            {
                "value": "drama",
                "label": "Drama"
            },
            {
                "value": "evros",
                "label": "Evros"
            },
            {
                "value": "evrytania",
                "label": "Evrytania"
            },
            {
                "value": "evvoia",
                "label": "Evvoia"
            },
            {
                "value": "florina",
                "label": "Florina"
            },
            {
                "value": "fokidos",
                "label": "Fokidos"
            },
            {
                "value": "fthiotis",
                "label": "Fthiotis"
            },
            {
                "value": "grevena",
                "label": "Grevena"
            },
            {
                "value": "ileia",
                "label": "Ileia"
            },
            {
                "value": "imathia",
                "label": "Imathia"
            },
            {
                "value": "ioannina",
                "label": "Ioannina"
            },
            {
                "value": "irakleion",
                "label": "Irakleion"
            },
            {
                "value": "karditsa",
                "label": "Karditsa"
            },
            {
                "value": "kastoria",
                "label": "Kastoria"
            },
            {
                "value": "kavala",
                "label": "Kavala"
            },
            {
                "value": "kefallinia",
                "label": "Kefallinia"
            },
            {
                "value": "kerkyra",
                "label": "Kerkyra"
            },
            {
                "value": "kilkis",
                "label": "Kilkis"
            },
            {
                "value": "korinthia",
                "label": "Korinthia"
            },
            {
                "value": "kozani",
                "label": "Kozani"
            },
            {
                "value": "kyklades",
                "label": "Kyklades"
            },
            {
                "value": "lakonia",
                "label": "Lakonia"
            },
            {
                "value": "larisa",
                "label": "Larisa"
            },
            {
                "value": "lasithi",
                "label": "Lasithi"
            },
            {
                "value": "lefkas",
                "label": "Lefkas"
            },
            {
                "value": "lesvos",
                "label": "Lesvos"
            },
            {
                "value": "magnisia",
                "label": "Magnisia"
            },
            {
                "value": "messinia",
                "label": "Messinia"
            },
            {
                "value": "pella",
                "label": "Pella"
            },
            {
                "value": "pieria",
                "label": "Pieria"
            },
            {
                "value": "preveza",
                "label": "Preveza"
            },
            {
                "value": "rethynnis",
                "label": "Rethynnis"
            },
            {
                "value": "rodopi",
                "label": "Rodopi"
            },
            {
                "value": "samos",
                "label": "Samos"
            },
            {
                "value": "serrai",
                "label": "Serrai"
            },
            {
                "value": "thesprotia",
                "label": "Thesprotia"
            },
            {
                "value": "thessaloniki",
                "label": "Thessaloniki"
            },
            {
                "value": "trikala",
                "label": "Trikala"
            },
            {
                "value": "voiotia",
                "label": "Voiotia"
            },
            {
                "value": "xanthi",
                "label": "Xanthi"
            },
            {
                "value": "zakynthos",
                "label": "Zakynthos"
            }
        ]
    },
    {
        "country": {
            "label": "Greenland",
            "value": "greenland"
        },
        "states": [
            {
                "value": "avannaa (nordgronland)",
                "label": "Avannaa (Nordgronland)"
            },
            {
                "value": "tunu (ostgronland)",
                "label": "Tunu (Ostgronland)"
            },
            {
                "value": "kitaa (vestgronland)",
                "label": "Kitaa (Vestgronland)"
            }
        ]
    },
    {
        "country": {
            "label": "Grenada",
            "value": "grenada"
        },
        "states": [
            {
                "value": "carriacou and petit martinique",
                "label": "Carriacou and Petit Martinique"
            },
            {
                "value": "saint andrew",
                "label": "Saint Andrew"
            },
            {
                "value": "saint david",
                "label": "Saint David"
            },
            {
                "value": "saint george",
                "label": "Saint George"
            },
            {
                "value": "saint john",
                "label": "Saint John"
            },
            {
                "value": "saint mark",
                "label": "Saint Mark"
            },
            {
                "value": "saint patrick",
                "label": "Saint Patrick"
            }
        ]
    },
    {
        "country": {
            "label": "Guatemala",
            "value": "guatemala"
        },
        "states": [
            {
                "value": "alta verapaz",
                "label": "Alta Verapaz"
            },
            {
                "value": "baja verapaz",
                "label": "Baja Verapaz"
            },
            {
                "value": "chimaltenango",
                "label": "Chimaltenango"
            },
            {
                "value": "chiquimula",
                "label": "Chiquimula"
            },
            {
                "value": "el progreso",
                "label": "El Progreso"
            },
            {
                "value": "escuintla",
                "label": "Escuintla"
            },
            {
                "value": "guatemala",
                "label": "Guatemala"
            },
            {
                "value": "huehuetenango",
                "label": "Huehuetenango"
            },
            {
                "value": "izabal",
                "label": "Izabal"
            },
            {
                "value": "jalapa",
                "label": "Jalapa"
            },
            {
                "value": "jutiapa",
                "label": "Jutiapa"
            },
            {
                "value": "peten",
                "label": "Peten"
            },
            {
                "value": "quetzaltenango",
                "label": "Quetzaltenango"
            },
            {
                "value": "quiche",
                "label": "Quiche"
            },
            {
                "value": "retalhuleu",
                "label": "Retalhuleu"
            },
            {
                "value": "sacatepequez",
                "label": "Sacatepequez"
            },
            {
                "value": "san marcos",
                "label": "San Marcos"
            },
            {
                "value": "santa rosa",
                "label": "Santa Rosa"
            },
            {
                "value": "solola",
                "label": "Solola"
            },
            {
                "value": "suchitepequez",
                "label": "Suchitepequez"
            },
            {
                "value": "totonicapan",
                "label": "Totonicapan"
            },
            {
                "value": "zacapa",
                "label": "Zacapa"
            }
        ]
    },
    {
        "country": {
            "label": "Guinea",
            "value": "guinea"
        },
        "states": [
            {
                "value": "beyla",
                "label": "Beyla"
            },
            {
                "value": "boffa",
                "label": "Boffa"
            },
            {
                "value": "boke",
                "label": "Boke"
            },
            {
                "value": "conakry",
                "label": "Conakry"
            },
            {
                "value": "coyah",
                "label": "Coyah"
            },
            {
                "value": "dabola",
                "label": "Dabola"
            },
            {
                "value": "dalaba",
                "label": "Dalaba"
            },
            {
                "value": "dinguiraye",
                "label": "Dinguiraye"
            },
            {
                "value": "dubreka",
                "label": "Dubreka"
            },
            {
                "value": "faranah",
                "label": "Faranah"
            },
            {
                "value": "forecariah",
                "label": "Forecariah"
            },
            {
                "value": "fria",
                "label": "Fria"
            },
            {
                "value": "gaoual",
                "label": "Gaoual"
            },
            {
                "value": "gueckedou",
                "label": "Gueckedou"
            },
            {
                "value": "kankan",
                "label": "Kankan"
            },
            {
                "value": "kerouane",
                "label": "Kerouane"
            },
            {
                "value": "kindia",
                "label": "Kindia"
            },
            {
                "value": "kissidougou",
                "label": "Kissidougou"
            },
            {
                "value": "koubia",
                "label": "Koubia"
            },
            {
                "value": "koundara",
                "label": "Koundara"
            },
            {
                "value": "kouroussa",
                "label": "Kouroussa"
            },
            {
                "value": "labe",
                "label": "Labe"
            },
            {
                "value": "lelouma",
                "label": "Lelouma"
            },
            {
                "value": "lola",
                "label": "Lola"
            },
            {
                "value": "macenta",
                "label": "Macenta"
            },
            {
                "value": "mali",
                "label": "Mali"
            },
            {
                "value": "mamou",
                "label": "Mamou"
            },
            {
                "value": "mandiana",
                "label": "Mandiana"
            },
            {
                "value": "nzerekore",
                "label": "Nzerekore"
            },
            {
                "value": "pita",
                "label": "Pita"
            },
            {
                "value": "siguiri",
                "label": "Siguiri"
            },
            {
                "value": "telimele",
                "label": "Telimele"
            },
            {
                "value": "tougue",
                "label": "Tougue"
            },
            {
                "value": "yomou",
                "label": "Yomou"
            }
        ]
    },
    {
        "country": {
            "label": "Guinea-Bissau",
            "value": "guinea-bissau"
        },
        "states": [
            {
                "value": "bafata",
                "label": "Bafata"
            },
            {
                "value": "biombo",
                "label": "Biombo"
            },
            {
                "value": "bissau",
                "label": "Bissau"
            },
            {
                "value": "bolama",
                "label": "Bolama"
            },
            {
                "value": "cacheu",
                "label": "Cacheu"
            },
            {
                "value": "gabu",
                "label": "Gabu"
            },
            {
                "value": "oio",
                "label": "Oio"
            },
            {
                "value": "quinara",
                "label": "Quinara"
            },
            {
                "value": "tombali",
                "label": "Tombali"
            }
        ]
    },
    {
        "country": {
            "label": "Guyana",
            "value": "guyana"
        },
        "states": [
            {
                "value": "barima-waini",
                "label": "Barima-Waini"
            },
            {
                "value": "cuyuni-mazaruni",
                "label": "Cuyuni-Mazaruni"
            },
            {
                "value": "demerara-mahaica",
                "label": "Demerara-Mahaica"
            },
            {
                "value": "east berbice-corentyne",
                "label": "East Berbice-Corentyne"
            },
            {
                "value": "essequibo islands-west demerara",
                "label": "Essequibo Islands-West Demerara"
            },
            {
                "value": "mahaica-berbice",
                "label": "Mahaica-Berbice"
            },
            {
                "value": "pomeroon-supenaam",
                "label": "Pomeroon-Supenaam"
            },
            {
                "value": "potaro-siparuni",
                "label": "Potaro-Siparuni"
            },
            {
                "value": "upper demerara-berbice",
                "label": "Upper Demerara-Berbice"
            },
            {
                "value": "upper takutu-upper essequibo",
                "label": "Upper Takutu-Upper Essequibo"
            }
        ]
    },
    {
        "country": {
            "label": "Haiti",
            "value": "haiti"
        },
        "states": [
            {
                "value": "artibonite",
                "label": "Artibonite"
            },
            {
                "value": "centre",
                "label": "Centre"
            },
            {
                "value": "grand 'anse",
                "label": "Grand 'Anse"
            },
            {
                "value": "nord",
                "label": "Nord"
            },
            {
                "value": "nord-est",
                "label": "Nord-Est"
            },
            {
                "value": "nord-ouest",
                "label": "Nord-Ouest"
            },
            {
                "value": "ouest",
                "label": "Ouest"
            },
            {
                "value": "sud",
                "label": "Sud"
            },
            {
                "value": "sud-est",
                "label": "Sud-Est"
            }
        ]
    },
    {
        "country": {
            "label": "Honduras",
            "value": "honduras"
        },
        "states": [
            {
                "value": "atlantida",
                "label": "Atlantida"
            },
            {
                "value": "choluteca",
                "label": "Choluteca"
            },
            {
                "value": "colon",
                "label": "Colon"
            },
            {
                "value": "comayagua",
                "label": "Comayagua"
            },
            {
                "value": "copan",
                "label": "Copan"
            },
            {
                "value": "cortes",
                "label": "Cortes"
            },
            {
                "value": "el paraiso",
                "label": "El Paraiso"
            },
            {
                "value": "francisco morazan",
                "label": "Francisco Morazan"
            },
            {
                "value": "gracias a dios",
                "label": "Gracias a Dios"
            },
            {
                "value": "intibuca",
                "label": "Intibuca"
            },
            {
                "value": "islas de la bahia",
                "label": "Islas de la Bahia"
            },
            {
                "value": "la paz",
                "label": "La Paz"
            },
            {
                "value": "lempira",
                "label": "Lempira"
            },
            {
                "value": "ocotepeque",
                "label": "Ocotepeque"
            },
            {
                "value": "olancho",
                "label": "Olancho"
            },
            {
                "value": "santa barbara",
                "label": "Santa Barbara"
            },
            {
                "value": "valle",
                "label": "Valle"
            },
            {
                "value": "yoro",
                "label": "Yoro"
            }
        ]
    },
    {
        "country": {
            "label": "Hong Kong",
            "value": "hong kong"
        },
        "states": []
    },
    {
        "country": {
            "label": "Hungary",
            "value": "hungary"
        },
        "states": [
            {
                "value": "bacs-kiskun",
                "label": "Bacs-Kiskun"
            },
            {
                "value": "baranya",
                "label": "Baranya"
            },
            {
                "value": "bekes",
                "label": "Bekes"
            },
            {
                "value": "borsod-abauj-zemplen",
                "label": "Borsod-Abauj-Zemplen"
            },
            {
                "value": "csongrad",
                "label": "Csongrad"
            },
            {
                "value": "fejer",
                "label": "Fejer"
            },
            {
                "value": "gyor-moson-sopron",
                "label": "Gyor-Moson-Sopron"
            },
            {
                "value": "hajdu-bihar",
                "label": "Hajdu-Bihar"
            },
            {
                "value": "heves",
                "label": "Heves"
            },
            {
                "value": "jasz-nagykun-szolnok",
                "label": "Jasz-Nagykun-Szolnok"
            },
            {
                "value": "komarom-esztergom",
                "label": "Komarom-Esztergom"
            },
            {
                "value": "nograd",
                "label": "Nograd"
            },
            {
                "value": "pest",
                "label": "Pest"
            },
            {
                "value": "somogy",
                "label": "Somogy"
            },
            {
                "value": "szabolcs-szatmar-bereg",
                "label": "Szabolcs-Szatmar-Bereg"
            },
            {
                "value": "tolna",
                "label": "Tolna"
            },
            {
                "value": "vas",
                "label": "Vas"
            },
            {
                "value": "veszprem",
                "label": "Veszprem"
            },
            {
                "value": "zala",
                "label": "Zala"
            },
            {
                "value": "bekescsaba",
                "label": "Bekescsaba"
            },
            {
                "value": "debrecen",
                "label": "Debrecen"
            },
            {
                "value": "dunaujvaros",
                "label": "Dunaujvaros"
            },
            {
                "value": "eger",
                "label": "Eger"
            },
            {
                "value": "gyor",
                "label": "Gyor"
            },
            {
                "value": "hodmezovasarhely",
                "label": "Hodmezovasarhely"
            },
            {
                "value": "kaposvar",
                "label": "Kaposvar"
            },
            {
                "value": "kecskemet",
                "label": "Kecskemet"
            },
            {
                "value": "miskolc",
                "label": "Miskolc"
            },
            {
                "value": "nagykanizsa",
                "label": "Nagykanizsa"
            },
            {
                "value": "nyiregyhaza",
                "label": "Nyiregyhaza"
            },
            {
                "value": "pecs",
                "label": "Pecs"
            },
            {
                "value": "sopron",
                "label": "Sopron"
            },
            {
                "value": "szeged",
                "label": "Szeged"
            },
            {
                "value": "szekesfehervar",
                "label": "Szekesfehervar"
            },
            {
                "value": "szolnok",
                "label": "Szolnok"
            },
            {
                "value": "szombathely",
                "label": "Szombathely"
            },
            {
                "value": "tatabanya",
                "label": "Tatabanya"
            },
            {
                "value": "veszprem",
                "label": "Veszprem"
            },
            {
                "value": "zalaegerszeg",
                "label": "Zalaegerszeg"
            }
        ]
    },
    {
        "country": {
            "label": "Iceland",
            "value": "iceland"
        },
        "states": [
            {
                "value": "austurland",
                "label": "Austurland"
            },
            {
                "value": "hofudhborgarsvaedhi",
                "label": "Hofudhborgarsvaedhi"
            },
            {
                "value": "nordhurland eystra",
                "label": "Nordhurland Eystra"
            },
            {
                "value": "nordhurland vestra",
                "label": "Nordhurland Vestra"
            },
            {
                "value": "sudhurland",
                "label": "Sudhurland"
            },
            {
                "value": "sudhurnes",
                "label": "Sudhurnes"
            },
            {
                "value": "vestfirdhir",
                "label": "Vestfirdhir"
            },
            {
                "value": "vesturland",
                "label": "Vesturland"
            }
        ]
    },
    {
        "country": {
            "label": "India",
            "value": "india"
        },
        "states": [
            {
                "value": "andaman and nicobar islands",
                "label": "Andaman and Nicobar Islands"
            },
            {
                "value": "andhra pradesh",
                "label": "Andhra Pradesh"
            },
            {
                "value": "arunachal pradesh",
                "label": "Arunachal Pradesh"
            },
            {
                "value": "assam",
                "label": "Assam"
            },
            {
                "value": "bihar",
                "label": "Bihar"
            },
            {
                "value": "chandigarh",
                "label": "Chandigarh"
            },
            {
                "value": "chhattisgarh",
                "label": "Chhattisgarh"
            },
            {
                "value": "dadra and nagar haveli",
                "label": "Dadra and Nagar Haveli"
            },
            {
                "value": "daman and diu",
                "label": "Daman and Diu"
            },
            {
                "value": "delhi",
                "label": "Delhi"
            },
            {
                "value": "goa",
                "label": "Goa"
            },
            {
                "value": "gujarat",
                "label": "Gujarat"
            },
            {
                "value": "haryana",
                "label": "Haryana"
            },
            {
                "value": "himachal pradesh",
                "label": "Himachal Pradesh"
            },
            {
                "value": "jammu and kashmir",
                "label": "Jammu and Kashmir"
            },
            {
                "value": "jharkhand",
                "label": "Jharkhand"
            },
            {
                "value": "karnataka",
                "label": "Karnataka"
            },
            {
                "value": "kerala",
                "label": "Kerala"
            },
            {
                "value": "lakshadweep",
                "label": "Lakshadweep"
            },
            {
                "value": "madhya pradesh",
                "label": "Madhya Pradesh"
            },
            {
                "value": "maharashtra",
                "label": "Maharashtra"
            },
            {
                "value": "manipur",
                "label": "Manipur"
            },
            {
                "value": "meghalaya",
                "label": "Meghalaya"
            },
            {
                "value": "mizoram",
                "label": "Mizoram"
            },
            {
                "value": "nagaland",
                "label": "Nagaland"
            },
            {
                "value": "orissa",
                "label": "Orissa"
            },
            {
                "value": "pondicherry",
                "label": "Pondicherry"
            },
            {
                "value": "punjab",
                "label": "Punjab"
            },
            {
                "value": "rajasthan",
                "label": "Rajasthan"
            },
            {
                "value": "sikkim",
                "label": "Sikkim"
            },
            {
                "value": "tamil nadu",
                "label": "Tamil Nadu"
            },
            {
                "value": "tripura",
                "label": "Tripura"
            },
            {
                "value": "uttaranchal",
                "label": "Uttaranchal"
            },
            {
                "value": "uttar pradesh",
                "label": "Uttar Pradesh"
            },
            {
                "value": "west bengal",
                "label": "West Bengal"
            }
        ]
    },
    {
        "country": {
            "label": "Indonesia",
            "value": "indonesia"
        },
        "states": [
            {
                "value": "aceh",
                "label": "Aceh"
            },
            {
                "value": "bali",
                "label": "Bali"
            },
            {
                "value": "banten",
                "label": "Banten"
            },
            {
                "value": "bengkulu",
                "label": "Bengkulu"
            },
            {
                "value": "gorontalo",
                "label": "Gorontalo"
            },
            {
                "value": "irian jaya barat",
                "label": "Irian Jaya Barat"
            },
            {
                "value": "jakarta raya",
                "label": "Jakarta Raya"
            },
            {
                "value": "jambi",
                "label": "Jambi"
            },
            {
                "value": "jawa barat",
                "label": "Jawa Barat"
            },
            {
                "value": "jawa tengah",
                "label": "Jawa Tengah"
            },
            {
                "value": "jawa timur",
                "label": "Jawa Timur"
            },
            {
                "value": "kalimantan barat",
                "label": "Kalimantan Barat"
            },
            {
                "value": "kalimantan selatan",
                "label": "Kalimantan Selatan"
            },
            {
                "value": "kalimantan tengah",
                "label": "Kalimantan Tengah"
            },
            {
                "value": "kalimantan timur",
                "label": "Kalimantan Timur"
            },
            {
                "value": "kepulauan bangka belitung",
                "label": "Kepulauan Bangka Belitung"
            },
            {
                "value": "kepulauan riau",
                "label": "Kepulauan Riau"
            },
            {
                "value": "lampung",
                "label": "Lampung"
            },
            {
                "value": "maluku",
                "label": "Maluku"
            },
            {
                "value": "maluku utara",
                "label": "Maluku Utara"
            },
            {
                "value": "nusa tenggara barat",
                "label": "Nusa Tenggara Barat"
            },
            {
                "value": "nusa tenggara timur",
                "label": "Nusa Tenggara Timur"
            },
            {
                "value": "papua",
                "label": "Papua"
            },
            {
                "value": "riau",
                "label": "Riau"
            },
            {
                "value": "sulawesi barat",
                "label": "Sulawesi Barat"
            },
            {
                "value": "sulawesi selatan",
                "label": "Sulawesi Selatan"
            },
            {
                "value": "sulawesi tengah",
                "label": "Sulawesi Tengah"
            },
            {
                "value": "sulawesi tenggara",
                "label": "Sulawesi Tenggara"
            },
            {
                "value": "sulawesi utara",
                "label": "Sulawesi Utara"
            },
            {
                "value": "sumatera barat",
                "label": "Sumatera Barat"
            },
            {
                "value": "sumatera selatan",
                "label": "Sumatera Selatan"
            },
            {
                "value": "sumatera utara",
                "label": "Sumatera Utara"
            },
            {
                "value": "yogyakarta",
                "label": "Yogyakarta"
            }
        ]
    },
    {
        "country": {
            "label": "Iran",
            "value": "iran"
        },
        "states": [
            {
                "value": "ardabil",
                "label": "Ardabil"
            },
            {
                "value": "azarbayjan-e gharbi",
                "label": "Azarbayjan-e Gharbi"
            },
            {
                "value": "azarbayjan-e sharqi",
                "label": "Azarbayjan-e Sharqi"
            },
            {
                "value": "bushehr",
                "label": "Bushehr"
            },
            {
                "value": "chahar mahall va bakhtiari",
                "label": "Chahar Mahall va Bakhtiari"
            },
            {
                "value": "esfahan",
                "label": "Esfahan"
            },
            {
                "value": "fars",
                "label": "Fars"
            },
            {
                "value": "gilan",
                "label": "Gilan"
            },
            {
                "value": "golestan",
                "label": "Golestan"
            },
            {
                "value": "hamadan",
                "label": "Hamadan"
            },
            {
                "value": "hormozgan",
                "label": "Hormozgan"
            },
            {
                "value": "ilam",
                "label": "Ilam"
            },
            {
                "value": "kerman",
                "label": "Kerman"
            },
            {
                "value": "kermanshah",
                "label": "Kermanshah"
            },
            {
                "value": "khorasan-e janubi",
                "label": "Khorasan-e Janubi"
            },
            {
                "value": "khorasan-e razavi",
                "label": "Khorasan-e Razavi"
            },
            {
                "value": "khorasan-e shemali",
                "label": "Khorasan-e Shemali"
            },
            {
                "value": "khuzestan",
                "label": "Khuzestan"
            },
            {
                "value": "kohgiluyeh va buyer ahmad",
                "label": "Kohgiluyeh va Buyer Ahmad"
            },
            {
                "value": "kordestan",
                "label": "Kordestan"
            },
            {
                "value": "lorestan",
                "label": "Lorestan"
            },
            {
                "value": "markazi",
                "label": "Markazi"
            },
            {
                "value": "mazandaran",
                "label": "Mazandaran"
            },
            {
                "value": "qazvin",
                "label": "Qazvin"
            },
            {
                "value": "qom",
                "label": "Qom"
            },
            {
                "value": "semnan",
                "label": "Semnan"
            },
            {
                "value": "sistan va baluchestan",
                "label": "Sistan va Baluchestan"
            },
            {
                "value": "tehran",
                "label": "Tehran"
            },
            {
                "value": "yazd",
                "label": "Yazd"
            },
            {
                "value": "zanjan",
                "label": "Zanjan"
            }
        ]
    },
    {
        "country": {
            "label": "Iraq",
            "value": "iraq"
        },
        "states": [
            {
                "value": "al anbar",
                "label": "Al Anbar"
            },
            {
                "value": "al basrah",
                "label": "Al Basrah"
            },
            {
                "value": "al muthanna",
                "label": "Al Muthanna"
            },
            {
                "value": "al qadisiyah",
                "label": "Al Qadisiyah"
            },
            {
                "value": "an najaf",
                "label": "An Najaf"
            },
            {
                "value": "arbil",
                "label": "Arbil"
            },
            {
                "value": "as sulaymaniyah",
                "label": "As Sulaymaniyah"
            },
            {
                "value": "at ta'mim",
                "label": "At Ta'mim"
            },
            {
                "value": "babil",
                "label": "Babil"
            },
            {
                "value": "baghdad",
                "label": "Baghdad"
            },
            {
                "value": "dahuk",
                "label": "Dahuk"
            },
            {
                "value": "dhi qar",
                "label": "Dhi Qar"
            },
            {
                "value": "diyala",
                "label": "Diyala"
            },
            {
                "value": "karbala'",
                "label": "Karbala'"
            },
            {
                "value": "maysan",
                "label": "Maysan"
            },
            {
                "value": "ninawa",
                "label": "Ninawa"
            },
            {
                "value": "salah ad din",
                "label": "Salah ad Din"
            },
            {
                "value": "wasit",
                "label": "Wasit"
            }
        ]
    },
    {
        "country": {
            "label": "Ireland",
            "value": "ireland"
        },
        "states": [
            {
                "value": "carlow",
                "label": "Carlow"
            },
            {
                "value": "cavan",
                "label": "Cavan"
            },
            {
                "value": "clare",
                "label": "Clare"
            },
            {
                "value": "cork",
                "label": "Cork"
            },
            {
                "value": "donegal",
                "label": "Donegal"
            },
            {
                "value": "dublin",
                "label": "Dublin"
            },
            {
                "value": "galway",
                "label": "Galway"
            },
            {
                "value": "kerry",
                "label": "Kerry"
            },
            {
                "value": "kildare",
                "label": "Kildare"
            },
            {
                "value": "kilkenny",
                "label": "Kilkenny"
            },
            {
                "value": "laois",
                "label": "Laois"
            },
            {
                "value": "leitrim",
                "label": "Leitrim"
            },
            {
                "value": "limerick",
                "label": "Limerick"
            },
            {
                "value": "longford",
                "label": "Longford"
            },
            {
                "value": "louth",
                "label": "Louth"
            },
            {
                "value": "mayo",
                "label": "Mayo"
            },
            {
                "value": "meath",
                "label": "Meath"
            },
            {
                "value": "monaghan",
                "label": "Monaghan"
            },
            {
                "value": "offaly",
                "label": "Offaly"
            },
            {
                "value": "roscommon",
                "label": "Roscommon"
            },
            {
                "value": "sligo",
                "label": "Sligo"
            },
            {
                "value": "tipperary",
                "label": "Tipperary"
            },
            {
                "value": "waterford",
                "label": "Waterford"
            },
            {
                "value": "westmeath",
                "label": "Westmeath"
            },
            {
                "value": "wexford",
                "label": "Wexford"
            },
            {
                "value": "wicklow",
                "label": "Wicklow"
            }
        ]
    },
    {
        "country": {
            "label": "Israel",
            "value": "israel"
        },
        "states": [
            {
                "value": "central",
                "label": "Central"
            },
            {
                "value": "haifa",
                "label": "Haifa"
            },
            {
                "value": "jerusalem",
                "label": "Jerusalem"
            },
            {
                "value": "northern",
                "label": "Northern"
            },
            {
                "value": "southern",
                "label": "Southern"
            },
            {
                "value": "tel aviv",
                "label": "Tel Aviv"
            }
        ]
    },
    {
        "country": {
            "label": "Italy",
            "value": "italy"
        },
        "states": [
            {
                "value": "abruzzo",
                "label": "Abruzzo"
            },
            {
                "value": "basilicata",
                "label": "Basilicata"
            },
            {
                "value": "calabria",
                "label": "Calabria"
            },
            {
                "value": "campania",
                "label": "Campania"
            },
            {
                "value": "emilia-romagna",
                "label": "Emilia-Romagna"
            },
            {
                "value": "friuli-venezia giulia",
                "label": "Friuli-Venezia Giulia"
            },
            {
                "value": "lazio",
                "label": "Lazio"
            },
            {
                "value": "liguria",
                "label": "Liguria"
            },
            {
                "value": "lombardia",
                "label": "Lombardia"
            },
            {
                "value": "marche",
                "label": "Marche"
            },
            {
                "value": "molise",
                "label": "Molise"
            },
            {
                "value": "piemonte",
                "label": "Piemonte"
            },
            {
                "value": "puglia",
                "label": "Puglia"
            },
            {
                "value": "sardegna",
                "label": "Sardegna"
            },
            {
                "value": "sicilia",
                "label": "Sicilia"
            },
            {
                "value": "toscana",
                "label": "Toscana"
            },
            {
                "value": "trentino-alto adige",
                "label": "Trentino-Alto Adige"
            },
            {
                "value": "umbria",
                "label": "Umbria"
            },
            {
                "value": "valle d'aosta",
                "label": "Valle d'Aosta"
            },
            {
                "value": "veneto",
                "label": "Veneto"
            }
        ]
    },
    {
        "country": {
            "label": "Jamaica",
            "value": "jamaica"
        },
        "states": [
            {
                "value": "clarendon",
                "label": "Clarendon"
            },
            {
                "value": "hanover",
                "label": "Hanover"
            },
            {
                "value": "kingston",
                "label": "Kingston"
            },
            {
                "value": "manchester",
                "label": "Manchester"
            },
            {
                "value": "portland",
                "label": "Portland"
            },
            {
                "value": "saint andrew",
                "label": "Saint Andrew"
            },
            {
                "value": "saint ann",
                "label": "Saint Ann"
            },
            {
                "value": "saint catherine",
                "label": "Saint Catherine"
            },
            {
                "value": "saint elizabeth",
                "label": "Saint Elizabeth"
            },
            {
                "value": "saint james",
                "label": "Saint James"
            },
            {
                "value": "saint mary",
                "label": "Saint Mary"
            },
            {
                "value": "saint thomas",
                "label": "Saint Thomas"
            },
            {
                "value": "trelawny",
                "label": "Trelawny"
            },
            {
                "value": "westmoreland",
                "label": "Westmoreland"
            }
        ]
    },
    {
        "country": {
            "label": "Japan",
            "value": "japan"
        },
        "states": [
            {
                "value": "aichi",
                "label": "Aichi"
            },
            {
                "value": "akita",
                "label": "Akita"
            },
            {
                "value": "aomori",
                "label": "Aomori"
            },
            {
                "value": "chiba",
                "label": "Chiba"
            },
            {
                "value": "ehime",
                "label": "Ehime"
            },
            {
                "value": "fukui",
                "label": "Fukui"
            },
            {
                "value": "fukuoka",
                "label": "Fukuoka"
            },
            {
                "value": "fukushima",
                "label": "Fukushima"
            },
            {
                "value": "gifu",
                "label": "Gifu"
            },
            {
                "value": "gumma",
                "label": "Gumma"
            },
            {
                "value": "hiroshima",
                "label": "Hiroshima"
            },
            {
                "value": "hokkaido",
                "label": "Hokkaido"
            },
            {
                "value": "hyogo",
                "label": "Hyogo"
            },
            {
                "value": "ibaraki",
                "label": "Ibaraki"
            },
            {
                "value": "ishikawa",
                "label": "Ishikawa"
            },
            {
                "value": "iwate",
                "label": "Iwate"
            },
            {
                "value": "kagawa",
                "label": "Kagawa"
            },
            {
                "value": "kagoshima",
                "label": "Kagoshima"
            },
            {
                "value": "kanagawa",
                "label": "Kanagawa"
            },
            {
                "value": "kochi",
                "label": "Kochi"
            },
            {
                "value": "kumamoto",
                "label": "Kumamoto"
            },
            {
                "value": "kyoto",
                "label": "Kyoto"
            },
            {
                "value": "mie",
                "label": "Mie"
            },
            {
                "value": "miyagi",
                "label": "Miyagi"
            },
            {
                "value": "miyazaki",
                "label": "Miyazaki"
            },
            {
                "value": "nagano",
                "label": "Nagano"
            },
            {
                "value": "nagasaki",
                "label": "Nagasaki"
            },
            {
                "value": "nara",
                "label": "Nara"
            },
            {
                "value": "niigata",
                "label": "Niigata"
            },
            {
                "value": "oita",
                "label": "Oita"
            },
            {
                "value": "okayama",
                "label": "Okayama"
            },
            {
                "value": "okinawa",
                "label": "Okinawa"
            },
            {
                "value": "osaka",
                "label": "Osaka"
            },
            {
                "value": "saga",
                "label": "Saga"
            },
            {
                "value": "saitama",
                "label": "Saitama"
            },
            {
                "value": "shiga",
                "label": "Shiga"
            },
            {
                "value": "shimane",
                "label": "Shimane"
            },
            {
                "value": "shizuoka",
                "label": "Shizuoka"
            },
            {
                "value": "tochigi",
                "label": "Tochigi"
            },
            {
                "value": "tokushima",
                "label": "Tokushima"
            },
            {
                "value": "tokyo",
                "label": "Tokyo"
            },
            {
                "value": "tottori",
                "label": "Tottori"
            },
            {
                "value": "toyama",
                "label": "Toyama"
            },
            {
                "value": "wakayama",
                "label": "Wakayama"
            },
            {
                "value": "yamagata",
                "label": "Yamagata"
            },
            {
                "value": "yamaguchi",
                "label": "Yamaguchi"
            },
            {
                "value": "yamanashi",
                "label": "Yamanashi"
            }
        ]
    },
    {
        "country": {
            "label": "Jordan",
            "value": "jordan"
        },
        "states": [
            {
                "value": "ajlun",
                "label": "Ajlun"
            },
            {
                "value": "al 'aqabah",
                "label": "Al 'Aqabah"
            },
            {
                "value": "al balqa'",
                "label": "Al Balqa'"
            },
            {
                "value": "al karak",
                "label": "Al Karak"
            },
            {
                "value": "al mafraq",
                "label": "Al Mafraq"
            },
            {
                "value": "'amman",
                "label": "'Amman"
            },
            {
                "value": "at tafilah",
                "label": "At Tafilah"
            },
            {
                "value": "az zarqa'",
                "label": "Az Zarqa'"
            },
            {
                "value": "irbid",
                "label": "Irbid"
            },
            {
                "value": "jarash",
                "label": "Jarash"
            },
            {
                "value": "ma'an",
                "label": "Ma'an"
            },
            {
                "value": "madaba",
                "label": "Madaba"
            }
        ]
    },
    {
        "country": {
            "label": "Kazakhstan",
            "value": "kazakhstan"
        },
        "states": [
            {
                "value": "almaty oblysy",
                "label": "Almaty Oblysy"
            },
            {
                "value": "almaty qalasy",
                "label": "Almaty Qalasy"
            },
            {
                "value": "aqmola oblysy",
                "label": "Aqmola Oblysy"
            },
            {
                "value": "aqtobe oblysy",
                "label": "Aqtobe Oblysy"
            },
            {
                "value": "astana qalasy",
                "label": "Astana Qalasy"
            },
            {
                "value": "atyrau oblysy",
                "label": "Atyrau Oblysy"
            },
            {
                "value": "batys qazaqstan oblysy",
                "label": "Batys Qazaqstan Oblysy"
            },
            {
                "value": "bayqongyr qalasy",
                "label": "Bayqongyr Qalasy"
            },
            {
                "value": "mangghystau oblysy",
                "label": "Mangghystau Oblysy"
            },
            {
                "value": "ongtustik qazaqstan oblysy",
                "label": "Ongtustik Qazaqstan Oblysy"
            },
            {
                "value": "pavlodar oblysy",
                "label": "Pavlodar Oblysy"
            },
            {
                "value": "qaraghandy oblysy",
                "label": "Qaraghandy Oblysy"
            },
            {
                "value": "qostanay oblysy",
                "label": "Qostanay Oblysy"
            },
            {
                "value": "qyzylorda oblysy",
                "label": "Qyzylorda Oblysy"
            },
            {
                "value": "shyghys qazaqstan oblysy",
                "label": "Shyghys Qazaqstan Oblysy"
            },
            {
                "value": "soltustik qazaqstan oblysy",
                "label": "Soltustik Qazaqstan Oblysy"
            },
            {
                "value": "zhambyl oblysy",
                "label": "Zhambyl Oblysy"
            }
        ]
    },
    {
        "country": {
            "label": "Kenya",
            "value": "kenya"
        },
        "states": [
            {
                "value": "central",
                "label": "Central"
            },
            {
                "value": "coast",
                "label": "Coast"
            },
            {
                "value": "eastern",
                "label": "Eastern"
            },
            {
                "value": "nairobi area",
                "label": "Nairobi Area"
            },
            {
                "value": "north eastern",
                "label": "North Eastern"
            },
            {
                "value": "nyanza",
                "label": "Nyanza"
            },
            {
                "value": "rift valley",
                "label": "Rift Valley"
            },
            {
                "value": "western",
                "label": "Western"
            }
        ]
    },
    {
        "country": {
            "label": "Kiribati",
            "value": "kiribati"
        },
        "states": []
    },
    {
        "country": {
            "label": "Korea North",
            "value": "korea north"
        },
        "states": [
            {
                "value": "chagang",
                "label": "Chagang"
            },
            {
                "value": "north hamgyong",
                "label": "North Hamgyong"
            },
            {
                "value": "south hamgyong",
                "label": "South Hamgyong"
            },
            {
                "value": "north hwanghae",
                "label": "North Hwanghae"
            },
            {
                "value": "south hwanghae",
                "label": "South Hwanghae"
            },
            {
                "value": "kangwon",
                "label": "Kangwon"
            },
            {
                "value": "north p'yongan",
                "label": "North P'yongan"
            },
            {
                "value": "south p'yongan",
                "label": "South P'yongan"
            },
            {
                "value": "yanggang",
                "label": "Yanggang"
            },
            {
                "value": "kaesong",
                "label": "Kaesong"
            },
            {
                "value": "najin",
                "label": "Najin"
            },
            {
                "value": "namp'o",
                "label": "Namp'o"
            },
            {
                "value": "pyongyang",
                "label": "Pyongyang"
            }
        ]
    },
    {
        "country": {
            "label": "Korea South",
            "value": "korea south"
        },
        "states": [
            {
                "value": "seoul",
                "label": "Seoul"
            },
            {
                "value": "busan city",
                "label": "Busan City"
            },
            {
                "value": "daegu city",
                "label": "Daegu City"
            },
            {
                "value": "incheon city",
                "label": "Incheon City"
            },
            {
                "value": "gwangju city",
                "label": "Gwangju City"
            },
            {
                "value": "daejeon city",
                "label": "Daejeon City"
            },
            {
                "value": "ulsan",
                "label": "Ulsan"
            },
            {
                "value": "gyeonggi province",
                "label": "Gyeonggi Province"
            },
            {
                "value": "gangwon province",
                "label": "Gangwon Province"
            },
            {
                "value": "north chungcheong province",
                "label": "North Chungcheong Province"
            },
            {
                "value": "south chungcheong province",
                "label": "South Chungcheong Province"
            },
            {
                "value": "north jeolla province",
                "label": "North Jeolla Province"
            },
            {
                "value": "south jeolla province",
                "label": "South Jeolla Province"
            },
            {
                "value": "north gyeongsang province",
                "label": "North Gyeongsang Province"
            },
            {
                "value": "south gyeongsang province",
                "label": "South Gyeongsang Province"
            },
            {
                "value": "jeju",
                "label": "Jeju"
            }
        ]
    },
    {
        "country": {
            "label": "Kuwait",
            "value": "kuwait"
        },
        "states": [
            {
                "value": "al ahmadi",
                "label": "Al Ahmadi"
            },
            {
                "value": "al farwaniyah",
                "label": "Al Farwaniyah"
            },
            {
                "value": "al asimah",
                "label": "Al Asimah"
            },
            {
                "value": "al jahra",
                "label": "Al Jahra"
            },
            {
                "value": "hawalli",
                "label": "Hawalli"
            },
            {
                "value": "mubarak al-kabeer",
                "label": "Mubarak Al-Kabeer"
            }
        ]
    },
    {
        "country": {
            "label": "Kyrgyzstan",
            "value": "kyrgyzstan"
        },
        "states": [
            {
                "value": "batken oblasty",
                "label": "Batken Oblasty"
            },
            {
                "value": "bishkek shaary",
                "label": "Bishkek Shaary"
            },
            {
                "value": "chuy oblasty",
                "label": "Chuy Oblasty"
            },
            {
                "value": "jalal-abad oblasty",
                "label": "Jalal-Abad Oblasty"
            },
            {
                "value": "naryn oblasty",
                "label": "Naryn Oblasty"
            },
            {
                "value": "osh oblasty",
                "label": "Osh Oblasty"
            },
            {
                "value": "talas oblasty",
                "label": "Talas Oblasty"
            },
            {
                "value": "ysyk-kol oblasty",
                "label": "Ysyk-Kol Oblasty"
            }
        ]
    },
    {
        "country": {
            "label": "Laos",
            "value": "laos"
        },
        "states": [
            {
                "value": "attapu",
                "label": "Attapu"
            },
            {
                "value": "bokeo",
                "label": "Bokeo"
            },
            {
                "value": "bolikhamxai",
                "label": "Bolikhamxai"
            },
            {
                "value": "champasak",
                "label": "Champasak"
            },
            {
                "value": "houaphan",
                "label": "Houaphan"
            },
            {
                "value": "khammouan",
                "label": "Khammouan"
            },
            {
                "value": "louangnamtha",
                "label": "Louangnamtha"
            },
            {
                "value": "louangphrabang",
                "label": "Louangphrabang"
            },
            {
                "value": "oudomxai",
                "label": "Oudomxai"
            },
            {
                "value": "phongsali",
                "label": "Phongsali"
            },
            {
                "value": "salavan",
                "label": "Salavan"
            },
            {
                "value": "savannakhet",
                "label": "Savannakhet"
            },
            {
                "value": "viangchan",
                "label": "Viangchan"
            },
            {
                "value": "viangchan",
                "label": "Viangchan"
            },
            {
                "value": "xaignabouli",
                "label": "Xaignabouli"
            },
            {
                "value": "xaisomboun",
                "label": "Xaisomboun"
            },
            {
                "value": "xekong",
                "label": "Xekong"
            },
            {
                "value": "xiangkhoang",
                "label": "Xiangkhoang"
            }
        ]
    },
    {
        "country": {
            "label": "Latvia",
            "value": "latvia"
        },
        "states": [
            {
                "value": "aizkraukles rajons",
                "label": "Aizkraukles Rajons"
            },
            {
                "value": "aluksnes rajons",
                "label": "Aluksnes Rajons"
            },
            {
                "value": "balvu rajons",
                "label": "Balvu Rajons"
            },
            {
                "value": "bauskas rajons",
                "label": "Bauskas Rajons"
            },
            {
                "value": "cesu rajons",
                "label": "Cesu Rajons"
            },
            {
                "value": "daugavpils",
                "label": "Daugavpils"
            },
            {
                "value": "daugavpils rajons",
                "label": "Daugavpils Rajons"
            },
            {
                "value": "dobeles rajons",
                "label": "Dobeles Rajons"
            },
            {
                "value": "gulbenes rajons",
                "label": "Gulbenes Rajons"
            },
            {
                "value": "jekabpils rajons",
                "label": "Jekabpils Rajons"
            },
            {
                "value": "jelgava",
                "label": "Jelgava"
            },
            {
                "value": "jelgavas rajons",
                "label": "Jelgavas Rajons"
            },
            {
                "value": "jurmala",
                "label": "Jurmala"
            },
            {
                "value": "kraslavas rajons",
                "label": "Kraslavas Rajons"
            },
            {
                "value": "kuldigas rajons",
                "label": "Kuldigas Rajons"
            },
            {
                "value": "liepaja",
                "label": "Liepaja"
            },
            {
                "value": "liepajas rajons",
                "label": "Liepajas Rajons"
            },
            {
                "value": "limbazu rajons",
                "label": "Limbazu Rajons"
            },
            {
                "value": "ludzas rajons",
                "label": "Ludzas Rajons"
            },
            {
                "value": "madonas rajons",
                "label": "Madonas Rajons"
            },
            {
                "value": "ogres rajons",
                "label": "Ogres Rajons"
            },
            {
                "value": "preilu rajons",
                "label": "Preilu Rajons"
            },
            {
                "value": "rezekne",
                "label": "Rezekne"
            },
            {
                "value": "rezeknes rajons",
                "label": "Rezeknes Rajons"
            },
            {
                "value": "riga",
                "label": "Riga"
            },
            {
                "value": "rigas rajons",
                "label": "Rigas Rajons"
            },
            {
                "value": "saldus rajons",
                "label": "Saldus Rajons"
            },
            {
                "value": "talsu rajons",
                "label": "Talsu Rajons"
            },
            {
                "value": "tukuma rajons",
                "label": "Tukuma Rajons"
            },
            {
                "value": "valkas rajons",
                "label": "Valkas Rajons"
            },
            {
                "value": "valmieras rajons",
                "label": "Valmieras Rajons"
            },
            {
                "value": "ventspils",
                "label": "Ventspils"
            },
            {
                "value": "ventspils rajons",
                "label": "Ventspils Rajons"
            }
        ]
    },
    {
        "country": {
            "label": "Lebanon",
            "value": "lebanon"
        },
        "states": [
            {
                "value": "beyrouth",
                "label": "Beyrouth"
            },
            {
                "value": "beqaa",
                "label": "Beqaa"
            },
            {
                "value": "liban-nord",
                "label": "Liban-Nord"
            },
            {
                "value": "liban-sud",
                "label": "Liban-Sud"
            },
            {
                "value": "mont-liban",
                "label": "Mont-Liban"
            },
            {
                "value": "nabatiye",
                "label": "Nabatiye"
            }
        ]
    },
    {
        "country": {
            "label": "Lesotho",
            "value": "lesotho"
        },
        "states": [
            {
                "value": "berea",
                "label": "Berea"
            },
            {
                "value": "butha-buthe",
                "label": "Butha-Buthe"
            },
            {
                "value": "leribe",
                "label": "Leribe"
            },
            {
                "value": "mafeteng",
                "label": "Mafeteng"
            },
            {
                "value": "maseru",
                "label": "Maseru"
            },
            {
                "value": "mohale's hoek",
                "label": "Mohale's Hoek"
            },
            {
                "value": "mokhotlong",
                "label": "Mokhotlong"
            },
            {
                "value": "qacha's nek",
                "label": "Qacha's Nek"
            },
            {
                "value": "quthing",
                "label": "Quthing"
            },
            {
                "value": "thaba-tseka",
                "label": "Thaba-Tseka"
            }
        ]
    },
    {
        "country": {
            "label": "Liberia",
            "value": "liberia"
        },
        "states": [
            {
                "value": "bomi",
                "label": "Bomi"
            },
            {
                "value": "bong",
                "label": "Bong"
            },
            {
                "value": "gbarpolu",
                "label": "Gbarpolu"
            },
            {
                "value": "grand bassa",
                "label": "Grand Bassa"
            },
            {
                "value": "grand cape mount",
                "label": "Grand Cape Mount"
            },
            {
                "value": "grand gedeh",
                "label": "Grand Gedeh"
            },
            {
                "value": "grand kru",
                "label": "Grand Kru"
            },
            {
                "value": "lofa",
                "label": "Lofa"
            },
            {
                "value": "margibi",
                "label": "Margibi"
            },
            {
                "value": "maryland",
                "label": "Maryland"
            },
            {
                "value": "montserrado",
                "label": "Montserrado"
            },
            {
                "value": "nimba",
                "label": "Nimba"
            },
            {
                "value": "river cess",
                "label": "River Cess"
            },
            {
                "value": "river gee",
                "label": "River Gee"
            },
            {
                "value": "sinoe",
                "label": "Sinoe"
            }
        ]
    },
    {
        "country": {
            "label": "Libya",
            "value": "libya"
        },
        "states": [
            {
                "value": "ajdabiya",
                "label": "Ajdabiya"
            },
            {
                "value": "al 'aziziyah",
                "label": "Al 'Aziziyah"
            },
            {
                "value": "al fatih",
                "label": "Al Fatih"
            },
            {
                "value": "al jabal al akhdar",
                "label": "Al Jabal al Akhdar"
            },
            {
                "value": "al jufrah",
                "label": "Al Jufrah"
            },
            {
                "value": "al khums",
                "label": "Al Khums"
            },
            {
                "value": "al kufrah",
                "label": "Al Kufrah"
            },
            {
                "value": "an nuqat al khams",
                "label": "An Nuqat al Khams"
            },
            {
                "value": "ash shati'",
                "label": "Ash Shati'"
            },
            {
                "value": "awbari",
                "label": "Awbari"
            },
            {
                "value": "az zawiyah",
                "label": "Az Zawiyah"
            },
            {
                "value": "banghazi",
                "label": "Banghazi"
            },
            {
                "value": "darnah",
                "label": "Darnah"
            },
            {
                "value": "ghadamis",
                "label": "Ghadamis"
            },
            {
                "value": "gharyan",
                "label": "Gharyan"
            },
            {
                "value": "misratah",
                "label": "Misratah"
            },
            {
                "value": "murzuq",
                "label": "Murzuq"
            },
            {
                "value": "sabha",
                "label": "Sabha"
            },
            {
                "value": "sawfajjin",
                "label": "Sawfajjin"
            },
            {
                "value": "surt",
                "label": "Surt"
            },
            {
                "value": "tarabulus",
                "label": "Tarabulus"
            },
            {
                "value": "tarhunah",
                "label": "Tarhunah"
            },
            {
                "value": "tubruq",
                "label": "Tubruq"
            },
            {
                "value": "yafran",
                "label": "Yafran"
            },
            {
                "value": "zlitan",
                "label": "Zlitan"
            }
        ]
    },
    {
        "country": {
            "label": "Liechtenstein",
            "value": "liechtenstein"
        },
        "states": [
            {
                "value": "balzers",
                "label": "Balzers"
            },
            {
                "value": "eschen",
                "label": "Eschen"
            },
            {
                "value": "gamprin",
                "label": "Gamprin"
            },
            {
                "value": "mauren",
                "label": "Mauren"
            },
            {
                "value": "planken",
                "label": "Planken"
            },
            {
                "value": "ruggell",
                "label": "Ruggell"
            },
            {
                "value": "schaan",
                "label": "Schaan"
            },
            {
                "value": "schellenberg",
                "label": "Schellenberg"
            },
            {
                "value": "triesen",
                "label": "Triesen"
            },
            {
                "value": "triesenberg",
                "label": "Triesenberg"
            },
            {
                "value": "vaduz",
                "label": "Vaduz"
            }
        ]
    },
    {
        "country": {
            "label": "Lithuania",
            "value": "lithuania"
        },
        "states": [
            {
                "value": "alytaus",
                "label": "Alytaus"
            },
            {
                "value": "kauno",
                "label": "Kauno"
            },
            {
                "value": "klaipedos",
                "label": "Klaipedos"
            },
            {
                "value": "marijampoles",
                "label": "Marijampoles"
            },
            {
                "value": "panevezio",
                "label": "Panevezio"
            },
            {
                "value": "siauliu",
                "label": "Siauliu"
            },
            {
                "value": "taurages",
                "label": "Taurages"
            },
            {
                "value": "telsiu",
                "label": "Telsiu"
            },
            {
                "value": "utenos",
                "label": "Utenos"
            },
            {
                "value": "vilniaus",
                "label": "Vilniaus"
            }
        ]
    },
    {
        "country": {
            "label": "Luxembourg",
            "value": "luxembourg"
        },
        "states": [
            {
                "value": "diekirch",
                "label": "Diekirch"
            },
            {
                "value": "grevenmacher",
                "label": "Grevenmacher"
            },
            {
                "value": "luxembourg",
                "label": "Luxembourg"
            }
        ]
    },
    {
        "country": {
            "label": "Macedonia",
            "value": "macedonia"
        },
        "states": [
            {
                "value": "aerodrom",
                "label": "Aerodrom"
            },
            {
                "value": "aracinovo",
                "label": "Aracinovo"
            },
            {
                "value": "berovo",
                "label": "Berovo"
            },
            {
                "value": "bitola",
                "label": "Bitola"
            },
            {
                "value": "bogdanci",
                "label": "Bogdanci"
            },
            {
                "value": "bogovinje",
                "label": "Bogovinje"
            },
            {
                "value": "bosilovo",
                "label": "Bosilovo"
            },
            {
                "value": "brvenica",
                "label": "Brvenica"
            },
            {
                "value": "butel",
                "label": "Butel"
            },
            {
                "value": "cair",
                "label": "Cair"
            },
            {
                "value": "caska",
                "label": "Caska"
            },
            {
                "value": "centar",
                "label": "Centar"
            },
            {
                "value": "centar zupa",
                "label": "Centar Zupa"
            },
            {
                "value": "cesinovo",
                "label": "Cesinovo"
            },
            {
                "value": "cucer-sandevo",
                "label": "Cucer-Sandevo"
            },
            {
                "value": "debar",
                "label": "Debar"
            },
            {
                "value": "debartsa",
                "label": "Debartsa"
            },
            {
                "value": "delcevo",
                "label": "Delcevo"
            },
            {
                "value": "demir hisar",
                "label": "Demir Hisar"
            },
            {
                "value": "demir kapija",
                "label": "Demir Kapija"
            },
            {
                "value": "dojran",
                "label": "Dojran"
            },
            {
                "value": "dolneni",
                "label": "Dolneni"
            },
            {
                "value": "drugovo",
                "label": "Drugovo"
            },
            {
                "value": "gazi baba",
                "label": "Gazi Baba"
            },
            {
                "value": "gevgelija",
                "label": "Gevgelija"
            },
            {
                "value": "gjorce petrov",
                "label": "Gjorce Petrov"
            },
            {
                "value": "gostivar",
                "label": "Gostivar"
            },
            {
                "value": "gradsko",
                "label": "Gradsko"
            },
            {
                "value": "ilinden",
                "label": "Ilinden"
            },
            {
                "value": "jegunovce",
                "label": "Jegunovce"
            },
            {
                "value": "karbinci",
                "label": "Karbinci"
            },
            {
                "value": "karpos",
                "label": "Karpos"
            },
            {
                "value": "kavadarci",
                "label": "Kavadarci"
            },
            {
                "value": "kicevo",
                "label": "Kicevo"
            },
            {
                "value": "kisela voda",
                "label": "Kisela Voda"
            },
            {
                "value": "kocani",
                "label": "Kocani"
            },
            {
                "value": "konce",
                "label": "Konce"
            },
            {
                "value": "kratovo",
                "label": "Kratovo"
            },
            {
                "value": "kriva palanka",
                "label": "Kriva Palanka"
            },
            {
                "value": "krivogastani",
                "label": "Krivogastani"
            },
            {
                "value": "krusevo",
                "label": "Krusevo"
            },
            {
                "value": "kumanovo",
                "label": "Kumanovo"
            },
            {
                "value": "lipkovo",
                "label": "Lipkovo"
            },
            {
                "value": "lozovo",
                "label": "Lozovo"
            },
            {
                "value": "makedonska kamenica",
                "label": "Makedonska Kamenica"
            },
            {
                "value": "makedonski brod",
                "label": "Makedonski Brod"
            },
            {
                "value": "mavrovo i rastusa",
                "label": "Mavrovo i Rastusa"
            },
            {
                "value": "mogila",
                "label": "Mogila"
            },
            {
                "value": "negotino",
                "label": "Negotino"
            },
            {
                "value": "novaci",
                "label": "Novaci"
            },
            {
                "value": "novo selo",
                "label": "Novo Selo"
            },
            {
                "value": "ohrid",
                "label": "Ohrid"
            },
            {
                "value": "oslomej",
                "label": "Oslomej"
            },
            {
                "value": "pehcevo",
                "label": "Pehcevo"
            },
            {
                "value": "petrovec",
                "label": "Petrovec"
            },
            {
                "value": "plasnica",
                "label": "Plasnica"
            },
            {
                "value": "prilep",
                "label": "Prilep"
            },
            {
                "value": "probistip",
                "label": "Probistip"
            },
            {
                "value": "radovis",
                "label": "Radovis"
            },
            {
                "value": "rankovce",
                "label": "Rankovce"
            },
            {
                "value": "resen",
                "label": "Resen"
            },
            {
                "value": "rosoman",
                "label": "Rosoman"
            },
            {
                "value": "saraj",
                "label": "Saraj"
            },
            {
                "value": "skopje",
                "label": "Skopje"
            },
            {
                "value": "sopiste",
                "label": "Sopiste"
            },
            {
                "value": "staro nagoricane",
                "label": "Staro Nagoricane"
            },
            {
                "value": "stip",
                "label": "Stip"
            },
            {
                "value": "struga",
                "label": "Struga"
            },
            {
                "value": "strumica",
                "label": "Strumica"
            },
            {
                "value": "studenicani",
                "label": "Studenicani"
            },
            {
                "value": "suto orizari",
                "label": "Suto Orizari"
            },
            {
                "value": "sveti nikole",
                "label": "Sveti Nikole"
            },
            {
                "value": "tearce",
                "label": "Tearce"
            },
            {
                "value": "tetovo",
                "label": "Tetovo"
            },
            {
                "value": "valandovo",
                "label": "Valandovo"
            },
            {
                "value": "vasilevo",
                "label": "Vasilevo"
            },
            {
                "value": "veles",
                "label": "Veles"
            },
            {
                "value": "vevcani",
                "label": "Vevcani"
            },
            {
                "value": "vinica",
                "label": "Vinica"
            },
            {
                "value": "vranestica",
                "label": "Vranestica"
            },
            {
                "value": "vrapciste",
                "label": "Vrapciste"
            },
            {
                "value": "zajas",
                "label": "Zajas"
            },
            {
                "value": "zelenikovo",
                "label": "Zelenikovo"
            },
            {
                "value": "zelino",
                "label": "Zelino"
            },
            {
                "value": "zrnovci",
                "label": "Zrnovci"
            }
        ]
    },
    {
        "country": {
            "label": "Madagascar",
            "value": "madagascar"
        },
        "states": [
            {
                "value": "antananarivo",
                "label": "Antananarivo"
            },
            {
                "value": "antsiranana",
                "label": "Antsiranana"
            },
            {
                "value": "fianarantsoa",
                "label": "Fianarantsoa"
            },
            {
                "value": "mahajanga",
                "label": "Mahajanga"
            },
            {
                "value": "toamasina",
                "label": "Toamasina"
            },
            {
                "value": "toliara",
                "label": "Toliara"
            }
        ]
    },
    {
        "country": {
            "label": "Malawi",
            "value": "malawi"
        },
        "states": [
            {
                "value": "balaka",
                "label": "Balaka"
            },
            {
                "value": "blantyre",
                "label": "Blantyre"
            },
            {
                "value": "chikwawa",
                "label": "Chikwawa"
            },
            {
                "value": "chiradzulu",
                "label": "Chiradzulu"
            },
            {
                "value": "chitipa",
                "label": "Chitipa"
            },
            {
                "value": "dedza",
                "label": "Dedza"
            },
            {
                "value": "dowa",
                "label": "Dowa"
            },
            {
                "value": "karonga",
                "label": "Karonga"
            },
            {
                "value": "kasungu",
                "label": "Kasungu"
            },
            {
                "value": "likoma",
                "label": "Likoma"
            },
            {
                "value": "lilongwe",
                "label": "Lilongwe"
            },
            {
                "value": "machinga",
                "label": "Machinga"
            },
            {
                "value": "mangochi",
                "label": "Mangochi"
            },
            {
                "value": "mchinji",
                "label": "Mchinji"
            },
            {
                "value": "mulanje",
                "label": "Mulanje"
            },
            {
                "value": "mwanza",
                "label": "Mwanza"
            },
            {
                "value": "mzimba",
                "label": "Mzimba"
            },
            {
                "value": "ntcheu",
                "label": "Ntcheu"
            },
            {
                "value": "nkhata bay",
                "label": "Nkhata Bay"
            },
            {
                "value": "nkhotakota",
                "label": "Nkhotakota"
            },
            {
                "value": "nsanje",
                "label": "Nsanje"
            },
            {
                "value": "ntchisi",
                "label": "Ntchisi"
            },
            {
                "value": "phalombe",
                "label": "Phalombe"
            },
            {
                "value": "rumphi",
                "label": "Rumphi"
            },
            {
                "value": "salima",
                "label": "Salima"
            },
            {
                "value": "thyolo",
                "label": "Thyolo"
            },
            {
                "value": "zomba",
                "label": "Zomba"
            }
        ]
    },
    {
        "country": {
            "label": "Malaysia",
            "value": "malaysia"
        },
        "states": [
            {
                "value": "johor",
                "label": "Johor"
            },
            {
                "value": "kedah",
                "label": "Kedah"
            },
            {
                "value": "kelantan",
                "label": "Kelantan"
            },
            {
                "value": "kuala lumpur",
                "label": "Kuala Lumpur"
            },
            {
                "value": "labuan",
                "label": "Labuan"
            },
            {
                "value": "malacca",
                "label": "Malacca"
            },
            {
                "value": "negeri sembilan",
                "label": "Negeri Sembilan"
            },
            {
                "value": "pahang",
                "label": "Pahang"
            },
            {
                "value": "perak",
                "label": "Perak"
            },
            {
                "value": "perlis",
                "label": "Perlis"
            },
            {
                "value": "penang",
                "label": "Penang"
            },
            {
                "value": "sabah",
                "label": "Sabah"
            },
            {
                "value": "sarawak",
                "label": "Sarawak"
            },
            {
                "value": "selangor",
                "label": "Selangor"
            },
            {
                "value": "terengganu",
                "label": "Terengganu"
            }
        ]
    },
    {
        "country": {
            "label": "Maldives",
            "value": "maldives"
        },
        "states": [
            {
                "value": "alifu",
                "label": "Alifu"
            },
            {
                "value": "baa",
                "label": "Baa"
            },
            {
                "value": "dhaalu",
                "label": "Dhaalu"
            },
            {
                "value": "faafu",
                "label": "Faafu"
            },
            {
                "value": "gaafu alifu",
                "label": "Gaafu Alifu"
            },
            {
                "value": "gaafu dhaalu",
                "label": "Gaafu Dhaalu"
            },
            {
                "value": "gnaviyani",
                "label": "Gnaviyani"
            },
            {
                "value": "haa alifu",
                "label": "Haa Alifu"
            },
            {
                "value": "haa dhaalu",
                "label": "Haa Dhaalu"
            },
            {
                "value": "kaafu",
                "label": "Kaafu"
            },
            {
                "value": "laamu",
                "label": "Laamu"
            },
            {
                "value": "lhaviyani",
                "label": "Lhaviyani"
            },
            {
                "value": "maale",
                "label": "Maale"
            },
            {
                "value": "meemu",
                "label": "Meemu"
            },
            {
                "value": "noonu",
                "label": "Noonu"
            },
            {
                "value": "raa",
                "label": "Raa"
            },
            {
                "value": "seenu",
                "label": "Seenu"
            },
            {
                "value": "shaviyani",
                "label": "Shaviyani"
            },
            {
                "value": "thaa",
                "label": "Thaa"
            },
            {
                "value": "vaavu",
                "label": "Vaavu"
            }
        ]
    },
    {
        "country": {
            "label": "Mali",
            "value": "mali"
        },
        "states": [
            {
                "value": "bamako (capital)",
                "label": "Bamako (Capital)"
            },
            {
                "value": "gao",
                "label": "Gao"
            },
            {
                "value": "kayes",
                "label": "Kayes"
            },
            {
                "value": "kidal",
                "label": "Kidal"
            },
            {
                "value": "koulikoro",
                "label": "Koulikoro"
            },
            {
                "value": "mopti",
                "label": "Mopti"
            },
            {
                "value": "segou",
                "label": "Segou"
            },
            {
                "value": "sikasso",
                "label": "Sikasso"
            },
            {
                "value": "tombouctou",
                "label": "Tombouctou"
            }
        ]
    },
    {
        "country": {
            "label": "Malta",
            "value": "malta"
        },
        "states": []
    },
    {
        "country": {
            "label": "Marshall Islands",
            "value": "marshall islands"
        },
        "states": []
    },
    {
        "country": {
            "label": "Mauritania",
            "value": "mauritania"
        },
        "states": [
            {
                "value": "adrar",
                "label": "Adrar"
            },
            {
                "value": "assaba",
                "label": "Assaba"
            },
            {
                "value": "brakna",
                "label": "Brakna"
            },
            {
                "value": "dakhlet nouadhibou",
                "label": "Dakhlet Nouadhibou"
            },
            {
                "value": "gorgol",
                "label": "Gorgol"
            },
            {
                "value": "guidimaka",
                "label": "Guidimaka"
            },
            {
                "value": "hodh ech chargui",
                "label": "Hodh Ech Chargui"
            },
            {
                "value": "hodh el gharbi",
                "label": "Hodh El Gharbi"
            },
            {
                "value": "inchiri",
                "label": "Inchiri"
            },
            {
                "value": "nouakchott",
                "label": "Nouakchott"
            },
            {
                "value": "tagant",
                "label": "Tagant"
            },
            {
                "value": "tiris zemmour",
                "label": "Tiris Zemmour"
            },
            {
                "value": "trarza",
                "label": "Trarza"
            }
        ]
    },
    {
        "country": {
            "label": "Mauritius",
            "value": "mauritius"
        },
        "states": [
            {
                "value": "agalega islands",
                "label": "Agalega Islands"
            },
            {
                "value": "black river",
                "label": "Black River"
            },
            {
                "value": "cargados carajos shoals",
                "label": "Cargados Carajos Shoals"
            },
            {
                "value": "flacq",
                "label": "Flacq"
            },
            {
                "value": "grand port",
                "label": "Grand Port"
            },
            {
                "value": "moka",
                "label": "Moka"
            },
            {
                "value": "pamplemousses",
                "label": "Pamplemousses"
            },
            {
                "value": "plaines wilhems",
                "label": "Plaines Wilhems"
            },
            {
                "value": "port louis",
                "label": "Port Louis"
            },
            {
                "value": "riviere du rempart",
                "label": "Riviere du Rempart"
            },
            {
                "value": "rodrigues",
                "label": "Rodrigues"
            },
            {
                "value": "savanne",
                "label": "Savanne"
            }
        ]
    },
    {
        "country": {
            "label": "Mexico",
            "value": "mexico"
        },
        "states": [
            {
                "value": "aguascalientes",
                "label": "Aguascalientes"
            },
            {
                "value": "baja california",
                "label": "Baja California"
            },
            {
                "value": "baja california sur",
                "label": "Baja California Sur"
            },
            {
                "value": "campeche",
                "label": "Campeche"
            },
            {
                "value": "chiapas",
                "label": "Chiapas"
            },
            {
                "value": "chihuahua",
                "label": "Chihuahua"
            },
            {
                "value": "coahuila de zaragoza",
                "label": "Coahuila de Zaragoza"
            },
            {
                "value": "colima",
                "label": "Colima"
            },
            {
                "value": "distrito federal",
                "label": "Distrito Federal"
            },
            {
                "value": "durango",
                "label": "Durango"
            },
            {
                "value": "guanajuato",
                "label": "Guanajuato"
            },
            {
                "value": "guerrero",
                "label": "Guerrero"
            },
            {
                "value": "hidalgo",
                "label": "Hidalgo"
            },
            {
                "value": "jalisco",
                "label": "Jalisco"
            },
            {
                "value": "mexico",
                "label": "Mexico"
            },
            {
                "value": "michoacan de ocampo",
                "label": "Michoacan de Ocampo"
            },
            {
                "value": "morelos",
                "label": "Morelos"
            },
            {
                "value": "nayarit",
                "label": "Nayarit"
            },
            {
                "value": "nuevo leon",
                "label": "Nuevo Leon"
            },
            {
                "value": "oaxaca",
                "label": "Oaxaca"
            },
            {
                "value": "puebla",
                "label": "Puebla"
            },
            {
                "value": "queretaro de arteaga",
                "label": "Queretaro de Arteaga"
            },
            {
                "value": "quintana roo",
                "label": "Quintana Roo"
            },
            {
                "value": "san luis potosi",
                "label": "San Luis Potosi"
            },
            {
                "value": "sinaloa",
                "label": "Sinaloa"
            },
            {
                "value": "sonora",
                "label": "Sonora"
            },
            {
                "value": "tabasco",
                "label": "Tabasco"
            },
            {
                "value": "tamaulipas",
                "label": "Tamaulipas"
            },
            {
                "value": "tlaxcala",
                "label": "Tlaxcala"
            },
            {
                "value": "veracruz-llave",
                "label": "Veracruz-Llave"
            },
            {
                "value": "yucatan",
                "label": "Yucatan"
            },
            {
                "value": "zacatecas",
                "label": "Zacatecas"
            }
        ]
    },
    {
        "country": {
            "label": "Micronesia",
            "value": "micronesia"
        },
        "states": []
    },
    {
        "country": {
            "label": "Moldova",
            "value": "moldova"
        },
        "states": [
            {
                "value": "anenii noi",
                "label": "Anenii Noi"
            },
            {
                "value": "basarabeasca",
                "label": "Basarabeasca"
            },
            {
                "value": "briceni",
                "label": "Briceni"
            },
            {
                "value": "cahul",
                "label": "Cahul"
            },
            {
                "value": "cantemir",
                "label": "Cantemir"
            },
            {
                "value": "calarasi",
                "label": "Calarasi"
            },
            {
                "value": "causeni",
                "label": "Causeni"
            },
            {
                "value": "cimislia",
                "label": "Cimislia"
            },
            {
                "value": "criuleni",
                "label": "Criuleni"
            },
            {
                "value": "donduseni",
                "label": "Donduseni"
            },
            {
                "value": "drochia",
                "label": "Drochia"
            },
            {
                "value": "dubasari",
                "label": "Dubasari"
            },
            {
                "value": "edinet",
                "label": "Edinet"
            },
            {
                "value": "falesti",
                "label": "Falesti"
            },
            {
                "value": "floresti",
                "label": "Floresti"
            },
            {
                "value": "glodeni",
                "label": "Glodeni"
            },
            {
                "value": "hincesti",
                "label": "Hincesti"
            },
            {
                "value": "ialoveni",
                "label": "Ialoveni"
            },
            {
                "value": "leova",
                "label": "Leova"
            },
            {
                "value": "nisporeni",
                "label": "Nisporeni"
            },
            {
                "value": "ocnita",
                "label": "Ocnita"
            },
            {
                "value": "orhei",
                "label": "Orhei"
            },
            {
                "value": "rezina",
                "label": "Rezina"
            },
            {
                "value": "riscani",
                "label": "Riscani"
            },
            {
                "value": "singerei",
                "label": "Singerei"
            },
            {
                "value": "soldanesti",
                "label": "Soldanesti"
            },
            {
                "value": "soroca",
                "label": "Soroca"
            },
            {
                "value": "stefan-voda",
                "label": "Stefan-Voda"
            },
            {
                "value": "straseni",
                "label": "Straseni"
            },
            {
                "value": "taraclia",
                "label": "Taraclia"
            },
            {
                "value": "telenesti",
                "label": "Telenesti"
            },
            {
                "value": "ungheni",
                "label": "Ungheni"
            },
            {
                "value": "balti",
                "label": "Balti"
            },
            {
                "value": "bender",
                "label": "Bender"
            },
            {
                "value": "chisinau",
                "label": "Chisinau"
            },
            {
                "value": "gagauzia",
                "label": "Gagauzia"
            },
            {
                "value": "stinga nistrului",
                "label": "Stinga Nistrului"
            }
        ]
    },
    {
        "country": {
            "label": "Mongolia",
            "value": "mongolia"
        },
        "states": [
            {
                "value": "arhangay",
                "label": "Arhangay"
            },
            {
                "value": "bayanhongor",
                "label": "Bayanhongor"
            },
            {
                "value": "bayan-olgiy",
                "label": "Bayan-Olgiy"
            },
            {
                "value": "bulgan",
                "label": "Bulgan"
            },
            {
                "value": "darhan uul",
                "label": "Darhan Uul"
            },
            {
                "value": "dornod",
                "label": "Dornod"
            },
            {
                "value": "dornogovi",
                "label": "Dornogovi"
            },
            {
                "value": "dundgovi",
                "label": "Dundgovi"
            },
            {
                "value": "dzavhan",
                "label": "Dzavhan"
            },
            {
                "value": "govi-altay",
                "label": "Govi-Altay"
            },
            {
                "value": "govi-sumber",
                "label": "Govi-Sumber"
            },
            {
                "value": "hentiy",
                "label": "Hentiy"
            },
            {
                "value": "hovd",
                "label": "Hovd"
            },
            {
                "value": "hovsgol",
                "label": "Hovsgol"
            },
            {
                "value": "omnogovi",
                "label": "Omnogovi"
            },
            {
                "value": "orhon",
                "label": "Orhon"
            },
            {
                "value": "ovorhangay",
                "label": "Ovorhangay"
            },
            {
                "value": "selenge",
                "label": "Selenge"
            },
            {
                "value": "suhbaatar",
                "label": "Suhbaatar"
            },
            {
                "value": "tov",
                "label": "Tov"
            },
            {
                "value": "ulaanbaatar",
                "label": "Ulaanbaatar"
            },
            {
                "value": "uvs",
                "label": "Uvs"
            }
        ]
    },
    {
        "country": {
            "label": "Morocco",
            "value": "morocco"
        },
        "states": [
            {
                "value": "agadir",
                "label": "Agadir"
            },
            {
                "value": "al hoceima",
                "label": "Al Hoceima"
            },
            {
                "value": "azilal",
                "label": "Azilal"
            },
            {
                "value": "beni mellal",
                "label": "Beni Mellal"
            },
            {
                "value": "ben slimane",
                "label": "Ben Slimane"
            },
            {
                "value": "boulemane",
                "label": "Boulemane"
            },
            {
                "value": "casablanca",
                "label": "Casablanca"
            },
            {
                "value": "chaouen",
                "label": "Chaouen"
            },
            {
                "value": "el jadida",
                "label": "El Jadida"
            },
            {
                "value": "el kelaa des sraghna",
                "label": "El Kelaa des Sraghna"
            },
            {
                "value": "er rachidia",
                "label": "Er Rachidia"
            },
            {
                "value": "essaouira",
                "label": "Essaouira"
            },
            {
                "value": "fes",
                "label": "Fes"
            },
            {
                "value": "figuig",
                "label": "Figuig"
            },
            {
                "value": "guelmim",
                "label": "Guelmim"
            },
            {
                "value": "ifrane",
                "label": "Ifrane"
            },
            {
                "value": "kenitra",
                "label": "Kenitra"
            },
            {
                "value": "khemisset",
                "label": "Khemisset"
            },
            {
                "value": "khenifra",
                "label": "Khenifra"
            },
            {
                "value": "khouribga",
                "label": "Khouribga"
            },
            {
                "value": "laayoune",
                "label": "Laayoune"
            },
            {
                "value": "larache",
                "label": "Larache"
            },
            {
                "value": "marrakech",
                "label": "Marrakech"
            },
            {
                "value": "meknes",
                "label": "Meknes"
            },
            {
                "value": "nador",
                "label": "Nador"
            },
            {
                "value": "ouarzazate",
                "label": "Ouarzazate"
            },
            {
                "value": "oujda",
                "label": "Oujda"
            },
            {
                "value": "rabat-sale",
                "label": "Rabat-Sale"
            },
            {
                "value": "safi",
                "label": "Safi"
            },
            {
                "value": "settat",
                "label": "Settat"
            },
            {
                "value": "sidi kacem",
                "label": "Sidi Kacem"
            },
            {
                "value": "tangier",
                "label": "Tangier"
            },
            {
                "value": "tan-tan",
                "label": "Tan-Tan"
            },
            {
                "value": "taounate",
                "label": "Taounate"
            },
            {
                "value": "taroudannt",
                "label": "Taroudannt"
            },
            {
                "value": "tata",
                "label": "Tata"
            },
            {
                "value": "taza",
                "label": "Taza"
            },
            {
                "value": "tetouan",
                "label": "Tetouan"
            },
            {
                "value": "tiznit",
                "label": "Tiznit"
            }
        ]
    },
    {
        "country": {
            "label": "Monaco",
            "value": "monaco"
        },
        "states": []
    },
    {
        "country": {
            "label": "Mozambique",
            "value": "mozambique"
        },
        "states": [
            {
                "value": "cabo delgado",
                "label": "Cabo Delgado"
            },
            {
                "value": "gaza",
                "label": "Gaza"
            },
            {
                "value": "inhambane",
                "label": "Inhambane"
            },
            {
                "value": "manica",
                "label": "Manica"
            },
            {
                "value": "maputo",
                "label": "Maputo"
            },
            {
                "value": "cidade de maputo",
                "label": "Cidade de Maputo"
            },
            {
                "value": "nampula",
                "label": "Nampula"
            },
            {
                "value": "niassa",
                "label": "Niassa"
            },
            {
                "value": "sofala",
                "label": "Sofala"
            },
            {
                "value": "tete",
                "label": "Tete"
            },
            {
                "value": "zambezia",
                "label": "Zambezia"
            }
        ]
    },
    {
        "country": {
            "label": "Namibia",
            "value": "namibia"
        },
        "states": [
            {
                "value": "caprivi",
                "label": "Caprivi"
            },
            {
                "value": "erongo",
                "label": "Erongo"
            },
            {
                "value": "hardap",
                "label": "Hardap"
            },
            {
                "value": "karas",
                "label": "Karas"
            },
            {
                "value": "khomas",
                "label": "Khomas"
            },
            {
                "value": "kunene",
                "label": "Kunene"
            },
            {
                "value": "ohangwena",
                "label": "Ohangwena"
            },
            {
                "value": "okavango",
                "label": "Okavango"
            },
            {
                "value": "omaheke",
                "label": "Omaheke"
            },
            {
                "value": "omusati",
                "label": "Omusati"
            },
            {
                "value": "oshana",
                "label": "Oshana"
            },
            {
                "value": "oshikoto",
                "label": "Oshikoto"
            },
            {
                "value": "otjozondjupa",
                "label": "Otjozondjupa"
            }
        ]
    },
    {
        "country": {
            "label": "Nauru",
            "value": "nauru"
        },
        "states": []
    },
    {
        "country": {
            "label": "Nepal",
            "value": "nepal"
        },
        "states": [
            {
                "value": "bagmati",
                "label": "Bagmati"
            },
            {
                "value": "bheri",
                "label": "Bheri"
            },
            {
                "value": "dhawalagiri",
                "label": "Dhawalagiri"
            },
            {
                "value": "gandaki",
                "label": "Gandaki"
            },
            {
                "value": "janakpur",
                "label": "Janakpur"
            },
            {
                "value": "karnali",
                "label": "Karnali"
            },
            {
                "value": "kosi",
                "label": "Kosi"
            },
            {
                "value": "lumbini",
                "label": "Lumbini"
            },
            {
                "value": "mahakali",
                "label": "Mahakali"
            },
            {
                "value": "mechi",
                "label": "Mechi"
            },
            {
                "value": "narayani",
                "label": "Narayani"
            },
            {
                "value": "rapti",
                "label": "Rapti"
            },
            {
                "value": "sagarmatha",
                "label": "Sagarmatha"
            },
            {
                "value": "seti",
                "label": "Seti"
            }
        ]
    },
    {
        "country": {
            "label": "Netherlands",
            "value": "netherlands"
        },
        "states": [
            {
                "value": "drenthe",
                "label": "Drenthe"
            },
            {
                "value": "flevoland",
                "label": "Flevoland"
            },
            {
                "value": "friesland",
                "label": "Friesland"
            },
            {
                "value": "gelderland",
                "label": "Gelderland"
            },
            {
                "value": "groningen",
                "label": "Groningen"
            },
            {
                "value": "limburg",
                "label": "Limburg"
            },
            {
                "value": "noord-brabant",
                "label": "Noord-Brabant"
            },
            {
                "value": "noord-holland",
                "label": "Noord-Holland"
            },
            {
                "value": "overijssel",
                "label": "Overijssel"
            },
            {
                "value": "utrecht",
                "label": "Utrecht"
            },
            {
                "value": "zeeland",
                "label": "Zeeland"
            },
            {
                "value": "zuid-holland",
                "label": "Zuid-Holland"
            }
        ]
    },
    {
        "country": {
            "label": "New Zealand",
            "value": "new zealand"
        },
        "states": [
            {
                "value": "auckland",
                "label": "Auckland"
            },
            {
                "value": "bay of plenty",
                "label": "Bay of Plenty"
            },
            {
                "value": "canterbury",
                "label": "Canterbury"
            },
            {
                "value": "chatham islands",
                "label": "Chatham Islands"
            },
            {
                "value": "gisborne",
                "label": "Gisborne"
            },
            {
                "value": "hawke's bay",
                "label": "Hawke's Bay"
            },
            {
                "value": "manawatu-wanganui",
                "label": "Manawatu-Wanganui"
            },
            {
                "value": "marlborough",
                "label": "Marlborough"
            },
            {
                "value": "nelson",
                "label": "Nelson"
            },
            {
                "value": "northland",
                "label": "Northland"
            },
            {
                "value": "otago",
                "label": "Otago"
            },
            {
                "value": "southland",
                "label": "Southland"
            },
            {
                "value": "taranaki",
                "label": "Taranaki"
            },
            {
                "value": "tasman",
                "label": "Tasman"
            },
            {
                "value": "waikato",
                "label": "Waikato"
            },
            {
                "value": "wellington",
                "label": "Wellington"
            },
            {
                "value": "west coast",
                "label": "West Coast"
            }
        ]
    },
    {
        "country": {
            "label": "Nicaragua",
            "value": "nicaragua"
        },
        "states": [
            {
                "value": "atlantico norte",
                "label": "Atlantico Norte"
            },
            {
                "value": "atlantico sur",
                "label": "Atlantico Sur"
            },
            {
                "value": "boaco",
                "label": "Boaco"
            },
            {
                "value": "carazo",
                "label": "Carazo"
            },
            {
                "value": "chinandega",
                "label": "Chinandega"
            },
            {
                "value": "chontales",
                "label": "Chontales"
            },
            {
                "value": "esteli",
                "label": "Esteli"
            },
            {
                "value": "granada",
                "label": "Granada"
            },
            {
                "value": "jinotega",
                "label": "Jinotega"
            },
            {
                "value": "leon",
                "label": "Leon"
            },
            {
                "value": "madriz",
                "label": "Madriz"
            },
            {
                "value": "managua",
                "label": "Managua"
            },
            {
                "value": "masaya",
                "label": "Masaya"
            },
            {
                "value": "matagalpa",
                "label": "Matagalpa"
            },
            {
                "value": "nueva segovia",
                "label": "Nueva Segovia"
            },
            {
                "value": "rio san juan",
                "label": "Rio San Juan"
            },
            {
                "value": "rivas",
                "label": "Rivas"
            }
        ]
    },
    {
        "country": {
            "label": "Niger",
            "value": "niger"
        },
        "states": [
            {
                "value": "agadez",
                "label": "Agadez"
            },
            {
                "value": "diffa",
                "label": "Diffa"
            },
            {
                "value": "dosso",
                "label": "Dosso"
            },
            {
                "value": "maradi",
                "label": "Maradi"
            },
            {
                "value": "niamey",
                "label": "Niamey"
            },
            {
                "value": "tahoua",
                "label": "Tahoua"
            },
            {
                "value": "tillaberi",
                "label": "Tillaberi"
            },
            {
                "value": "zinder",
                "label": "Zinder"
            }
        ]
    },
    {
        "country": {
            "label": "Nigeria",
            "value": "nigeria"
        },
        "states": [
            {
                "value": "abia",
                "label": "Abia"
            },
            {
                "value": "abuja federal capital",
                "label": "Abuja Federal Capital"
            },
            {
                "value": "adamawa",
                "label": "Adamawa"
            },
            {
                "value": "akwa ibom",
                "label": "Akwa Ibom"
            },
            {
                "value": "anambra",
                "label": "Anambra"
            },
            {
                "value": "bauchi",
                "label": "Bauchi"
            },
            {
                "value": "bayelsa",
                "label": "Bayelsa"
            },
            {
                "value": "benue",
                "label": "Benue"
            },
            {
                "value": "borno",
                "label": "Borno"
            },
            {
                "value": "cross river",
                "label": "Cross River"
            },
            {
                "value": "delta",
                "label": "Delta"
            },
            {
                "value": "ebonyi",
                "label": "Ebonyi"
            },
            {
                "value": "edo",
                "label": "Edo"
            },
            {
                "value": "ekiti",
                "label": "Ekiti"
            },
            {
                "value": "enugu",
                "label": "Enugu"
            },
            {
                "value": "gombe",
                "label": "Gombe"
            },
            {
                "value": "imo",
                "label": "Imo"
            },
            {
                "value": "jigawa",
                "label": "Jigawa"
            },
            {
                "value": "kaduna",
                "label": "Kaduna"
            },
            {
                "value": "kano",
                "label": "Kano"
            },
            {
                "value": "katsina",
                "label": "Katsina"
            },
            {
                "value": "kebbi",
                "label": "Kebbi"
            },
            {
                "value": "kogi",
                "label": "Kogi"
            },
            {
                "value": "kwara",
                "label": "Kwara"
            },
            {
                "value": "lagos",
                "label": "Lagos"
            },
            {
                "value": "nassarawa",
                "label": "Nassarawa"
            },
            {
                "value": "niger",
                "label": "Niger"
            },
            {
                "value": "ogun",
                "label": "Ogun"
            },
            {
                "value": "ondo",
                "label": "Ondo"
            },
            {
                "value": "osun",
                "label": "Osun"
            },
            {
                "value": "oyo",
                "label": "Oyo"
            },
            {
                "value": "plateau",
                "label": "Plateau"
            },
            {
                "value": "rivers",
                "label": "Rivers"
            },
            {
                "value": "sokoto",
                "label": "Sokoto"
            },
            {
                "value": "taraba",
                "label": "Taraba"
            },
            {
                "value": "yobe",
                "label": "Yobe"
            },
            {
                "value": "zamfara",
                "label": "Zamfara"
            }
        ]
    },
    {
        "country": {
            "label": "Norway",
            "value": "norway"
        },
        "states": [
            {
                "value": "akershus",
                "label": "Akershus"
            },
            {
                "value": "aust-agder",
                "label": "Aust-Agder"
            },
            {
                "value": "buskerud",
                "label": "Buskerud"
            },
            {
                "value": "finnmark",
                "label": "Finnmark"
            },
            {
                "value": "hedmark",
                "label": "Hedmark"
            },
            {
                "value": "hordaland",
                "label": "Hordaland"
            },
            {
                "value": "more og romsdal",
                "label": "More og Romsdal"
            },
            {
                "value": "nordland",
                "label": "Nordland"
            },
            {
                "value": "nord-trondelag",
                "label": "Nord-Trondelag"
            },
            {
                "value": "oppland",
                "label": "Oppland"
            },
            {
                "value": "oslo",
                "label": "Oslo"
            },
            {
                "value": "ostfold",
                "label": "Ostfold"
            },
            {
                "value": "rogaland",
                "label": "Rogaland"
            },
            {
                "value": "sogn og fjordane",
                "label": "Sogn og Fjordane"
            },
            {
                "value": "sor-trondelag",
                "label": "Sor-Trondelag"
            },
            {
                "value": "telemark",
                "label": "Telemark"
            },
            {
                "value": "troms",
                "label": "Troms"
            },
            {
                "value": "vest-agder",
                "label": "Vest-Agder"
            },
            {
                "value": "vestfold",
                "label": "Vestfold"
            }
        ]
    },
    {
        "country": {
            "label": "Oman",
            "value": "oman"
        },
        "states": [
            {
                "value": "ad dakhiliyah",
                "label": "Ad Dakhiliyah"
            },
            {
                "value": "al batinah",
                "label": "Al Batinah"
            },
            {
                "value": "al wusta",
                "label": "Al Wusta"
            },
            {
                "value": "ash sharqiyah",
                "label": "Ash Sharqiyah"
            },
            {
                "value": "az zahirah",
                "label": "Az Zahirah"
            },
            {
                "value": "masqat",
                "label": "Masqat"
            },
            {
                "value": "musandam",
                "label": "Musandam"
            },
            {
                "value": "dhofar",
                "label": "Dhofar"
            }
        ]
    },
    {
        "country": {
            "label": "Pakistan",
            "value": "pakistan"
        },
        "states": [
            {
                "value": "balochistan",
                "label": "Balochistan"
            },
            {
                "value": "north-west frontier province",
                "label": "North-West Frontier Province"
            },
            {
                "value": "punjab",
                "label": "Punjab"
            },
            {
                "value": "sindh",
                "label": "Sindh"
            },
            {
                "value": "islamabad capital territory",
                "label": "Islamabad Capital Territory"
            },
            {
                "value": "federally administered tribal areas",
                "label": "Federally Administered Tribal Areas"
            }
        ]
    },
    {
        "country": {
            "label": "Panama",
            "value": "panama"
        },
        "states": [
            {
                "value": "bocas del toro",
                "label": "Bocas del Toro"
            },
            {
                "value": "chiriqui",
                "label": "Chiriqui"
            },
            {
                "value": "cocle",
                "label": "Cocle"
            },
            {
                "value": "colon",
                "label": "Colon"
            },
            {
                "value": "darien",
                "label": "Darien"
            },
            {
                "value": "herrera",
                "label": "Herrera"
            },
            {
                "value": "los santos",
                "label": "Los Santos"
            },
            {
                "value": "panama",
                "label": "Panama"
            },
            {
                "value": "san blas",
                "label": "San Blas"
            },
            {
                "value": "veraguas",
                "label": "Veraguas"
            }
        ]
    },
    {
        "country": {
            "label": "Papua New Guinea",
            "value": "papua new guinea"
        },
        "states": [
            {
                "value": "bougainville",
                "label": "Bougainville"
            },
            {
                "value": "central",
                "label": "Central"
            },
            {
                "value": "chimbu",
                "label": "Chimbu"
            },
            {
                "value": "eastern highlands",
                "label": "Eastern Highlands"
            },
            {
                "value": "east new britain",
                "label": "East New Britain"
            },
            {
                "value": "east sepik",
                "label": "East Sepik"
            },
            {
                "value": "enga",
                "label": "Enga"
            },
            {
                "value": "gulf",
                "label": "Gulf"
            },
            {
                "value": "madang",
                "label": "Madang"
            },
            {
                "value": "manus",
                "label": "Manus"
            },
            {
                "value": "milne bay",
                "label": "Milne Bay"
            },
            {
                "value": "morobe",
                "label": "Morobe"
            },
            {
                "value": "national capital",
                "label": "National Capital"
            },
            {
                "value": "new ireland",
                "label": "New Ireland"
            },
            {
                "value": "northern",
                "label": "Northern"
            },
            {
                "value": "sandaun",
                "label": "Sandaun"
            },
            {
                "value": "southern highlands",
                "label": "Southern Highlands"
            },
            {
                "value": "western",
                "label": "Western"
            },
            {
                "value": "western highlands",
                "label": "Western Highlands"
            },
            {
                "value": "west new britain",
                "label": "West New Britain"
            }
        ]
    },
    {
        "country": {
            "label": "Paraguay",
            "value": "paraguay"
        },
        "states": [
            {
                "value": "alto paraguay",
                "label": "Alto Paraguay"
            },
            {
                "value": "alto parana",
                "label": "Alto Parana"
            },
            {
                "value": "amambay",
                "label": "Amambay"
            },
            {
                "value": "asuncion",
                "label": "Asuncion"
            },
            {
                "value": "boqueron",
                "label": "Boqueron"
            },
            {
                "value": "caaguazu",
                "label": "Caaguazu"
            },
            {
                "value": "caazapa",
                "label": "Caazapa"
            },
            {
                "value": "canindeyu",
                "label": "Canindeyu"
            },
            {
                "value": "central",
                "label": "Central"
            },
            {
                "value": "concepcion",
                "label": "Concepcion"
            },
            {
                "value": "cordillera",
                "label": "Cordillera"
            },
            {
                "value": "guaira",
                "label": "Guaira"
            },
            {
                "value": "itapua",
                "label": "Itapua"
            },
            {
                "value": "misiones",
                "label": "Misiones"
            },
            {
                "value": "neembucu",
                "label": "Neembucu"
            },
            {
                "value": "paraguari",
                "label": "Paraguari"
            },
            {
                "value": "presidente hayes",
                "label": "Presidente Hayes"
            },
            {
                "value": "san pedro",
                "label": "San Pedro"
            }
        ]
    },
    {
        "country": {
            "label": "Peru",
            "value": "peru"
        },
        "states": [
            {
                "value": "amazonas",
                "label": "Amazonas"
            },
            {
                "value": "ancash",
                "label": "Ancash"
            },
            {
                "value": "apurimac",
                "label": "Apurimac"
            },
            {
                "value": "arequipa",
                "label": "Arequipa"
            },
            {
                "value": "ayacucho",
                "label": "Ayacucho"
            },
            {
                "value": "cajamarca",
                "label": "Cajamarca"
            },
            {
                "value": "callao",
                "label": "Callao"
            },
            {
                "value": "cusco",
                "label": "Cusco"
            },
            {
                "value": "huancavelica",
                "label": "Huancavelica"
            },
            {
                "value": "huanuco",
                "label": "Huanuco"
            },
            {
                "value": "ica",
                "label": "Ica"
            },
            {
                "value": "junin",
                "label": "Junin"
            },
            {
                "value": "la libertad",
                "label": "La Libertad"
            },
            {
                "value": "lambayeque",
                "label": "Lambayeque"
            },
            {
                "value": "lima",
                "label": "Lima"
            },
            {
                "value": "loreto",
                "label": "Loreto"
            },
            {
                "value": "madre de dios",
                "label": "Madre de Dios"
            },
            {
                "value": "moquegua",
                "label": "Moquegua"
            },
            {
                "value": "pasco",
                "label": "Pasco"
            },
            {
                "value": "piura",
                "label": "Piura"
            },
            {
                "value": "puno",
                "label": "Puno"
            },
            {
                "value": "san martin",
                "label": "San Martin"
            },
            {
                "value": "tacna",
                "label": "Tacna"
            },
            {
                "value": "tumbes",
                "label": "Tumbes"
            },
            {
                "value": "ucayali",
                "label": "Ucayali"
            }
        ]
    },
    {
        "country": {
            "label": "Philippines",
            "value": "philippines"
        },
        "states": [
            {
                "value": "abra",
                "label": "Abra"
            },
            {
                "value": "agusan del norte",
                "label": "Agusan del Norte"
            },
            {
                "value": "agusan del sur",
                "label": "Agusan del Sur"
            },
            {
                "value": "aklan",
                "label": "Aklan"
            },
            {
                "value": "albay",
                "label": "Albay"
            },
            {
                "value": "antique",
                "label": "Antique"
            },
            {
                "value": "apayao",
                "label": "Apayao"
            },
            {
                "value": "aurora",
                "label": "Aurora"
            },
            {
                "value": "basilan",
                "label": "Basilan"
            },
            {
                "value": "bataan",
                "label": "Bataan"
            },
            {
                "value": "batanes",
                "label": "Batanes"
            },
            {
                "value": "batangas",
                "label": "Batangas"
            },
            {
                "value": "biliran",
                "label": "Biliran"
            },
            {
                "value": "benguet",
                "label": "Benguet"
            },
            {
                "value": "bohol",
                "label": "Bohol"
            },
            {
                "value": "bukidnon",
                "label": "Bukidnon"
            },
            {
                "value": "bulacan",
                "label": "Bulacan"
            },
            {
                "value": "cagayan",
                "label": "Cagayan"
            },
            {
                "value": "camarines norte",
                "label": "Camarines Norte"
            },
            {
                "value": "camarines sur",
                "label": "Camarines Sur"
            },
            {
                "value": "camiguin",
                "label": "Camiguin"
            },
            {
                "value": "capiz",
                "label": "Capiz"
            },
            {
                "value": "catanduanes",
                "label": "Catanduanes"
            },
            {
                "value": "cavite",
                "label": "Cavite"
            },
            {
                "value": "cebu",
                "label": "Cebu"
            },
            {
                "value": "compostela",
                "label": "Compostela"
            },
            {
                "value": "davao del norte",
                "label": "Davao del Norte"
            },
            {
                "value": "davao del sur",
                "label": "Davao del Sur"
            },
            {
                "value": "davao oriental",
                "label": "Davao Oriental"
            },
            {
                "value": "eastern samar",
                "label": "Eastern Samar"
            },
            {
                "value": "guimaras",
                "label": "Guimaras"
            },
            {
                "value": "ifugao",
                "label": "Ifugao"
            },
            {
                "value": "ilocos norte",
                "label": "Ilocos Norte"
            },
            {
                "value": "ilocos sur",
                "label": "Ilocos Sur"
            },
            {
                "value": "iloilo",
                "label": "Iloilo"
            },
            {
                "value": "isabela",
                "label": "Isabela"
            },
            {
                "value": "kalinga",
                "label": "Kalinga"
            },
            {
                "value": "laguna",
                "label": "Laguna"
            },
            {
                "value": "lanao del norte",
                "label": "Lanao del Norte"
            },
            {
                "value": "lanao del sur",
                "label": "Lanao del Sur"
            },
            {
                "value": "la union",
                "label": "La Union"
            },
            {
                "value": "leyte",
                "label": "Leyte"
            },
            {
                "value": "maguindanao",
                "label": "Maguindanao"
            },
            {
                "value": "marinduque",
                "label": "Marinduque"
            },
            {
                "value": "masbate",
                "label": "Masbate"
            },
            {
                "value": "mindoro occidental",
                "label": "Mindoro Occidental"
            },
            {
                "value": "mindoro oriental",
                "label": "Mindoro Oriental"
            },
            {
                "value": "misamis occidental",
                "label": "Misamis Occidental"
            },
            {
                "value": "misamis oriental",
                "label": "Misamis Oriental"
            },
            {
                "value": "mountain province",
                "label": "Mountain Province"
            },
            {
                "value": "negros occidental",
                "label": "Negros Occidental"
            },
            {
                "value": "negros oriental",
                "label": "Negros Oriental"
            },
            {
                "value": "north cotabato",
                "label": "North Cotabato"
            },
            {
                "value": "northern samar",
                "label": "Northern Samar"
            },
            {
                "value": "nueva ecija",
                "label": "Nueva Ecija"
            },
            {
                "value": "nueva vizcaya",
                "label": "Nueva Vizcaya"
            },
            {
                "value": "palawan",
                "label": "Palawan"
            },
            {
                "value": "pampanga",
                "label": "Pampanga"
            },
            {
                "value": "pangasinan",
                "label": "Pangasinan"
            },
            {
                "value": "quezon",
                "label": "Quezon"
            },
            {
                "value": "quirino",
                "label": "Quirino"
            },
            {
                "value": "rizal",
                "label": "Rizal"
            },
            {
                "value": "romblon",
                "label": "Romblon"
            },
            {
                "value": "samar",
                "label": "Samar"
            },
            {
                "value": "sarangani",
                "label": "Sarangani"
            },
            {
                "value": "siquijor",
                "label": "Siquijor"
            },
            {
                "value": "sorsogon",
                "label": "Sorsogon"
            },
            {
                "value": "south cotabato",
                "label": "South Cotabato"
            },
            {
                "value": "southern leyte",
                "label": "Southern Leyte"
            },
            {
                "value": "sultan kudarat",
                "label": "Sultan Kudarat"
            },
            {
                "value": "sulu",
                "label": "Sulu"
            },
            {
                "value": "surigao del norte",
                "label": "Surigao del Norte"
            },
            {
                "value": "surigao del sur",
                "label": "Surigao del Sur"
            },
            {
                "value": "tarlac",
                "label": "Tarlac"
            },
            {
                "value": "tawi-tawi",
                "label": "Tawi-Tawi"
            },
            {
                "value": "zambales",
                "label": "Zambales"
            },
            {
                "value": "zamboanga del norte",
                "label": "Zamboanga del Norte"
            },
            {
                "value": "zamboanga del sur",
                "label": "Zamboanga del Sur"
            },
            {
                "value": "zamboanga sibugay",
                "label": "Zamboanga Sibugay"
            }
        ]
    },
    {
        "country": {
            "label": "Poland",
            "value": "poland"
        },
        "states": [
            {
                "value": "greater poland (wielkopolskie)",
                "label": "Greater Poland (Wielkopolskie)"
            },
            {
                "value": "kuyavian-pomeranian (kujawsko-pomorskie)",
                "label": "Kuyavian-Pomeranian (Kujawsko-Pomorskie)"
            },
            {
                "value": "lesser poland (malopolskie)",
                "label": "Lesser Poland (Malopolskie)"
            },
            {
                "value": "lodz (lodzkie)",
                "label": "Lodz (Lodzkie)"
            },
            {
                "value": "lower silesian (dolnoslaskie)",
                "label": "Lower Silesian (Dolnoslaskie)"
            },
            {
                "value": "lublin (lubelskie)",
                "label": "Lublin (Lubelskie)"
            },
            {
                "value": "lubusz (lubuskie)",
                "label": "Lubusz (Lubuskie)"
            },
            {
                "value": "masovian (mazowieckie)",
                "label": "Masovian (Mazowieckie)"
            },
            {
                "value": "opole (opolskie)",
                "label": "Opole (Opolskie)"
            },
            {
                "value": "podlasie (podlaskie)",
                "label": "Podlasie (Podlaskie)"
            },
            {
                "value": "pomeranian (pomorskie)",
                "label": "Pomeranian (Pomorskie)"
            },
            {
                "value": "silesian (slaskie)",
                "label": "Silesian (Slaskie)"
            },
            {
                "value": "subcarpathian (podkarpackie)",
                "label": "Subcarpathian (Podkarpackie)"
            },
            {
                "value": "swietokrzyskie (swietokrzyskie)",
                "label": "Swietokrzyskie (Swietokrzyskie)"
            },
            {
                "value": "warmian-masurian (warminsko-mazurskie)",
                "label": "Warmian-Masurian (Warminsko-Mazurskie)"
            },
            {
                "value": "west pomeranian (zachodniopomorskie)",
                "label": "West Pomeranian (Zachodniopomorskie)"
            }
        ]
    },
    {
        "country": {
            "label": "Portugal",
            "value": "portugal"
        },
        "states": [
            {
                "value": "aveiro",
                "label": "Aveiro"
            },
            {
                "value": "acores",
                "label": "Acores"
            },
            {
                "value": "beja",
                "label": "Beja"
            },
            {
                "value": "braga",
                "label": "Braga"
            },
            {
                "value": "braganca",
                "label": "Braganca"
            },
            {
                "value": "castelo branco",
                "label": "Castelo Branco"
            },
            {
                "value": "coimbra",
                "label": "Coimbra"
            },
            {
                "value": "evora",
                "label": "Evora"
            },
            {
                "value": "faro",
                "label": "Faro"
            },
            {
                "value": "guarda",
                "label": "Guarda"
            },
            {
                "value": "leiria",
                "label": "Leiria"
            },
            {
                "value": "lisboa",
                "label": "Lisboa"
            },
            {
                "value": "madeira",
                "label": "Madeira"
            },
            {
                "value": "portalegre",
                "label": "Portalegre"
            },
            {
                "value": "porto",
                "label": "Porto"
            },
            {
                "value": "santarem",
                "label": "Santarem"
            },
            {
                "value": "setubal",
                "label": "Setubal"
            },
            {
                "value": "viana do castelo",
                "label": "Viana do Castelo"
            },
            {
                "value": "vila real",
                "label": "Vila Real"
            },
            {
                "value": "viseu",
                "label": "Viseu"
            }
        ]
    },
    {
        "country": {
            "label": "Qatar",
            "value": "qatar"
        },
        "states": [
            {
                "value": "ad dawhah",
                "label": "Ad Dawhah"
            },
            {
                "value": "al ghuwayriyah",
                "label": "Al Ghuwayriyah"
            },
            {
                "value": "al jumayliyah",
                "label": "Al Jumayliyah"
            },
            {
                "value": "al khawr",
                "label": "Al Khawr"
            },
            {
                "value": "al wakrah",
                "label": "Al Wakrah"
            },
            {
                "value": "ar rayyan",
                "label": "Ar Rayyan"
            },
            {
                "value": "jarayan al batinah",
                "label": "Jarayan al Batinah"
            },
            {
                "value": "madinat ash shamal",
                "label": "Madinat ash Shamal"
            },
            {
                "value": "umm sa'id",
                "label": "Umm Sa'id"
            },
            {
                "value": "umm salal",
                "label": "Umm Salal"
            }
        ]
    },
    {
        "country": {
            "label": "Romania",
            "value": "romania"
        },
        "states": [
            {
                "value": "alba",
                "label": "Alba"
            },
            {
                "value": "arad",
                "label": "Arad"
            },
            {
                "value": "arges",
                "label": "Arges"
            },
            {
                "value": "bacau",
                "label": "Bacau"
            },
            {
                "value": "bihor",
                "label": "Bihor"
            },
            {
                "value": "bistrita-nasaud",
                "label": "Bistrita-Nasaud"
            },
            {
                "value": "botosani",
                "label": "Botosani"
            },
            {
                "value": "braila",
                "label": "Braila"
            },
            {
                "value": "brasov",
                "label": "Brasov"
            },
            {
                "value": "bucuresti",
                "label": "Bucuresti"
            },
            {
                "value": "buzau",
                "label": "Buzau"
            },
            {
                "value": "calarasi",
                "label": "Calarasi"
            },
            {
                "value": "caras-severin",
                "label": "Caras-Severin"
            },
            {
                "value": "cluj",
                "label": "Cluj"
            },
            {
                "value": "constanta",
                "label": "Constanta"
            },
            {
                "value": "covasna",
                "label": "Covasna"
            },
            {
                "value": "dimbovita",
                "label": "Dimbovita"
            },
            {
                "value": "dolj",
                "label": "Dolj"
            },
            {
                "value": "galati",
                "label": "Galati"
            },
            {
                "value": "gorj",
                "label": "Gorj"
            },
            {
                "value": "giurgiu",
                "label": "Giurgiu"
            },
            {
                "value": "harghita",
                "label": "Harghita"
            },
            {
                "value": "hunedoara",
                "label": "Hunedoara"
            },
            {
                "value": "ialomita",
                "label": "Ialomita"
            },
            {
                "value": "iasi",
                "label": "Iasi"
            },
            {
                "value": "ilfov",
                "label": "Ilfov"
            },
            {
                "value": "maramures",
                "label": "Maramures"
            },
            {
                "value": "mehedinti",
                "label": "Mehedinti"
            },
            {
                "value": "mures",
                "label": "Mures"
            },
            {
                "value": "neamt",
                "label": "Neamt"
            },
            {
                "value": "olt",
                "label": "Olt"
            },
            {
                "value": "prahova",
                "label": "Prahova"
            },
            {
                "value": "salaj",
                "label": "Salaj"
            },
            {
                "value": "satu mare",
                "label": "Satu Mare"
            },
            {
                "value": "sibiu",
                "label": "Sibiu"
            },
            {
                "value": "suceava",
                "label": "Suceava"
            },
            {
                "value": "teleorman",
                "label": "Teleorman"
            },
            {
                "value": "timis",
                "label": "Timis"
            },
            {
                "value": "tulcea",
                "label": "Tulcea"
            },
            {
                "value": "vaslui",
                "label": "Vaslui"
            },
            {
                "value": "vilcea",
                "label": "Vilcea"
            },
            {
                "value": "vrancea",
                "label": "Vrancea"
            }
        ]
    },
    {
        "country": {
            "label": "Russia",
            "value": "russia"
        },
        "states": [
            {
                "value": "amur",
                "label": "Amur"
            },
            {
                "value": "arkhangel'sk",
                "label": "Arkhangel'sk"
            },
            {
                "value": "astrakhan'",
                "label": "Astrakhan'"
            },
            {
                "value": "belgorod",
                "label": "Belgorod"
            },
            {
                "value": "bryansk",
                "label": "Bryansk"
            },
            {
                "value": "chelyabinsk",
                "label": "Chelyabinsk"
            },
            {
                "value": "chita",
                "label": "Chita"
            },
            {
                "value": "irkutsk",
                "label": "Irkutsk"
            },
            {
                "value": "ivanovo",
                "label": "Ivanovo"
            },
            {
                "value": "kaliningrad",
                "label": "Kaliningrad"
            },
            {
                "value": "kaluga",
                "label": "Kaluga"
            },
            {
                "value": "kamchatka",
                "label": "Kamchatka"
            },
            {
                "value": "kemerovo",
                "label": "Kemerovo"
            },
            {
                "value": "kirov",
                "label": "Kirov"
            },
            {
                "value": "kostroma",
                "label": "Kostroma"
            },
            {
                "value": "kurgan",
                "label": "Kurgan"
            },
            {
                "value": "kursk",
                "label": "Kursk"
            },
            {
                "value": "leningrad",
                "label": "Leningrad"
            },
            {
                "value": "lipetsk",
                "label": "Lipetsk"
            },
            {
                "value": "magadan",
                "label": "Magadan"
            },
            {
                "value": "moscow",
                "label": "Moscow"
            },
            {
                "value": "murmansk",
                "label": "Murmansk"
            },
            {
                "value": "nizhniy novgorod",
                "label": "Nizhniy Novgorod"
            },
            {
                "value": "novgorod",
                "label": "Novgorod"
            },
            {
                "value": "novosibirsk",
                "label": "Novosibirsk"
            },
            {
                "value": "omsk",
                "label": "Omsk"
            },
            {
                "value": "orenburg",
                "label": "Orenburg"
            },
            {
                "value": "orel",
                "label": "Orel"
            },
            {
                "value": "penza",
                "label": "Penza"
            },
            {
                "value": "perm'",
                "label": "Perm'"
            },
            {
                "value": "pskov",
                "label": "Pskov"
            },
            {
                "value": "rostov",
                "label": "Rostov"
            },
            {
                "value": "ryazan'",
                "label": "Ryazan'"
            },
            {
                "value": "sakhalin",
                "label": "Sakhalin"
            },
            {
                "value": "samara",
                "label": "Samara"
            },
            {
                "value": "saratov",
                "label": "Saratov"
            },
            {
                "value": "smolensk",
                "label": "Smolensk"
            },
            {
                "value": "sverdlovsk",
                "label": "Sverdlovsk"
            },
            {
                "value": "tambov",
                "label": "Tambov"
            },
            {
                "value": "tomsk",
                "label": "Tomsk"
            },
            {
                "value": "tula",
                "label": "Tula"
            },
            {
                "value": "tver'",
                "label": "Tver'"
            },
            {
                "value": "tyumen'",
                "label": "Tyumen'"
            },
            {
                "value": "ul'yanovsk",
                "label": "Ul'yanovsk"
            },
            {
                "value": "vladimir",
                "label": "Vladimir"
            },
            {
                "value": "volgograd",
                "label": "Volgograd"
            },
            {
                "value": "vologda",
                "label": "Vologda"
            },
            {
                "value": "voronezh",
                "label": "Voronezh"
            },
            {
                "value": "yaroslavl'",
                "label": "Yaroslavl'"
            },
            {
                "value": "adygeya",
                "label": "Adygeya"
            },
            {
                "value": "altay",
                "label": "Altay"
            },
            {
                "value": "bashkortostan",
                "label": "Bashkortostan"
            },
            {
                "value": "buryatiya",
                "label": "Buryatiya"
            },
            {
                "value": "chechnya",
                "label": "Chechnya"
            },
            {
                "value": "chuvashiya",
                "label": "Chuvashiya"
            },
            {
                "value": "dagestan",
                "label": "Dagestan"
            },
            {
                "value": "ingushetiya",
                "label": "Ingushetiya"
            },
            {
                "value": "kabardino-balkariya",
                "label": "Kabardino-Balkariya"
            },
            {
                "value": "kalmykiya",
                "label": "Kalmykiya"
            },
            {
                "value": "karachayevo-cherkesiya",
                "label": "Karachayevo-Cherkesiya"
            },
            {
                "value": "kareliya",
                "label": "Kareliya"
            },
            {
                "value": "khakasiya",
                "label": "Khakasiya"
            },
            {
                "value": "komi",
                "label": "Komi"
            },
            {
                "value": "mariy-el",
                "label": "Mariy-El"
            },
            {
                "value": "mordoviya",
                "label": "Mordoviya"
            },
            {
                "value": "sakha",
                "label": "Sakha"
            },
            {
                "value": "north ossetia",
                "label": "North Ossetia"
            },
            {
                "value": "tatarstan",
                "label": "Tatarstan"
            },
            {
                "value": "tyva",
                "label": "Tyva"
            },
            {
                "value": "udmurtiya",
                "label": "Udmurtiya"
            },
            {
                "value": "aga buryat",
                "label": "Aga Buryat"
            },
            {
                "value": "chukotka",
                "label": "Chukotka"
            },
            {
                "value": "evenk",
                "label": "Evenk"
            },
            {
                "value": "khanty-mansi",
                "label": "Khanty-Mansi"
            },
            {
                "value": "komi-permyak",
                "label": "Komi-Permyak"
            },
            {
                "value": "koryak",
                "label": "Koryak"
            },
            {
                "value": "nenets",
                "label": "Nenets"
            },
            {
                "value": "taymyr",
                "label": "Taymyr"
            },
            {
                "value": "ust'-orda buryat",
                "label": "Ust'-Orda Buryat"
            },
            {
                "value": "yamalo-nenets",
                "label": "Yamalo-Nenets"
            },
            {
                "value": "altay",
                "label": "Altay"
            },
            {
                "value": "khabarovsk",
                "label": "Khabarovsk"
            },
            {
                "value": "krasnodar",
                "label": "Krasnodar"
            },
            {
                "value": "krasnoyarsk",
                "label": "Krasnoyarsk"
            },
            {
                "value": "primorskiy",
                "label": "Primorskiy"
            },
            {
                "value": "stavropol'",
                "label": "Stavropol'"
            },
            {
                "value": "moscow",
                "label": "Moscow"
            },
            {
                "value": "st. petersburg",
                "label": "St. Petersburg"
            },
            {
                "value": "yevrey",
                "label": "Yevrey"
            }
        ]
    },
    {
        "country": {
            "label": "Rwanda",
            "value": "rwanda"
        },
        "states": [
            {
                "value": "butare",
                "label": "Butare"
            },
            {
                "value": "byumba",
                "label": "Byumba"
            },
            {
                "value": "cyangugu",
                "label": "Cyangugu"
            },
            {
                "value": "gikongoro",
                "label": "Gikongoro"
            },
            {
                "value": "gisenyi",
                "label": "Gisenyi"
            },
            {
                "value": "gitarama",
                "label": "Gitarama"
            },
            {
                "value": "kibungo",
                "label": "Kibungo"
            },
            {
                "value": "kibuye",
                "label": "Kibuye"
            },
            {
                "value": "kigali rurale",
                "label": "Kigali Rurale"
            },
            {
                "value": "kigali-ville",
                "label": "Kigali-ville"
            },
            {
                "value": "umutara",
                "label": "Umutara"
            },
            {
                "value": "ruhengeri",
                "label": "Ruhengeri"
            }
        ]
    },
    {
        "country": {
            "label": "Samoa",
            "value": "samoa"
        },
        "states": [
            {
                "value": "a'ana",
                "label": "A'ana"
            },
            {
                "value": "aiga-i-le-tai",
                "label": "Aiga-i-le-Tai"
            },
            {
                "value": "atua",
                "label": "Atua"
            },
            {
                "value": "fa'asaleleaga",
                "label": "Fa'asaleleaga"
            },
            {
                "value": "gaga'emauga",
                "label": "Gaga'emauga"
            },
            {
                "value": "gagaifomauga",
                "label": "Gagaifomauga"
            },
            {
                "value": "palauli",
                "label": "Palauli"
            },
            {
                "value": "satupa'itea",
                "label": "Satupa'itea"
            },
            {
                "value": "tuamasaga",
                "label": "Tuamasaga"
            },
            {
                "value": "va'a-o-fonoti",
                "label": "Va'a-o-Fonoti"
            },
            {
                "value": "vaisigano",
                "label": "Vaisigano"
            }
        ]
    },
    {
        "country": {
            "label": "San Marino",
            "value": "san marino"
        },
        "states": [
            {
                "value": "acquaviva",
                "label": "Acquaviva"
            },
            {
                "value": "borgo maggiore",
                "label": "Borgo Maggiore"
            },
            {
                "value": "chiesanuova",
                "label": "Chiesanuova"
            },
            {
                "value": "domagnano",
                "label": "Domagnano"
            },
            {
                "value": "faetano",
                "label": "Faetano"
            },
            {
                "value": "fiorentino",
                "label": "Fiorentino"
            },
            {
                "value": "montegiardino",
                "label": "Montegiardino"
            },
            {
                "value": "san marino citta",
                "label": "San Marino Citta"
            },
            {
                "value": "serravalle",
                "label": "Serravalle"
            }
        ]
    },
    {
        "country": {
            "label": "Sao Tome",
            "value": "sao tome"
        },
        "states": []
    },
    {
        "country": {
            "label": "Saudi Arabia",
            "value": "saudi arabia"
        },
        "states": [
            {
                "value": "al bahah",
                "label": "Al Bahah"
            },
            {
                "value": "al hudud ash shamaliyah",
                "label": "Al Hudud ash Shamaliyah"
            },
            {
                "value": "al jawf",
                "label": "Al Jawf"
            },
            {
                "value": "al madinah",
                "label": "Al Madinah"
            },
            {
                "value": "al qasim",
                "label": "Al Qasim"
            },
            {
                "value": "ar riyad",
                "label": "Ar Riyad"
            },
            {
                "value": "ash sharqiyah",
                "label": "Ash Sharqiyah"
            },
            {
                "value": "'asir",
                "label": "'Asir"
            },
            {
                "value": "ha'il",
                "label": "Ha'il"
            },
            {
                "value": "jizan",
                "label": "Jizan"
            },
            {
                "value": "makkah",
                "label": "Makkah"
            },
            {
                "value": "najran",
                "label": "Najran"
            },
            {
                "value": "tabuk",
                "label": "Tabuk"
            }
        ]
    },
    {
        "country": {
            "label": "Senegal",
            "value": "senegal"
        },
        "states": [
            {
                "value": "dakar",
                "label": "Dakar"
            },
            {
                "value": "diourbel",
                "label": "Diourbel"
            },
            {
                "value": "fatick",
                "label": "Fatick"
            },
            {
                "value": "kaolack",
                "label": "Kaolack"
            },
            {
                "value": "kolda",
                "label": "Kolda"
            },
            {
                "value": "louga",
                "label": "Louga"
            },
            {
                "value": "matam",
                "label": "Matam"
            },
            {
                "value": "saint-louis",
                "label": "Saint-Louis"
            },
            {
                "value": "tambacounda",
                "label": "Tambacounda"
            },
            {
                "value": "thies",
                "label": "Thies"
            },
            {
                "value": "ziguinchor",
                "label": "Ziguinchor"
            }
        ]
    },
    {
        "country": {
            "label": "Serbia and Montenegro",
            "value": "serbia and montenegro"
        },
        "states": [
            {
                "value": "kosovo",
                "label": "Kosovo"
            },
            {
                "value": "montenegro",
                "label": "Montenegro"
            },
            {
                "value": "serbia",
                "label": "Serbia"
            },
            {
                "value": "vojvodina",
                "label": "Vojvodina"
            }
        ]
    },
    {
        "country": {
            "label": "Seychelles",
            "value": "seychelles"
        },
        "states": [
            {
                "value": "anse aux pins",
                "label": "Anse aux Pins"
            },
            {
                "value": "anse boileau",
                "label": "Anse Boileau"
            },
            {
                "value": "anse etoile",
                "label": "Anse Etoile"
            },
            {
                "value": "anse louis",
                "label": "Anse Louis"
            },
            {
                "value": "anse royale",
                "label": "Anse Royale"
            },
            {
                "value": "baie lazare",
                "label": "Baie Lazare"
            },
            {
                "value": "baie sainte anne",
                "label": "Baie Sainte Anne"
            },
            {
                "value": "beau vallon",
                "label": "Beau Vallon"
            },
            {
                "value": "bel air",
                "label": "Bel Air"
            },
            {
                "value": "bel ombre",
                "label": "Bel Ombre"
            },
            {
                "value": "cascade",
                "label": "Cascade"
            },
            {
                "value": "glacis",
                "label": "Glacis"
            },
            {
                "value": "grand' anse",
                "label": "Grand' Anse"
            },
            {
                "value": "grand' anse",
                "label": "Grand' Anse"
            },
            {
                "value": "la digue",
                "label": "La Digue"
            },
            {
                "value": "la riviere anglaise",
                "label": "La Riviere Anglaise"
            },
            {
                "value": "mont buxton",
                "label": "Mont Buxton"
            },
            {
                "value": "mont fleuri",
                "label": "Mont Fleuri"
            },
            {
                "value": "plaisance",
                "label": "Plaisance"
            },
            {
                "value": "pointe la rue",
                "label": "Pointe La Rue"
            },
            {
                "value": "port glaud",
                "label": "Port Glaud"
            },
            {
                "value": "saint louis",
                "label": "Saint Louis"
            },
            {
                "value": "takamaka",
                "label": "Takamaka"
            }
        ]
    },
    {
        "country": {
            "label": "Sierra Leone",
            "value": "sierra leone"
        },
        "states": []
    },
    {
        "country": {
            "label": "Singapore",
            "value": "singapore"
        },
        "states": []
    },
    {
        "country": {
            "label": "Slovakia",
            "value": "slovakia"
        },
        "states": [
            {
                "value": "banskobystricky",
                "label": "Banskobystricky"
            },
            {
                "value": "bratislavsky",
                "label": "Bratislavsky"
            },
            {
                "value": "kosicky",
                "label": "Kosicky"
            },
            {
                "value": "nitriansky",
                "label": "Nitriansky"
            },
            {
                "value": "presovsky",
                "label": "Presovsky"
            },
            {
                "value": "trenciansky",
                "label": "Trenciansky"
            },
            {
                "value": "trnavsky",
                "label": "Trnavsky"
            },
            {
                "value": "zilinsky",
                "label": "Zilinsky"
            }
        ]
    },
    {
        "country": {
            "label": "Slovenia",
            "value": "slovenia"
        },
        "states": [
            {
                "value": "ajdovscina",
                "label": "Ajdovscina"
            },
            {
                "value": "beltinci",
                "label": "Beltinci"
            },
            {
                "value": "benedikt",
                "label": "Benedikt"
            },
            {
                "value": "bistrica ob sotli",
                "label": "Bistrica ob Sotli"
            },
            {
                "value": "bled",
                "label": "Bled"
            },
            {
                "value": "bloke",
                "label": "Bloke"
            },
            {
                "value": "bohinj",
                "label": "Bohinj"
            },
            {
                "value": "borovnica",
                "label": "Borovnica"
            },
            {
                "value": "bovec",
                "label": "Bovec"
            },
            {
                "value": "braslovce",
                "label": "Braslovce"
            },
            {
                "value": "brda",
                "label": "Brda"
            },
            {
                "value": "brezice",
                "label": "Brezice"
            },
            {
                "value": "brezovica",
                "label": "Brezovica"
            },
            {
                "value": "cankova",
                "label": "Cankova"
            },
            {
                "value": "celje",
                "label": "Celje"
            },
            {
                "value": "cerklje na gorenjskem",
                "label": "Cerklje na Gorenjskem"
            },
            {
                "value": "cerknica",
                "label": "Cerknica"
            },
            {
                "value": "cerkno",
                "label": "Cerkno"
            },
            {
                "value": "cerkvenjak",
                "label": "Cerkvenjak"
            },
            {
                "value": "crensovci",
                "label": "Crensovci"
            },
            {
                "value": "crna na koroskem",
                "label": "Crna na Koroskem"
            },
            {
                "value": "crnomelj",
                "label": "Crnomelj"
            },
            {
                "value": "destrnik",
                "label": "Destrnik"
            },
            {
                "value": "divaca",
                "label": "Divaca"
            },
            {
                "value": "dobje",
                "label": "Dobje"
            },
            {
                "value": "dobrepolje",
                "label": "Dobrepolje"
            },
            {
                "value": "dobrna",
                "label": "Dobrna"
            },
            {
                "value": "dobrova-horjul-polhov gradec",
                "label": "Dobrova-Horjul-Polhov Gradec"
            },
            {
                "value": "dobrovnik-dobronak",
                "label": "Dobrovnik-Dobronak"
            },
            {
                "value": "dolenjske toplice",
                "label": "Dolenjske Toplice"
            },
            {
                "value": "dol pri ljubljani",
                "label": "Dol pri Ljubljani"
            },
            {
                "value": "domzale",
                "label": "Domzale"
            },
            {
                "value": "dornava",
                "label": "Dornava"
            },
            {
                "value": "dravograd",
                "label": "Dravograd"
            },
            {
                "value": "duplek",
                "label": "Duplek"
            },
            {
                "value": "gorenja vas-poljane",
                "label": "Gorenja Vas-Poljane"
            },
            {
                "value": "gorisnica",
                "label": "Gorisnica"
            },
            {
                "value": "gornja radgona",
                "label": "Gornja Radgona"
            },
            {
                "value": "gornji grad",
                "label": "Gornji Grad"
            },
            {
                "value": "gornji petrovci",
                "label": "Gornji Petrovci"
            },
            {
                "value": "grad",
                "label": "Grad"
            },
            {
                "value": "grosuplje",
                "label": "Grosuplje"
            },
            {
                "value": "hajdina",
                "label": "Hajdina"
            },
            {
                "value": "hoce-slivnica",
                "label": "Hoce-Slivnica"
            },
            {
                "value": "hodos-hodos",
                "label": "Hodos-Hodos"
            },
            {
                "value": "horjul",
                "label": "Horjul"
            },
            {
                "value": "hrastnik",
                "label": "Hrastnik"
            },
            {
                "value": "hrpelje-kozina",
                "label": "Hrpelje-Kozina"
            },
            {
                "value": "idrija",
                "label": "Idrija"
            },
            {
                "value": "ig",
                "label": "Ig"
            },
            {
                "value": "ilirska bistrica",
                "label": "Ilirska Bistrica"
            },
            {
                "value": "ivancna gorica",
                "label": "Ivancna Gorica"
            },
            {
                "value": "izola-isola",
                "label": "Izola-Isola"
            },
            {
                "value": "jesenice",
                "label": "Jesenice"
            },
            {
                "value": "jezersko",
                "label": "Jezersko"
            },
            {
                "value": "jursinci",
                "label": "Jursinci"
            },
            {
                "value": "kamnik",
                "label": "Kamnik"
            },
            {
                "value": "kanal",
                "label": "Kanal"
            },
            {
                "value": "kidricevo",
                "label": "Kidricevo"
            },
            {
                "value": "kobarid",
                "label": "Kobarid"
            },
            {
                "value": "kobilje",
                "label": "Kobilje"
            },
            {
                "value": "kocevje",
                "label": "Kocevje"
            },
            {
                "value": "komen",
                "label": "Komen"
            },
            {
                "value": "komenda",
                "label": "Komenda"
            },
            {
                "value": "koper-capodistria",
                "label": "Koper-Capodistria"
            },
            {
                "value": "kostel",
                "label": "Kostel"
            },
            {
                "value": "kozje",
                "label": "Kozje"
            },
            {
                "value": "kranj",
                "label": "Kranj"
            },
            {
                "value": "kranjska gora",
                "label": "Kranjska Gora"
            },
            {
                "value": "krizevci",
                "label": "Krizevci"
            },
            {
                "value": "krsko",
                "label": "Krsko"
            },
            {
                "value": "kungota",
                "label": "Kungota"
            },
            {
                "value": "kuzma",
                "label": "Kuzma"
            },
            {
                "value": "lasko",
                "label": "Lasko"
            },
            {
                "value": "lenart",
                "label": "Lenart"
            },
            {
                "value": "lendava-lendva",
                "label": "Lendava-Lendva"
            },
            {
                "value": "litija",
                "label": "Litija"
            },
            {
                "value": "ljubljana",
                "label": "Ljubljana"
            },
            {
                "value": "ljubno",
                "label": "Ljubno"
            },
            {
                "value": "ljutomer",
                "label": "Ljutomer"
            },
            {
                "value": "logatec",
                "label": "Logatec"
            },
            {
                "value": "loska dolina",
                "label": "Loska Dolina"
            },
            {
                "value": "loski potok",
                "label": "Loski Potok"
            },
            {
                "value": "lovrenc na pohorju",
                "label": "Lovrenc na Pohorju"
            },
            {
                "value": "luce",
                "label": "Luce"
            },
            {
                "value": "lukovica",
                "label": "Lukovica"
            },
            {
                "value": "majsperk",
                "label": "Majsperk"
            },
            {
                "value": "maribor",
                "label": "Maribor"
            },
            {
                "value": "markovci",
                "label": "Markovci"
            },
            {
                "value": "medvode",
                "label": "Medvode"
            },
            {
                "value": "menges",
                "label": "Menges"
            },
            {
                "value": "metlika",
                "label": "Metlika"
            },
            {
                "value": "mezica",
                "label": "Mezica"
            },
            {
                "value": "miklavz na dravskem polju",
                "label": "Miklavz na Dravskem Polju"
            },
            {
                "value": "miren-kostanjevica",
                "label": "Miren-Kostanjevica"
            },
            {
                "value": "mirna pec",
                "label": "Mirna Pec"
            },
            {
                "value": "mislinja",
                "label": "Mislinja"
            },
            {
                "value": "moravce",
                "label": "Moravce"
            },
            {
                "value": "moravske toplice",
                "label": "Moravske Toplice"
            },
            {
                "value": "mozirje",
                "label": "Mozirje"
            },
            {
                "value": "murska sobota",
                "label": "Murska Sobota"
            },
            {
                "value": "muta",
                "label": "Muta"
            },
            {
                "value": "naklo",
                "label": "Naklo"
            },
            {
                "value": "nazarje",
                "label": "Nazarje"
            },
            {
                "value": "nova gorica",
                "label": "Nova Gorica"
            },
            {
                "value": "novo mesto",
                "label": "Novo Mesto"
            },
            {
                "value": "odranci",
                "label": "Odranci"
            },
            {
                "value": "oplotnica",
                "label": "Oplotnica"
            },
            {
                "value": "ormoz",
                "label": "Ormoz"
            },
            {
                "value": "osilnica",
                "label": "Osilnica"
            },
            {
                "value": "pesnica",
                "label": "Pesnica"
            },
            {
                "value": "piran-pirano",
                "label": "Piran-Pirano"
            },
            {
                "value": "pivka",
                "label": "Pivka"
            },
            {
                "value": "podcetrtek",
                "label": "Podcetrtek"
            },
            {
                "value": "podlehnik",
                "label": "Podlehnik"
            },
            {
                "value": "podvelka",
                "label": "Podvelka"
            },
            {
                "value": "polzela",
                "label": "Polzela"
            },
            {
                "value": "postojna",
                "label": "Postojna"
            },
            {
                "value": "prebold",
                "label": "Prebold"
            },
            {
                "value": "preddvor",
                "label": "Preddvor"
            },
            {
                "value": "prevalje",
                "label": "Prevalje"
            },
            {
                "value": "ptuj",
                "label": "Ptuj"
            },
            {
                "value": "puconci",
                "label": "Puconci"
            },
            {
                "value": "race-fram",
                "label": "Race-Fram"
            },
            {
                "value": "radece",
                "label": "Radece"
            },
            {
                "value": "radenci",
                "label": "Radenci"
            },
            {
                "value": "radlje ob dravi",
                "label": "Radlje ob Dravi"
            },
            {
                "value": "radovljica",
                "label": "Radovljica"
            },
            {
                "value": "ravne na koroskem",
                "label": "Ravne na Koroskem"
            },
            {
                "value": "razkrizje",
                "label": "Razkrizje"
            },
            {
                "value": "ribnica",
                "label": "Ribnica"
            },
            {
                "value": "ribnica na pohorju",
                "label": "Ribnica na Pohorju"
            },
            {
                "value": "rogasovci",
                "label": "Rogasovci"
            },
            {
                "value": "rogaska slatina",
                "label": "Rogaska Slatina"
            },
            {
                "value": "rogatec",
                "label": "Rogatec"
            },
            {
                "value": "ruse",
                "label": "Ruse"
            },
            {
                "value": "salovci",
                "label": "Salovci"
            },
            {
                "value": "selnica ob dravi",
                "label": "Selnica ob Dravi"
            },
            {
                "value": "semic",
                "label": "Semic"
            },
            {
                "value": "sempeter-vrtojba",
                "label": "Sempeter-Vrtojba"
            },
            {
                "value": "sencur",
                "label": "Sencur"
            },
            {
                "value": "sentilj",
                "label": "Sentilj"
            },
            {
                "value": "sentjernej",
                "label": "Sentjernej"
            },
            {
                "value": "sentjur pri celju",
                "label": "Sentjur pri Celju"
            },
            {
                "value": "sevnica",
                "label": "Sevnica"
            },
            {
                "value": "sezana",
                "label": "Sezana"
            },
            {
                "value": "skocjan",
                "label": "Skocjan"
            },
            {
                "value": "skofja loka",
                "label": "Skofja Loka"
            },
            {
                "value": "skofljica",
                "label": "Skofljica"
            },
            {
                "value": "slovenj gradec",
                "label": "Slovenj Gradec"
            },
            {
                "value": "slovenska bistrica",
                "label": "Slovenska Bistrica"
            },
            {
                "value": "slovenske konjice",
                "label": "Slovenske Konjice"
            },
            {
                "value": "smarje pri jelsah",
                "label": "Smarje pri Jelsah"
            },
            {
                "value": "smartno ob paki",
                "label": "Smartno ob Paki"
            },
            {
                "value": "smartno pri litiji",
                "label": "Smartno pri Litiji"
            },
            {
                "value": "sodrazica",
                "label": "Sodrazica"
            },
            {
                "value": "solcava",
                "label": "Solcava"
            },
            {
                "value": "sostanj",
                "label": "Sostanj"
            },
            {
                "value": "starse",
                "label": "Starse"
            },
            {
                "value": "store",
                "label": "Store"
            },
            {
                "value": "sveta ana",
                "label": "Sveta Ana"
            },
            {
                "value": "sveti andraz v slovenskih goricah",
                "label": "Sveti Andraz v Slovenskih Goricah"
            },
            {
                "value": "sveti jurij",
                "label": "Sveti Jurij"
            },
            {
                "value": "tabor",
                "label": "Tabor"
            },
            {
                "value": "tisina",
                "label": "Tisina"
            },
            {
                "value": "tolmin",
                "label": "Tolmin"
            },
            {
                "value": "trbovlje",
                "label": "Trbovlje"
            },
            {
                "value": "trebnje",
                "label": "Trebnje"
            },
            {
                "value": "trnovska vas",
                "label": "Trnovska Vas"
            },
            {
                "value": "trzic",
                "label": "Trzic"
            },
            {
                "value": "trzin",
                "label": "Trzin"
            },
            {
                "value": "turnisce",
                "label": "Turnisce"
            },
            {
                "value": "velenje",
                "label": "Velenje"
            },
            {
                "value": "velika polana",
                "label": "Velika Polana"
            },
            {
                "value": "velike lasce",
                "label": "Velike Lasce"
            },
            {
                "value": "verzej",
                "label": "Verzej"
            },
            {
                "value": "videm",
                "label": "Videm"
            },
            {
                "value": "vipava",
                "label": "Vipava"
            },
            {
                "value": "vitanje",
                "label": "Vitanje"
            },
            {
                "value": "vodice",
                "label": "Vodice"
            },
            {
                "value": "vojnik",
                "label": "Vojnik"
            },
            {
                "value": "vransko",
                "label": "Vransko"
            },
            {
                "value": "vrhnika",
                "label": "Vrhnika"
            },
            {
                "value": "vuzenica",
                "label": "Vuzenica"
            },
            {
                "value": "zagorje ob savi",
                "label": "Zagorje ob Savi"
            },
            {
                "value": "zalec",
                "label": "Zalec"
            },
            {
                "value": "zavrc",
                "label": "Zavrc"
            },
            {
                "value": "zelezniki",
                "label": "Zelezniki"
            },
            {
                "value": "zetale",
                "label": "Zetale"
            },
            {
                "value": "ziri",
                "label": "Ziri"
            },
            {
                "value": "zirovnica",
                "label": "Zirovnica"
            },
            {
                "value": "zuzemberk",
                "label": "Zuzemberk"
            },
            {
                "value": "zrece",
                "label": "Zrece"
            }
        ]
    },
    {
        "country": {
            "label": "Solomon Islands",
            "value": "solomon islands"
        },
        "states": [
            {
                "value": "central",
                "label": "Central"
            },
            {
                "value": "choiseul",
                "label": "Choiseul"
            },
            {
                "value": "guadalcanal",
                "label": "Guadalcanal"
            },
            {
                "value": "honiara",
                "label": "Honiara"
            },
            {
                "value": "isabel",
                "label": "Isabel"
            },
            {
                "value": "makira",
                "label": "Makira"
            },
            {
                "value": "malaita",
                "label": "Malaita"
            },
            {
                "value": "rennell and bellona",
                "label": "Rennell and Bellona"
            },
            {
                "value": "temotu",
                "label": "Temotu"
            },
            {
                "value": "western",
                "label": "Western"
            }
        ]
    },
    {
        "country": {
            "label": "Somalia",
            "value": "somalia"
        },
        "states": [
            {
                "value": "awdal",
                "label": "Awdal"
            },
            {
                "value": "bakool",
                "label": "Bakool"
            },
            {
                "value": "banaadir",
                "label": "Banaadir"
            },
            {
                "value": "bari",
                "label": "Bari"
            },
            {
                "value": "bay",
                "label": "Bay"
            },
            {
                "value": "galguduud",
                "label": "Galguduud"
            },
            {
                "value": "gedo",
                "label": "Gedo"
            },
            {
                "value": "hiiraan",
                "label": "Hiiraan"
            },
            {
                "value": "jubbada dhexe",
                "label": "Jubbada Dhexe"
            },
            {
                "value": "jubbada hoose",
                "label": "Jubbada Hoose"
            },
            {
                "value": "mudug",
                "label": "Mudug"
            },
            {
                "value": "nugaal",
                "label": "Nugaal"
            },
            {
                "value": "sanaag",
                "label": "Sanaag"
            },
            {
                "value": "shabeellaha dhexe",
                "label": "Shabeellaha Dhexe"
            },
            {
                "value": "shabeellaha hoose",
                "label": "Shabeellaha Hoose"
            },
            {
                "value": "sool",
                "label": "Sool"
            },
            {
                "value": "togdheer",
                "label": "Togdheer"
            },
            {
                "value": "woqooyi galbeed",
                "label": "Woqooyi Galbeed"
            }
        ]
    },
    {
        "country": {
            "label": "South Africa",
            "value": "south africa"
        },
        "states": [
            {
                "value": "eastern cape",
                "label": "Eastern Cape"
            },
            {
                "value": "free state",
                "label": "Free State"
            },
            {
                "value": "gauteng",
                "label": "Gauteng"
            },
            {
                "value": "kwazulu-natal",
                "label": "KwaZulu-Natal"
            },
            {
                "value": "limpopo",
                "label": "Limpopo"
            },
            {
                "value": "mpumalanga",
                "label": "Mpumalanga"
            },
            {
                "value": "north-west",
                "label": "North-West"
            },
            {
                "value": "northern cape",
                "label": "Northern Cape"
            },
            {
                "value": "western cape",
                "label": "Western Cape"
            }
        ]
    },
    {
        "country": {
            "label": "Spain",
            "value": "spain"
        },
        "states": [
            {
                "value": "andalucia",
                "label": "Andalucia"
            },
            {
                "value": "aragon",
                "label": "Aragon"
            },
            {
                "value": "asturias",
                "label": "Asturias"
            },
            {
                "value": "baleares",
                "label": "Baleares"
            },
            {
                "value": "ceuta",
                "label": "Ceuta"
            },
            {
                "value": "canarias",
                "label": "Canarias"
            },
            {
                "value": "cantabria",
                "label": "Cantabria"
            },
            {
                "value": "castilla-la mancha",
                "label": "Castilla-La Mancha"
            },
            {
                "value": "castilla y leon",
                "label": "Castilla y Leon"
            },
            {
                "value": "cataluna",
                "label": "Cataluna"
            },
            {
                "value": "comunidad valenciana",
                "label": "Comunidad Valenciana"
            },
            {
                "value": "extremadura",
                "label": "Extremadura"
            },
            {
                "value": "galicia",
                "label": "Galicia"
            },
            {
                "value": "la rioja",
                "label": "La Rioja"
            },
            {
                "value": "madrid",
                "label": "Madrid"
            },
            {
                "value": "melilla",
                "label": "Melilla"
            },
            {
                "value": "murcia",
                "label": "Murcia"
            },
            {
                "value": "navarra",
                "label": "Navarra"
            },
            {
                "value": "pais vasco",
                "label": "Pais Vasco"
            }
        ]
    },
    {
        "country": {
            "label": "Sri Lanka",
            "value": "sri lanka"
        },
        "states": [
            {
                "value": "central",
                "label": "Central"
            },
            {
                "value": "north central",
                "label": "North Central"
            },
            {
                "value": "north eastern",
                "label": "North Eastern"
            },
            {
                "value": "north western",
                "label": "North Western"
            },
            {
                "value": "sabaragamuwa",
                "label": "Sabaragamuwa"
            },
            {
                "value": "southern",
                "label": "Southern"
            },
            {
                "value": "uva",
                "label": "Uva"
            },
            {
                "value": "western",
                "label": "Western"
            }
        ]
    },
    {
        "country": {
            "label": "Sudan",
            "value": "sudan"
        },
        "states": [
            {
                "value": "a'ali an nil",
                "label": "A'ali an Nil"
            },
            {
                "value": "al bahr al ahmar",
                "label": "Al Bahr al Ahmar"
            },
            {
                "value": "al buhayrat",
                "label": "Al Buhayrat"
            },
            {
                "value": "al jazirah",
                "label": "Al Jazirah"
            },
            {
                "value": "al khartum",
                "label": "Al Khartum"
            },
            {
                "value": "al qadarif",
                "label": "Al Qadarif"
            },
            {
                "value": "al wahdah",
                "label": "Al Wahdah"
            },
            {
                "value": "an nil al abyad",
                "label": "An Nil al Abyad"
            },
            {
                "value": "an nil al azraq",
                "label": "An Nil al Azraq"
            },
            {
                "value": "ash shamaliyah",
                "label": "Ash Shamaliyah"
            },
            {
                "value": "bahr al jabal",
                "label": "Bahr al Jabal"
            },
            {
                "value": "gharb al istiwa'iyah",
                "label": "Gharb al Istiwa'iyah"
            },
            {
                "value": "gharb bahr al ghazal",
                "label": "Gharb Bahr al Ghazal"
            },
            {
                "value": "gharb darfur",
                "label": "Gharb Darfur"
            },
            {
                "value": "gharb kurdufan",
                "label": "Gharb Kurdufan"
            },
            {
                "value": "janub darfur",
                "label": "Janub Darfur"
            },
            {
                "value": "janub kurdufan",
                "label": "Janub Kurdufan"
            },
            {
                "value": "junqali",
                "label": "Junqali"
            },
            {
                "value": "kassala",
                "label": "Kassala"
            },
            {
                "value": "nahr an nil",
                "label": "Nahr an Nil"
            },
            {
                "value": "shamal bahr al ghazal",
                "label": "Shamal Bahr al Ghazal"
            },
            {
                "value": "shamal darfur",
                "label": "Shamal Darfur"
            },
            {
                "value": "shamal kurdufan",
                "label": "Shamal Kurdufan"
            },
            {
                "value": "sharq al istiwa'iyah",
                "label": "Sharq al Istiwa'iyah"
            },
            {
                "value": "sinnar",
                "label": "Sinnar"
            },
            {
                "value": "warab",
                "label": "Warab"
            }
        ]
    },
    {
        "country": {
            "label": "Suriname",
            "value": "suriname"
        },
        "states": [
            {
                "value": "brokopondo",
                "label": "Brokopondo"
            },
            {
                "value": "commewijne",
                "label": "Commewijne"
            },
            {
                "value": "coronie",
                "label": "Coronie"
            },
            {
                "value": "marowijne",
                "label": "Marowijne"
            },
            {
                "value": "nickerie",
                "label": "Nickerie"
            },
            {
                "value": "para",
                "label": "Para"
            },
            {
                "value": "paramaribo",
                "label": "Paramaribo"
            },
            {
                "value": "saramacca",
                "label": "Saramacca"
            },
            {
                "value": "sipaliwini",
                "label": "Sipaliwini"
            },
            {
                "value": "wanica",
                "label": "Wanica"
            }
        ]
    },
    {
        "country": {
            "label": "Swaziland",
            "value": "swaziland"
        },
        "states": [
            {
                "value": "hhohho",
                "label": "Hhohho"
            },
            {
                "value": "lubombo",
                "label": "Lubombo"
            },
            {
                "value": "manzini",
                "label": "Manzini"
            },
            {
                "value": "shiselweni",
                "label": "Shiselweni"
            }
        ]
    },
    {
        "country": {
            "label": "Sweden",
            "value": "sweden"
        },
        "states": [
            {
                "value": "blekinge",
                "label": "Blekinge"
            },
            {
                "value": "dalarnas",
                "label": "Dalarnas"
            },
            {
                "value": "gavleborgs",
                "label": "Gavleborgs"
            },
            {
                "value": "gotlands",
                "label": "Gotlands"
            },
            {
                "value": "hallands",
                "label": "Hallands"
            },
            {
                "value": "jamtlands",
                "label": "Jamtlands"
            },
            {
                "value": "jonkopings",
                "label": "Jonkopings"
            },
            {
                "value": "kalmar",
                "label": "Kalmar"
            },
            {
                "value": "kronobergs",
                "label": "Kronobergs"
            },
            {
                "value": "norrbottens",
                "label": "Norrbottens"
            },
            {
                "value": "orebro",
                "label": "Orebro"
            },
            {
                "value": "ostergotlands",
                "label": "Ostergotlands"
            },
            {
                "value": "skane",
                "label": "Skane"
            },
            {
                "value": "sodermanlands",
                "label": "Sodermanlands"
            },
            {
                "value": "stockholms",
                "label": "Stockholms"
            },
            {
                "value": "uppsala",
                "label": "Uppsala"
            },
            {
                "value": "varmlands",
                "label": "Varmlands"
            },
            {
                "value": "vasterbottens",
                "label": "Vasterbottens"
            },
            {
                "value": "vasternorrlands",
                "label": "Vasternorrlands"
            },
            {
                "value": "vastmanlands",
                "label": "Vastmanlands"
            },
            {
                "value": "vastra gotalands",
                "label": "Vastra Gotalands"
            }
        ]
    },
    {
        "country": {
            "label": "Switzerland",
            "value": "switzerland"
        },
        "states": [
            {
                "value": "aargau",
                "label": "Aargau"
            },
            {
                "value": "appenzell ausser-rhoden",
                "label": "Appenzell Ausser-Rhoden"
            },
            {
                "value": "appenzell inner-rhoden",
                "label": "Appenzell Inner-Rhoden"
            },
            {
                "value": "basel-landschaft",
                "label": "Basel-Landschaft"
            },
            {
                "value": "basel-stadt",
                "label": "Basel-Stadt"
            },
            {
                "value": "bern",
                "label": "Bern"
            },
            {
                "value": "fribourg",
                "label": "Fribourg"
            },
            {
                "value": "geneve",
                "label": "Geneve"
            },
            {
                "value": "glarus",
                "label": "Glarus"
            },
            {
                "value": "graubunden",
                "label": "Graubunden"
            },
            {
                "value": "jura",
                "label": "Jura"
            },
            {
                "value": "luzern",
                "label": "Luzern"
            },
            {
                "value": "neuchatel",
                "label": "Neuchatel"
            },
            {
                "value": "nidwalden",
                "label": "Nidwalden"
            },
            {
                "value": "obwalden",
                "label": "Obwalden"
            },
            {
                "value": "sankt gallen",
                "label": "Sankt Gallen"
            },
            {
                "value": "schaffhausen",
                "label": "Schaffhausen"
            },
            {
                "value": "schwyz",
                "label": "Schwyz"
            },
            {
                "value": "solothurn",
                "label": "Solothurn"
            },
            {
                "value": "thurgau",
                "label": "Thurgau"
            },
            {
                "value": "ticino",
                "label": "Ticino"
            },
            {
                "value": "uri",
                "label": "Uri"
            },
            {
                "value": "valais",
                "label": "Valais"
            },
            {
                "value": "vaud",
                "label": "Vaud"
            },
            {
                "value": "zug",
                "label": "Zug"
            },
            {
                "value": "zurich",
                "label": "Zurich"
            }
        ]
    },
    {
        "country": {
            "label": "Syria",
            "value": "syria"
        },
        "states": [
            {
                "value": "al hasakah",
                "label": "Al Hasakah"
            },
            {
                "value": "al ladhiqiyah",
                "label": "Al Ladhiqiyah"
            },
            {
                "value": "al qunaytirah",
                "label": "Al Qunaytirah"
            },
            {
                "value": "ar raqqah",
                "label": "Ar Raqqah"
            },
            {
                "value": "as suwayda'",
                "label": "As Suwayda'"
            },
            {
                "value": "dar'a",
                "label": "Dar'a"
            },
            {
                "value": "dayr az zawr",
                "label": "Dayr az Zawr"
            },
            {
                "value": "dimashq",
                "label": "Dimashq"
            },
            {
                "value": "halab",
                "label": "Halab"
            },
            {
                "value": "hamah",
                "label": "Hamah"
            },
            {
                "value": "hims",
                "label": "Hims"
            },
            {
                "value": "idlib",
                "label": "Idlib"
            },
            {
                "value": "rif dimashq",
                "label": "Rif Dimashq"
            },
            {
                "value": "tartus",
                "label": "Tartus"
            }
        ]
    },
    {
        "country": {
            "label": "Taiwan",
            "value": "taiwan"
        },
        "states": [
            {
                "value": "chang-hua",
                "label": "Chang-hua"
            },
            {
                "value": "chia-i",
                "label": "Chia-i"
            },
            {
                "value": "hsin-chu",
                "label": "Hsin-chu"
            },
            {
                "value": "hua-lien",
                "label": "Hua-lien"
            },
            {
                "value": "i-lan",
                "label": "I-lan"
            },
            {
                "value": "kao-hsiung",
                "label": "Kao-hsiung"
            },
            {
                "value": "kin-men",
                "label": "Kin-men"
            },
            {
                "value": "lien-chiang",
                "label": "Lien-chiang"
            },
            {
                "value": "miao-li",
                "label": "Miao-li"
            },
            {
                "value": "nan-t'ou",
                "label": "Nan-t'ou"
            },
            {
                "value": "p'eng-hu",
                "label": "P'eng-hu"
            },
            {
                "value": "p'ing-tung",
                "label": "P'ing-tung"
            },
            {
                "value": "t'ai-chung",
                "label": "T'ai-chung"
            },
            {
                "value": "t'ai-nan",
                "label": "T'ai-nan"
            },
            {
                "value": "t'ai-pei",
                "label": "T'ai-pei"
            },
            {
                "value": "t'ai-tung",
                "label": "T'ai-tung"
            },
            {
                "value": "t'ao-yuan",
                "label": "T'ao-yuan"
            },
            {
                "value": "yun-lin",
                "label": "Yun-lin"
            },
            {
                "value": "chia-i",
                "label": "Chia-i"
            },
            {
                "value": "chi-lung",
                "label": "Chi-lung"
            },
            {
                "value": "hsin-chu",
                "label": "Hsin-chu"
            },
            {
                "value": "t'ai-chung",
                "label": "T'ai-chung"
            },
            {
                "value": "t'ai-nan",
                "label": "T'ai-nan"
            },
            {
                "value": "kao-hsiung city",
                "label": "Kao-hsiung city"
            },
            {
                "value": "t'ai-pei city",
                "label": "T'ai-pei city"
            }
        ]
    },
    {
        "country": {
            "label": "Tajikistan",
            "value": "tajikistan"
        },
        "states": []
    },
    {
        "country": {
            "label": "Tanzania",
            "value": "tanzania"
        },
        "states": [
            {
                "value": "arusha",
                "label": "Arusha"
            },
            {
                "value": "dar es salaam",
                "label": "Dar es Salaam"
            },
            {
                "value": "dodoma",
                "label": "Dodoma"
            },
            {
                "value": "iringa",
                "label": "Iringa"
            },
            {
                "value": "kagera",
                "label": "Kagera"
            },
            {
                "value": "kigoma",
                "label": "Kigoma"
            },
            {
                "value": "kilimanjaro",
                "label": "Kilimanjaro"
            },
            {
                "value": "lindi",
                "label": "Lindi"
            },
            {
                "value": "manyara",
                "label": "Manyara"
            },
            {
                "value": "mara",
                "label": "Mara"
            },
            {
                "value": "mbeya",
                "label": "Mbeya"
            },
            {
                "value": "morogoro",
                "label": "Morogoro"
            },
            {
                "value": "mtwara",
                "label": "Mtwara"
            },
            {
                "value": "mwanza",
                "label": "Mwanza"
            },
            {
                "value": "pemba north",
                "label": "Pemba North"
            },
            {
                "value": "pemba south",
                "label": "Pemba South"
            },
            {
                "value": "pwani",
                "label": "Pwani"
            },
            {
                "value": "rukwa",
                "label": "Rukwa"
            },
            {
                "value": "ruvuma",
                "label": "Ruvuma"
            },
            {
                "value": "shinyanga",
                "label": "Shinyanga"
            },
            {
                "value": "singida",
                "label": "Singida"
            },
            {
                "value": "tabora",
                "label": "Tabora"
            },
            {
                "value": "tanga",
                "label": "Tanga"
            },
            {
                "value": "zanzibar central/south",
                "label": "Zanzibar Central/South"
            },
            {
                "value": "zanzibar north",
                "label": "Zanzibar North"
            },
            {
                "value": "zanzibar urban/west",
                "label": "Zanzibar Urban/West"
            }
        ]
    },
    {
        "country": {
            "label": "Thailand",
            "value": "thailand"
        },
        "states": [
            {
                "value": "amnat charoen",
                "label": "Amnat Charoen"
            },
            {
                "value": "ang thong",
                "label": "Ang Thong"
            },
            {
                "value": "buriram",
                "label": "Buriram"
            },
            {
                "value": "chachoengsao",
                "label": "Chachoengsao"
            },
            {
                "value": "chai nat",
                "label": "Chai Nat"
            },
            {
                "value": "chaiyaphum",
                "label": "Chaiyaphum"
            },
            {
                "value": "chanthaburi",
                "label": "Chanthaburi"
            },
            {
                "value": "chiang mai",
                "label": "Chiang Mai"
            },
            {
                "value": "chiang rai",
                "label": "Chiang Rai"
            },
            {
                "value": "chon buri",
                "label": "Chon Buri"
            },
            {
                "value": "chumphon",
                "label": "Chumphon"
            },
            {
                "value": "kalasin",
                "label": "Kalasin"
            },
            {
                "value": "kamphaeng phet",
                "label": "Kamphaeng Phet"
            },
            {
                "value": "kanchanaburi",
                "label": "Kanchanaburi"
            },
            {
                "value": "khon kaen",
                "label": "Khon Kaen"
            },
            {
                "value": "krabi",
                "label": "Krabi"
            },
            {
                "value": "krung thep mahanakhon",
                "label": "Krung Thep Mahanakhon"
            },
            {
                "value": "lampang",
                "label": "Lampang"
            },
            {
                "value": "lamphun",
                "label": "Lamphun"
            },
            {
                "value": "loei",
                "label": "Loei"
            },
            {
                "value": "lop buri",
                "label": "Lop Buri"
            },
            {
                "value": "mae hong son",
                "label": "Mae Hong Son"
            },
            {
                "value": "maha sarakham",
                "label": "Maha Sarakham"
            },
            {
                "value": "mukdahan",
                "label": "Mukdahan"
            },
            {
                "value": "nakhon nayok",
                "label": "Nakhon Nayok"
            },
            {
                "value": "nakhon pathom",
                "label": "Nakhon Pathom"
            },
            {
                "value": "nakhon phanom",
                "label": "Nakhon Phanom"
            },
            {
                "value": "nakhon ratchasima",
                "label": "Nakhon Ratchasima"
            },
            {
                "value": "nakhon sawan",
                "label": "Nakhon Sawan"
            },
            {
                "value": "nakhon si thammarat",
                "label": "Nakhon Si Thammarat"
            },
            {
                "value": "nan",
                "label": "Nan"
            },
            {
                "value": "narathiwat",
                "label": "Narathiwat"
            },
            {
                "value": "nong bua lamphu",
                "label": "Nong Bua Lamphu"
            },
            {
                "value": "nong khai",
                "label": "Nong Khai"
            },
            {
                "value": "nonthaburi",
                "label": "Nonthaburi"
            },
            {
                "value": "pathum thani",
                "label": "Pathum Thani"
            },
            {
                "value": "pattani",
                "label": "Pattani"
            },
            {
                "value": "phangnga",
                "label": "Phangnga"
            },
            {
                "value": "phatthalung",
                "label": "Phatthalung"
            },
            {
                "value": "phayao",
                "label": "Phayao"
            },
            {
                "value": "phetchabun",
                "label": "Phetchabun"
            },
            {
                "value": "phetchaburi",
                "label": "Phetchaburi"
            },
            {
                "value": "phichit",
                "label": "Phichit"
            },
            {
                "value": "phitsanulok",
                "label": "Phitsanulok"
            },
            {
                "value": "phra nakhon si ayutthaya",
                "label": "Phra Nakhon Si Ayutthaya"
            },
            {
                "value": "phrae",
                "label": "Phrae"
            },
            {
                "value": "phuket",
                "label": "Phuket"
            },
            {
                "value": "prachin buri",
                "label": "Prachin Buri"
            },
            {
                "value": "prachuap khiri khan",
                "label": "Prachuap Khiri Khan"
            },
            {
                "value": "ranong",
                "label": "Ranong"
            },
            {
                "value": "ratchaburi",
                "label": "Ratchaburi"
            },
            {
                "value": "rayong",
                "label": "Rayong"
            },
            {
                "value": "roi et",
                "label": "Roi Et"
            },
            {
                "value": "sa kaeo",
                "label": "Sa Kaeo"
            },
            {
                "value": "sakon nakhon",
                "label": "Sakon Nakhon"
            },
            {
                "value": "samut prakan",
                "label": "Samut Prakan"
            },
            {
                "value": "samut sakhon",
                "label": "Samut Sakhon"
            },
            {
                "value": "samut songkhram",
                "label": "Samut Songkhram"
            },
            {
                "value": "sara buri",
                "label": "Sara Buri"
            },
            {
                "value": "satun",
                "label": "Satun"
            },
            {
                "value": "sing buri",
                "label": "Sing Buri"
            },
            {
                "value": "sisaket",
                "label": "Sisaket"
            },
            {
                "value": "songkhla",
                "label": "Songkhla"
            },
            {
                "value": "sukhothai",
                "label": "Sukhothai"
            },
            {
                "value": "suphan buri",
                "label": "Suphan Buri"
            },
            {
                "value": "surat thani",
                "label": "Surat Thani"
            },
            {
                "value": "surin",
                "label": "Surin"
            },
            {
                "value": "tak",
                "label": "Tak"
            },
            {
                "value": "trang",
                "label": "Trang"
            },
            {
                "value": "trat",
                "label": "Trat"
            },
            {
                "value": "ubon ratchathani",
                "label": "Ubon Ratchathani"
            },
            {
                "value": "udon thani",
                "label": "Udon Thani"
            },
            {
                "value": "uthai thani",
                "label": "Uthai Thani"
            },
            {
                "value": "uttaradit",
                "label": "Uttaradit"
            },
            {
                "value": "yala",
                "label": "Yala"
            },
            {
                "value": "yasothon",
                "label": "Yasothon"
            }
        ]
    },
    {
        "country": {
            "label": "Togo",
            "value": "togo"
        },
        "states": [
            {
                "value": "kara",
                "label": "Kara"
            },
            {
                "value": "plateaux",
                "label": "Plateaux"
            },
            {
                "value": "savanes",
                "label": "Savanes"
            },
            {
                "value": "centrale",
                "label": "Centrale"
            },
            {
                "value": "maritime",
                "label": "Maritime"
            }
        ]
    },
    {
        "country": {
            "label": "Tonga",
            "value": "tonga"
        },
        "states": []
    },
    {
        "country": {
            "label": "Trinidad and Tobago",
            "value": "trinidad and tobago"
        },
        "states": [
            {
                "value": "couva",
                "label": "Couva"
            },
            {
                "value": "diego martin",
                "label": "Diego Martin"
            },
            {
                "value": "mayaro",
                "label": "Mayaro"
            },
            {
                "value": "penal",
                "label": "Penal"
            },
            {
                "value": "princes town",
                "label": "Princes Town"
            },
            {
                "value": "sangre grande",
                "label": "Sangre Grande"
            },
            {
                "value": "san juan",
                "label": "San Juan"
            },
            {
                "value": "siparia",
                "label": "Siparia"
            },
            {
                "value": "tunapuna",
                "label": "Tunapuna"
            },
            {
                "value": "port-of-spain",
                "label": "Port-of-Spain"
            },
            {
                "value": "san fernando",
                "label": "San Fernando"
            },
            {
                "value": "arima",
                "label": "Arima"
            },
            {
                "value": "point fortin",
                "label": "Point Fortin"
            },
            {
                "value": "chaguanas",
                "label": "Chaguanas"
            },
            {
                "value": "tobago",
                "label": "Tobago"
            }
        ]
    },
    {
        "country": {
            "label": "Tunisia",
            "value": "tunisia"
        },
        "states": [
            {
                "value": "ariana (aryanah)",
                "label": "Ariana (Aryanah)"
            },
            {
                "value": "beja (bajah)",
                "label": "Beja (Bajah)"
            },
            {
                "value": "ben arous (bin 'arus)",
                "label": "Ben Arous (Bin 'Arus)"
            },
            {
                "value": "bizerte (banzart)",
                "label": "Bizerte (Banzart)"
            },
            {
                "value": "gabes (qabis)",
                "label": "Gabes (Qabis)"
            },
            {
                "value": "gafsa (qafsah)",
                "label": "Gafsa (Qafsah)"
            },
            {
                "value": "jendouba (jundubah)",
                "label": "Jendouba (Jundubah)"
            },
            {
                "value": "kairouan (al qayrawan)",
                "label": "Kairouan (Al Qayrawan)"
            },
            {
                "value": "kasserine (al qasrayn)",
                "label": "Kasserine (Al Qasrayn)"
            },
            {
                "value": "kebili (qibili)",
                "label": "Kebili (Qibili)"
            },
            {
                "value": "kef (al kaf)",
                "label": "Kef (Al Kaf)"
            },
            {
                "value": "mahdia (al mahdiyah)",
                "label": "Mahdia (Al Mahdiyah)"
            },
            {
                "value": "manouba (manubah)",
                "label": "Manouba (Manubah)"
            },
            {
                "value": "medenine (madanin)",
                "label": "Medenine (Madanin)"
            },
            {
                "value": "monastir (al munastir)",
                "label": "Monastir (Al Munastir)"
            },
            {
                "value": "nabeul (nabul)",
                "label": "Nabeul (Nabul)"
            },
            {
                "value": "sfax (safaqis)",
                "label": "Sfax (Safaqis)"
            },
            {
                "value": "sidi bou zid (sidi bu zayd)",
                "label": "Sidi Bou Zid (Sidi Bu Zayd)"
            },
            {
                "value": "siliana (silyanah)",
                "label": "Siliana (Silyanah)"
            },
            {
                "value": "sousse (susah)",
                "label": "Sousse (Susah)"
            },
            {
                "value": "tataouine (tatawin)",
                "label": "Tataouine (Tatawin)"
            },
            {
                "value": "tozeur (tawzar)",
                "label": "Tozeur (Tawzar)"
            },
            {
                "value": "tunis",
                "label": "Tunis"
            },
            {
                "value": "zaghouan (zaghwan)",
                "label": "Zaghouan (Zaghwan)"
            }
        ]
    },
    {
        "country": {
            "label": "Turkey",
            "value": "turkey"
        },
        "states": [
            {
                "value": "adana",
                "label": "Adana"
            },
            {
                "value": "adiyaman",
                "label": "Adiyaman"
            },
            {
                "value": "afyonkarahisar",
                "label": "Afyonkarahisar"
            },
            {
                "value": "agri",
                "label": "Agri"
            },
            {
                "value": "aksaray",
                "label": "Aksaray"
            },
            {
                "value": "amasya",
                "label": "Amasya"
            },
            {
                "value": "ankara",
                "label": "Ankara"
            },
            {
                "value": "antalya",
                "label": "Antalya"
            },
            {
                "value": "ardahan",
                "label": "Ardahan"
            },
            {
                "value": "artvin",
                "label": "Artvin"
            },
            {
                "value": "aydin",
                "label": "Aydin"
            },
            {
                "value": "balikesir",
                "label": "Balikesir"
            },
            {
                "value": "bartin",
                "label": "Bartin"
            },
            {
                "value": "batman",
                "label": "Batman"
            },
            {
                "value": "bayburt",
                "label": "Bayburt"
            },
            {
                "value": "bilecik",
                "label": "Bilecik"
            },
            {
                "value": "bingol",
                "label": "Bingol"
            },
            {
                "value": "bitlis",
                "label": "Bitlis"
            },
            {
                "value": "bolu",
                "label": "Bolu"
            },
            {
                "value": "burdur",
                "label": "Burdur"
            },
            {
                "value": "bursa",
                "label": "Bursa"
            },
            {
                "value": "canakkale",
                "label": "Canakkale"
            },
            {
                "value": "cankiri",
                "label": "Cankiri"
            },
            {
                "value": "corum",
                "label": "Corum"
            },
            {
                "value": "denizli",
                "label": "Denizli"
            },
            {
                "value": "diyarbakir",
                "label": "Diyarbakir"
            },
            {
                "value": "duzce",
                "label": "Duzce"
            },
            {
                "value": "edirne",
                "label": "Edirne"
            },
            {
                "value": "elazig",
                "label": "Elazig"
            },
            {
                "value": "erzincan",
                "label": "Erzincan"
            },
            {
                "value": "erzurum",
                "label": "Erzurum"
            },
            {
                "value": "eskisehir",
                "label": "Eskisehir"
            },
            {
                "value": "gaziantep",
                "label": "Gaziantep"
            },
            {
                "value": "giresun",
                "label": "Giresun"
            },
            {
                "value": "gumushane",
                "label": "Gumushane"
            },
            {
                "value": "hakkari",
                "label": "Hakkari"
            },
            {
                "value": "hatay",
                "label": "Hatay"
            },
            {
                "value": "igdir",
                "label": "Igdir"
            },
            {
                "value": "isparta",
                "label": "Isparta"
            },
            {
                "value": "istanbul",
                "label": "Istanbul"
            },
            {
                "value": "izmir",
                "label": "Izmir"
            },
            {
                "value": "kahramanmaras",
                "label": "Kahramanmaras"
            },
            {
                "value": "karabuk",
                "label": "Karabuk"
            },
            {
                "value": "karaman",
                "label": "Karaman"
            },
            {
                "value": "kars",
                "label": "Kars"
            },
            {
                "value": "kastamonu",
                "label": "Kastamonu"
            },
            {
                "value": "kayseri",
                "label": "Kayseri"
            },
            {
                "value": "kilis",
                "label": "Kilis"
            },
            {
                "value": "kirikkale",
                "label": "Kirikkale"
            },
            {
                "value": "kirklareli",
                "label": "Kirklareli"
            },
            {
                "value": "kirsehir",
                "label": "Kirsehir"
            },
            {
                "value": "kocaeli",
                "label": "Kocaeli"
            },
            {
                "value": "konya",
                "label": "Konya"
            },
            {
                "value": "kutahya",
                "label": "Kutahya"
            },
            {
                "value": "malatya",
                "label": "Malatya"
            },
            {
                "value": "manisa",
                "label": "Manisa"
            },
            {
                "value": "mardin",
                "label": "Mardin"
            },
            {
                "value": "mersin",
                "label": "Mersin"
            },
            {
                "value": "mugla",
                "label": "Mugla"
            },
            {
                "value": "mus",
                "label": "Mus"
            },
            {
                "value": "nevsehir",
                "label": "Nevsehir"
            },
            {
                "value": "nigde",
                "label": "Nigde"
            },
            {
                "value": "ordu",
                "label": "Ordu"
            },
            {
                "value": "osmaniye",
                "label": "Osmaniye"
            },
            {
                "value": "rize",
                "label": "Rize"
            },
            {
                "value": "sakarya",
                "label": "Sakarya"
            },
            {
                "value": "samsun",
                "label": "Samsun"
            },
            {
                "value": "sanliurfa",
                "label": "Sanliurfa"
            },
            {
                "value": "siirt",
                "label": "Siirt"
            },
            {
                "value": "sinop",
                "label": "Sinop"
            },
            {
                "value": "sirnak",
                "label": "Sirnak"
            },
            {
                "value": "sivas",
                "label": "Sivas"
            },
            {
                "value": "tekirdag",
                "label": "Tekirdag"
            },
            {
                "value": "tokat",
                "label": "Tokat"
            },
            {
                "value": "trabzon",
                "label": "Trabzon"
            },
            {
                "value": "tunceli",
                "label": "Tunceli"
            },
            {
                "value": "usak",
                "label": "Usak"
            },
            {
                "value": "van",
                "label": "Van"
            },
            {
                "value": "yalova",
                "label": "Yalova"
            },
            {
                "value": "yozgat",
                "label": "Yozgat"
            },
            {
                "value": "zonguldak",
                "label": "Zonguldak"
            }
        ]
    },
    {
        "country": {
            "label": "Turkmenistan",
            "value": "turkmenistan"
        },
        "states": [
            {
                "value": "ahal welayaty (ashgabat)",
                "label": "Ahal Welayaty (Ashgabat)"
            },
            {
                "value": "balkan welayaty (balkanabat)",
                "label": "Balkan Welayaty (Balkanabat)"
            },
            {
                "value": "dashoguz welayaty",
                "label": "Dashoguz Welayaty"
            },
            {
                "value": "lebap welayaty (turkmenabat)",
                "label": "Lebap Welayaty (Turkmenabat)"
            },
            {
                "value": "mary welayaty",
                "label": "Mary Welayaty"
            }
        ]
    },
    {
        "country": {
            "label": "Uganda",
            "value": "uganda"
        },
        "states": [
            {
                "value": "adjumani",
                "label": "Adjumani"
            },
            {
                "value": "apac",
                "label": "Apac"
            },
            {
                "value": "arua",
                "label": "Arua"
            },
            {
                "value": "bugiri",
                "label": "Bugiri"
            },
            {
                "value": "bundibugyo",
                "label": "Bundibugyo"
            },
            {
                "value": "bushenyi",
                "label": "Bushenyi"
            },
            {
                "value": "busia",
                "label": "Busia"
            },
            {
                "value": "gulu",
                "label": "Gulu"
            },
            {
                "value": "hoima",
                "label": "Hoima"
            },
            {
                "value": "iganga",
                "label": "Iganga"
            },
            {
                "value": "jinja",
                "label": "Jinja"
            },
            {
                "value": "kabale",
                "label": "Kabale"
            },
            {
                "value": "kabarole",
                "label": "Kabarole"
            },
            {
                "value": "kaberamaido",
                "label": "Kaberamaido"
            },
            {
                "value": "kalangala",
                "label": "Kalangala"
            },
            {
                "value": "kampala",
                "label": "Kampala"
            },
            {
                "value": "kamuli",
                "label": "Kamuli"
            },
            {
                "value": "kamwenge",
                "label": "Kamwenge"
            },
            {
                "value": "kanungu",
                "label": "Kanungu"
            },
            {
                "value": "kapchorwa",
                "label": "Kapchorwa"
            },
            {
                "value": "kasese",
                "label": "Kasese"
            },
            {
                "value": "katakwi",
                "label": "Katakwi"
            },
            {
                "value": "kayunga",
                "label": "Kayunga"
            },
            {
                "value": "kibale",
                "label": "Kibale"
            },
            {
                "value": "kiboga",
                "label": "Kiboga"
            },
            {
                "value": "kisoro",
                "label": "Kisoro"
            },
            {
                "value": "kitgum",
                "label": "Kitgum"
            },
            {
                "value": "kotido",
                "label": "Kotido"
            },
            {
                "value": "kumi",
                "label": "Kumi"
            },
            {
                "value": "kyenjojo",
                "label": "Kyenjojo"
            },
            {
                "value": "lira",
                "label": "Lira"
            },
            {
                "value": "luwero",
                "label": "Luwero"
            },
            {
                "value": "masaka",
                "label": "Masaka"
            },
            {
                "value": "masindi",
                "label": "Masindi"
            },
            {
                "value": "mayuge",
                "label": "Mayuge"
            },
            {
                "value": "mbale",
                "label": "Mbale"
            },
            {
                "value": "mbarara",
                "label": "Mbarara"
            },
            {
                "value": "moroto",
                "label": "Moroto"
            },
            {
                "value": "moyo",
                "label": "Moyo"
            },
            {
                "value": "mpigi",
                "label": "Mpigi"
            },
            {
                "value": "mubende",
                "label": "Mubende"
            },
            {
                "value": "mukono",
                "label": "Mukono"
            },
            {
                "value": "nakapiripirit",
                "label": "Nakapiripirit"
            },
            {
                "value": "nakasongola",
                "label": "Nakasongola"
            },
            {
                "value": "nebbi",
                "label": "Nebbi"
            },
            {
                "value": "ntungamo",
                "label": "Ntungamo"
            },
            {
                "value": "pader",
                "label": "Pader"
            },
            {
                "value": "pallisa",
                "label": "Pallisa"
            },
            {
                "value": "rakai",
                "label": "Rakai"
            },
            {
                "value": "rukungiri",
                "label": "Rukungiri"
            },
            {
                "value": "sembabule",
                "label": "Sembabule"
            },
            {
                "value": "sironko",
                "label": "Sironko"
            },
            {
                "value": "soroti",
                "label": "Soroti"
            },
            {
                "value": "tororo",
                "label": "Tororo"
            },
            {
                "value": "wakiso",
                "label": "Wakiso"
            },
            {
                "value": "yumbe",
                "label": "Yumbe"
            }
        ]
    },
    {
        "country": {
            "label": "Ukraine",
            "value": "ukraine"
        },
        "states": [
            {
                "value": "cherkasy",
                "label": "Cherkasy"
            },
            {
                "value": "chernihiv",
                "label": "Chernihiv"
            },
            {
                "value": "chernivtsi",
                "label": "Chernivtsi"
            },
            {
                "value": "crimea",
                "label": "Crimea"
            },
            {
                "value": "dnipropetrovs'k",
                "label": "Dnipropetrovs'k"
            },
            {
                "value": "donets'k",
                "label": "Donets'k"
            },
            {
                "value": "ivano-frankivs'k",
                "label": "Ivano-Frankivs'k"
            },
            {
                "value": "kharkiv",
                "label": "Kharkiv"
            },
            {
                "value": "kherson",
                "label": "Kherson"
            },
            {
                "value": "khmel'nyts'kyy",
                "label": "Khmel'nyts'kyy"
            },
            {
                "value": "kirovohrad",
                "label": "Kirovohrad"
            },
            {
                "value": "kiev",
                "label": "Kiev"
            },
            {
                "value": "kyyiv",
                "label": "Kyyiv"
            },
            {
                "value": "luhans'k",
                "label": "Luhans'k"
            },
            {
                "value": "l'viv",
                "label": "L'viv"
            },
            {
                "value": "mykolayiv",
                "label": "Mykolayiv"
            },
            {
                "value": "odesa",
                "label": "Odesa"
            },
            {
                "value": "poltava",
                "label": "Poltava"
            },
            {
                "value": "rivne",
                "label": "Rivne"
            },
            {
                "value": "sevastopol'",
                "label": "Sevastopol'"
            },
            {
                "value": "sumy",
                "label": "Sumy"
            },
            {
                "value": "ternopil'",
                "label": "Ternopil'"
            },
            {
                "value": "vinnytsya",
                "label": "Vinnytsya"
            },
            {
                "value": "volyn'",
                "label": "Volyn'"
            },
            {
                "value": "zakarpattya",
                "label": "Zakarpattya"
            },
            {
                "value": "zaporizhzhya",
                "label": "Zaporizhzhya"
            },
            {
                "value": "zhytomyr",
                "label": "Zhytomyr"
            }
        ]
    },
    {
        "country": {
            "label": "United Arab Emirates",
            "value": "united arab emirates"
        },
        "states": [
            {
                "value": "abu dhabi",
                "label": "Abu Dhabi"
            },
            {
                "value": "'ajman",
                "label": "'Ajman"
            },
            {
                "value": "al fujayrah",
                "label": "Al Fujayrah"
            },
            {
                "value": "sharjah",
                "label": "Sharjah"
            },
            {
                "value": "dubai",
                "label": "Dubai"
            },
            {
                "value": "ra's al khaymah",
                "label": "Ra's al Khaymah"
            },
            {
                "value": "umm al qaywayn",
                "label": "Umm al Qaywayn"
            }
        ]
    },
    {
        "country": {
            "label": "United Kingdom",
            "value": "united kingdom"
        },
        "states": []
    },
    {
        "country": {
            "label": "United States",
            "value": "united states"
        },
        "states": [
            {
                "value": "alabama",
                "label": "Alabama"
            },
            {
                "value": "alaska",
                "label": "Alaska"
            },
            {
                "value": "arizona",
                "label": "Arizona"
            },
            {
                "value": "arkansas",
                "label": "Arkansas"
            },
            {
                "value": "california",
                "label": "California"
            },
            {
                "value": "colorado",
                "label": "Colorado"
            },
            {
                "value": "connecticut",
                "label": "Connecticut"
            },
            {
                "value": "delaware",
                "label": "Delaware"
            },
            {
                "value": "district of columbia",
                "label": "District of Columbia"
            },
            {
                "value": "florida",
                "label": "Florida"
            },
            {
                "value": "georgia",
                "label": "Georgia"
            },
            {
                "value": "hawaii",
                "label": "Hawaii"
            },
            {
                "value": "idaho",
                "label": "Idaho"
            },
            {
                "value": "illinois",
                "label": "Illinois"
            },
            {
                "value": "indiana",
                "label": "Indiana"
            },
            {
                "value": "iowa",
                "label": "Iowa"
            },
            {
                "value": "kansas",
                "label": "Kansas"
            },
            {
                "value": "kentucky",
                "label": "Kentucky"
            },
            {
                "value": "louisiana",
                "label": "Louisiana"
            },
            {
                "value": "maine",
                "label": "Maine"
            },
            {
                "value": "maryland",
                "label": "Maryland"
            },
            {
                "value": "massachusetts",
                "label": "Massachusetts"
            },
            {
                "value": "michigan",
                "label": "Michigan"
            },
            {
                "value": "minnesota",
                "label": "Minnesota"
            },
            {
                "value": "mississippi",
                "label": "Mississippi"
            },
            {
                "value": "missouri",
                "label": "Missouri"
            },
            {
                "value": "montana",
                "label": "Montana"
            },
            {
                "value": "nebraska",
                "label": "Nebraska"
            },
            {
                "value": "nevada",
                "label": "Nevada"
            },
            {
                "value": "new hampshire",
                "label": "New Hampshire"
            },
            {
                "value": "new jersey",
                "label": "New Jersey"
            },
            {
                "value": "new mexico",
                "label": "New Mexico"
            },
            {
                "value": "new york",
                "label": "New York"
            },
            {
                "value": "north carolina",
                "label": "North Carolina"
            },
            {
                "value": "north dakota",
                "label": "North Dakota"
            },
            {
                "value": "ohio",
                "label": "Ohio"
            },
            {
                "value": "oklahoma",
                "label": "Oklahoma"
            },
            {
                "value": "oregon",
                "label": "Oregon"
            },
            {
                "value": "pennsylvania",
                "label": "Pennsylvania"
            },
            {
                "value": "rhode island",
                "label": "Rhode Island"
            },
            {
                "value": "south carolina",
                "label": "South Carolina"
            },
            {
                "value": "south dakota",
                "label": "South Dakota"
            },
            {
                "value": "tennessee",
                "label": "Tennessee"
            },
            {
                "value": "texas",
                "label": "Texas"
            },
            {
                "value": "utah",
                "label": "Utah"
            },
            {
                "value": "vermont",
                "label": "Vermont"
            },
            {
                "value": "virginia",
                "label": "Virginia"
            },
            {
                "value": "washington",
                "label": "Washington"
            },
            {
                "value": "west virginia",
                "label": "West Virginia"
            },
            {
                "value": "wisconsin",
                "label": "Wisconsin"
            },
            {
                "value": "wyoming",
                "label": "Wyoming"
            }
        ]
    },
    {
        "country": {
            "label": "Uruguay",
            "value": "uruguay"
        },
        "states": [
            {
                "value": "artigas",
                "label": "Artigas"
            },
            {
                "value": "canelones",
                "label": "Canelones"
            },
            {
                "value": "cerro largo",
                "label": "Cerro Largo"
            },
            {
                "value": "colonia",
                "label": "Colonia"
            },
            {
                "value": "durazno",
                "label": "Durazno"
            },
            {
                "value": "flores",
                "label": "Flores"
            },
            {
                "value": "florida",
                "label": "Florida"
            },
            {
                "value": "lavalleja",
                "label": "Lavalleja"
            },
            {
                "value": "maldonado",
                "label": "Maldonado"
            },
            {
                "value": "montevideo",
                "label": "Montevideo"
            },
            {
                "value": "paysandu",
                "label": "Paysandu"
            },
            {
                "value": "rio negro",
                "label": "Rio Negro"
            },
            {
                "value": "rivera",
                "label": "Rivera"
            },
            {
                "value": "rocha",
                "label": "Rocha"
            },
            {
                "value": "salto",
                "label": "Salto"
            },
            {
                "value": "san jose",
                "label": "San Jose"
            },
            {
                "value": "soriano",
                "label": "Soriano"
            },
            {
                "value": "tacuarembo",
                "label": "Tacuarembo"
            },
            {
                "value": "treinta y tres",
                "label": "Treinta y Tres"
            }
        ]
    },
    {
        "country": {
            "label": "Uzbekistan",
            "value": "uzbekistan"
        },
        "states": [
            {
                "value": "andijon viloyati",
                "label": "Andijon Viloyati"
            },
            {
                "value": "buxoro viloyati",
                "label": "Buxoro Viloyati"
            },
            {
                "value": "farg'ona viloyati",
                "label": "Farg'ona Viloyati"
            },
            {
                "value": "jizzax viloyati",
                "label": "Jizzax Viloyati"
            },
            {
                "value": "namangan viloyati",
                "label": "Namangan Viloyati"
            },
            {
                "value": "navoiy viloyati",
                "label": "Navoiy Viloyati"
            },
            {
                "value": "qashqadaryo viloyati",
                "label": "Qashqadaryo Viloyati"
            },
            {
                "value": "qaraqalpog'iston respublikasi",
                "label": "Qaraqalpog'iston Respublikasi"
            },
            {
                "value": "samarqand viloyati",
                "label": "Samarqand Viloyati"
            },
            {
                "value": "sirdaryo viloyati",
                "label": "Sirdaryo Viloyati"
            },
            {
                "value": "surxondaryo viloyati",
                "label": "Surxondaryo Viloyati"
            },
            {
                "value": "toshkent shahri",
                "label": "Toshkent Shahri"
            },
            {
                "value": "toshkent viloyati",
                "label": "Toshkent Viloyati"
            },
            {
                "value": "xorazm viloyati",
                "label": "Xorazm Viloyati"
            }
        ]
    },
    {
        "country": {
            "label": "Vanuatu",
            "value": "vanuatu"
        },
        "states": [
            {
                "value": "malampa",
                "label": "Malampa"
            },
            {
                "value": "penama",
                "label": "Penama"
            },
            {
                "value": "sanma",
                "label": "Sanma"
            },
            {
                "value": "shefa",
                "label": "Shefa"
            },
            {
                "value": "tafea",
                "label": "Tafea"
            },
            {
                "value": "torba",
                "label": "Torba"
            }
        ]
    },
    {
        "country": {
            "label": "Venezuela",
            "value": "venezuela"
        },
        "states": [
            {
                "value": "amazonas",
                "label": "Amazonas"
            },
            {
                "value": "anzoategui",
                "label": "Anzoategui"
            },
            {
                "value": "apure",
                "label": "Apure"
            },
            {
                "value": "aragua",
                "label": "Aragua"
            },
            {
                "value": "barinas",
                "label": "Barinas"
            },
            {
                "value": "bolivar",
                "label": "Bolivar"
            },
            {
                "value": "carabobo",
                "label": "Carabobo"
            },
            {
                "value": "cojedes",
                "label": "Cojedes"
            },
            {
                "value": "delta amacuro",
                "label": "Delta Amacuro"
            },
            {
                "value": "dependencias federales",
                "label": "Dependencias Federales"
            },
            {
                "value": "distrito federal",
                "label": "Distrito Federal"
            },
            {
                "value": "falcon",
                "label": "Falcon"
            },
            {
                "value": "guarico",
                "label": "Guarico"
            },
            {
                "value": "lara",
                "label": "Lara"
            },
            {
                "value": "merida",
                "label": "Merida"
            },
            {
                "value": "miranda",
                "label": "Miranda"
            },
            {
                "value": "monagas",
                "label": "Monagas"
            },
            {
                "value": "nueva esparta",
                "label": "Nueva Esparta"
            },
            {
                "value": "portuguesa",
                "label": "Portuguesa"
            },
            {
                "value": "sucre",
                "label": "Sucre"
            },
            {
                "value": "tachira",
                "label": "Tachira"
            },
            {
                "value": "trujillo",
                "label": "Trujillo"
            },
            {
                "value": "vargas",
                "label": "Vargas"
            },
            {
                "value": "yaracuy",
                "label": "Yaracuy"
            },
            {
                "value": "zulia",
                "label": "Zulia"
            }
        ]
    },
    {
        "country": {
            "label": "Vietnam",
            "value": "vietnam"
        },
        "states": [
            {
                "value": "an giang",
                "label": "An Giang"
            },
            {
                "value": "bac giang",
                "label": "Bac Giang"
            },
            {
                "value": "bac kan",
                "label": "Bac Kan"
            },
            {
                "value": "bac lieu",
                "label": "Bac Lieu"
            },
            {
                "value": "bac ninh",
                "label": "Bac Ninh"
            },
            {
                "value": "ba ria-vung tau",
                "label": "Ba Ria-Vung Tau"
            },
            {
                "value": "ben tre",
                "label": "Ben Tre"
            },
            {
                "value": "binh dinh",
                "label": "Binh Dinh"
            },
            {
                "value": "binh duong",
                "label": "Binh Duong"
            },
            {
                "value": "binh phuoc",
                "label": "Binh Phuoc"
            },
            {
                "value": "binh thuan",
                "label": "Binh Thuan"
            },
            {
                "value": "ca mau",
                "label": "Ca Mau"
            },
            {
                "value": "cao bang",
                "label": "Cao Bang"
            },
            {
                "value": "dac lak",
                "label": "Dac Lak"
            },
            {
                "value": "dac nong",
                "label": "Dac Nong"
            },
            {
                "value": "dien bien",
                "label": "Dien Bien"
            },
            {
                "value": "dong nai",
                "label": "Dong Nai"
            },
            {
                "value": "dong thap",
                "label": "Dong Thap"
            },
            {
                "value": "gia lai",
                "label": "Gia Lai"
            },
            {
                "value": "ha giang",
                "label": "Ha Giang"
            },
            {
                "value": "hai duong",
                "label": "Hai Duong"
            },
            {
                "value": "ha nam",
                "label": "Ha Nam"
            },
            {
                "value": "ha tay",
                "label": "Ha Tay"
            },
            {
                "value": "ha tinh",
                "label": "Ha Tinh"
            },
            {
                "value": "hau giang",
                "label": "Hau Giang"
            },
            {
                "value": "hoa binh",
                "label": "Hoa Binh"
            },
            {
                "value": "hung yen",
                "label": "Hung Yen"
            },
            {
                "value": "khanh hoa",
                "label": "Khanh Hoa"
            },
            {
                "value": "kien giang",
                "label": "Kien Giang"
            },
            {
                "value": "kon tum",
                "label": "Kon Tum"
            },
            {
                "value": "lai chau",
                "label": "Lai Chau"
            },
            {
                "value": "lam dong",
                "label": "Lam Dong"
            },
            {
                "value": "lang son",
                "label": "Lang Son"
            },
            {
                "value": "lao cai",
                "label": "Lao Cai"
            },
            {
                "value": "long an",
                "label": "Long An"
            },
            {
                "value": "nam dinh",
                "label": "Nam Dinh"
            },
            {
                "value": "nghe an",
                "label": "Nghe An"
            },
            {
                "value": "ninh binh",
                "label": "Ninh Binh"
            },
            {
                "value": "ninh thuan",
                "label": "Ninh Thuan"
            },
            {
                "value": "phu tho",
                "label": "Phu Tho"
            },
            {
                "value": "phu yen",
                "label": "Phu Yen"
            },
            {
                "value": "quang binh",
                "label": "Quang Binh"
            },
            {
                "value": "quang nam",
                "label": "Quang Nam"
            },
            {
                "value": "quang ngai",
                "label": "Quang Ngai"
            },
            {
                "value": "quang ninh",
                "label": "Quang Ninh"
            },
            {
                "value": "quang tri",
                "label": "Quang Tri"
            },
            {
                "value": "soc trang",
                "label": "Soc Trang"
            },
            {
                "value": "son la",
                "label": "Son La"
            },
            {
                "value": "tay ninh",
                "label": "Tay Ninh"
            },
            {
                "value": "thai binh",
                "label": "Thai Binh"
            },
            {
                "value": "thai nguyen",
                "label": "Thai Nguyen"
            },
            {
                "value": "thanh hoa",
                "label": "Thanh Hoa"
            },
            {
                "value": "thua thien-hue",
                "label": "Thua Thien-Hue"
            },
            {
                "value": "tien giang",
                "label": "Tien Giang"
            },
            {
                "value": "tra vinh",
                "label": "Tra Vinh"
            },
            {
                "value": "tuyen quang",
                "label": "Tuyen Quang"
            },
            {
                "value": "vinh long",
                "label": "Vinh Long"
            },
            {
                "value": "vinh phuc",
                "label": "Vinh Phuc"
            },
            {
                "value": "yen bai",
                "label": "Yen Bai"
            },
            {
                "value": "can tho",
                "label": "Can Tho"
            },
            {
                "value": "da nang",
                "label": "Da Nang"
            },
            {
                "value": "hai phong",
                "label": "Hai Phong"
            },
            {
                "value": "hanoi",
                "label": "Hanoi"
            },
            {
                "value": "ho chi minh",
                "label": "Ho Chi Minh"
            }
        ]
    },
    {
        "country": {
            "label": "Yemen",
            "value": "yemen"
        },
        "states": [
            {
                "value": "abyan",
                "label": "Abyan"
            },
            {
                "value": "'adan",
                "label": "'Adan"
            },
            {
                "value": "ad dali'",
                "label": "Ad Dali'"
            },
            {
                "value": "al bayda'",
                "label": "Al Bayda'"
            },
            {
                "value": "al hudaydah",
                "label": "Al Hudaydah"
            },
            {
                "value": "al jawf",
                "label": "Al Jawf"
            },
            {
                "value": "al mahrah",
                "label": "Al Mahrah"
            },
            {
                "value": "al mahwit",
                "label": "Al Mahwit"
            },
            {
                "value": "'amran",
                "label": "'Amran"
            },
            {
                "value": "dhamar",
                "label": "Dhamar"
            },
            {
                "value": "hadramawt",
                "label": "Hadramawt"
            },
            {
                "value": "hajjah",
                "label": "Hajjah"
            },
            {
                "value": "ibb",
                "label": "Ibb"
            },
            {
                "value": "lahij",
                "label": "Lahij"
            },
            {
                "value": "ma'rib",
                "label": "Ma'rib"
            },
            {
                "value": "sa'dah",
                "label": "Sa'dah"
            },
            {
                "value": "san'a'",
                "label": "San'a'"
            },
            {
                "value": "shabwah",
                "label": "Shabwah"
            },
            {
                "value": "ta'izz",
                "label": "Ta'izz"
            }
        ]
    },
    {
        "country": {
            "label": "Zambia",
            "value": "zambia"
        },
        "states": [
            {
                "value": "central",
                "label": "Central"
            },
            {
                "value": "copperbelt",
                "label": "Copperbelt"
            },
            {
                "value": "eastern",
                "label": "Eastern"
            },
            {
                "value": "luapula",
                "label": "Luapula"
            },
            {
                "value": "lusaka",
                "label": "Lusaka"
            },
            {
                "value": "northern",
                "label": "Northern"
            },
            {
                "value": "north-western",
                "label": "North-Western"
            },
            {
                "value": "southern",
                "label": "Southern"
            },
            {
                "value": "western",
                "label": "Western"
            }
        ]
    },
    {
        "country": {
            "label": "Zimbabwe",
            "value": "zimbabwe"
        },
        "states": [
            {
                "value": "bulawayo",
                "label": "Bulawayo"
            },
            {
                "value": "harare",
                "label": "Harare"
            },
            {
                "value": "manicaland",
                "label": "Manicaland"
            },
            {
                "value": "mashonaland central",
                "label": "Mashonaland Central"
            },
            {
                "value": "mashonaland east",
                "label": "Mashonaland East"
            },
            {
                "value": "mashonaland west",
                "label": "Mashonaland West"
            },
            {
                "value": "masvingo",
                "label": "Masvingo"
            },
            {
                "value": "matabeleland north",
                "label": "Matabeleland North"
            },
            {
                "value": "matabeleland south",
                "label": "Matabeleland South"
            },
            {
                "value": "midlands",
                "label": "Midlands"
            }
        ]
    }
]
import { saveAs as importedSaveAs } from 'file-saver';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ViewAttachmentComponent } from '../view-attachment/view-attachment.component';

@Component({
  selector: 'app-gs4-view-photos',
  templateUrl: './view-docs.component.html',
  styleUrls: ['./view-docs.component.scss']
})
export class ViewDocsComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ViewDocsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) { this.dialogRef.disableClose = true; }

  ngOnInit(): void { }

  public downloadDoc(doc: any) {
    importedSaveAs(doc.url, doc.name);
  }

  public verifyPDF(url: any) {
    return url.indexOf('.pdf') != -1 === true;
  }

  public seeAttached(_attachment: any) {
    this.dialog.open(ViewAttachmentComponent, {
      width: '500px',
      data: { attachment: _attachment },
      panelClass: 'app-gs4-view-attachment'
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

}
import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { Colors } from 'src/utils/colors';

@Component({
  selector: 'app-header-pages',
  templateUrl: './header-pages.component.html',
  styleUrls: ['./header-pages.component.scss']
})
export class HeaderPagesComponent implements OnInit {

  @Input() title: string = 'Header Pages';
  @Input() description: string;
  @Input() bgImage: string = 'header-my-account.webp';
  @Input() bgColor: string = '#000';
  @Input() leftFlagColor: string = Colors.deepPurple;
  @Input() rightFlagColor: string = Colors.greenAd;

  @HostBinding('style.--left-flag-color') leftFlagColorHostBinding: string
  @HostBinding('style.--right-flag-color') rightFlagColorHostBinding: string

  constructor() { }

  ngOnInit(): void {
    this.leftFlagColorHostBinding = this.leftFlagColor;
    this.rightFlagColorHostBinding = this.rightFlagColor;
  }

}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { ViewAttachmentComponent } from '../view-attachment/view-attachment.component';
import { AttachFileService } from './attach-file.service';

declare var $: any;

@Component({
  selector: 'app-gs4-attach-file',
  templateUrl: './attach-file.component.html',
  styleUrls: ['./attach-file.component.scss']
})
export class AttachFileComponent implements OnInit {

  @Input('limit') limit = 4;
  @Input('idOption') idOption = null;
  @Input('folder') folder: string = '';
  @Input('files') files: Array<any> = [];
  @Input('canAdd') canAdd: boolean = false;
  @Input('category') category: string = '';
  @Output('toSave') toSave = new EventEmitter();
  @Input('canDelete') canDelete: boolean = false;
  @Input('allowed') allowed = 'jpg|jpeg|png|jng|ico|svg|gif|jfif|tiff|tif|bmp|webp|wbmp|pdf|txt|docx';
  // We accept more options
  // new-api-global4-student/app/Services/AwsS3.js - Row Types

  constructor(
    private _attachFileService: AttachFileService,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void { }

  public verifyPDF(url: any) {
    return url.indexOf('.pdf') != -1 === true;
  }

  public changeListener($event: any): void {
    this.readThis($event.target);
  }

  public propagateChange(reader: any, file: any): void {
    const imagem = reader;
    if ((this.files?.length + 1) <= this.limit) {
      this.add(imagem, file);
      return;
    }

    this.toastr.info('Maximum number of ' + this.limit + ' reached files!', 'Oops!');
  }

  public readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    if (file) {
      myReader.onloadend = () => {
        this.propagateChange(myReader.result, file);
      };

      myReader.readAsDataURL(file);
    }
  }

  public add(archive: any, fl: any): void {
    const category = 'fileLoading_' + this.category;

    let file = {
      name: fl.name,
      url: archive,
      format: '',
      resourceType: ''
    }

    const patt = new RegExp('.(' + this.allowed + ')');
    const res = patt.test(fl.name);

    if (res) {
      this._attachFileService[category].emit(true);
      this._attachFileService.docUpload({ name: file.name, url: file.url, folder: this.folder, idOption: this.idOption })
        .subscribe((x: any) => {
          if (x.status === 'success') {
            file.format = x.data?.url?.format;
            file.url = x.data?.url?.secure_url;
            file.resourceType = x.data?.url?.resourceType;

            this.files.push(file);
            this.toSave.emit(this.files);
            this._attachFileService[category].emit(false);

          } else if (x.status === 'error') {
            this._attachFileService[category].emit(false);
            this.toastr.error('An error occurred when trying to upload. Try again in a few moments.', 'Error');
          }
        },
          (err: any) => {
            this._attachFileService[category].emit(false);
            this.toastr.error('An error occurred when trying to upload. Try again in a few moments.', 'Error');
          });
    } else {
      this.toastr.info('This file type is not valid', 'Oops!');
    }
  }

  public downloadDoc(doc: any): void {
    importedSaveAs(doc.url, doc.name);
  }

  public seeAttached(_attachment: any): void {
    this.dialog.open(ViewAttachmentComponent, {
      width: '500px',
      data: { attachment: _attachment },
      panelClass: 'app-gs4-view-attachment'
    });
  }

  public endpointDelete(file?: any, index?: any, action?: any): void {
    let arrayIds = [];
    const category = 'fileLoading_' + this.category;
    this._attachFileService[category].emit(true);

    if (file) {
      arrayIds.push(file.url);

    } else {
      this.files.forEach(file => {
        arrayIds.push(file.url);
      });
    }

    this._attachFileService.docDestroy(arrayIds)
      .subscribe((x: any) => {
        if (x.status === 'success') {
          action === 'deleteAll' ? this.files = [] : this.files.splice(index, 1);

          this.toSave.emit(this.files);
          this._attachFileService[category].emit(false);

        } else if (x.status === 'error') {
          this._attachFileService[category].emit(false);
          this.toastr.error('An error occurred while trying to delete the file. Please try again in a few moments.', 'Error');
        }
      },
        (err: any) => {
          this._attachFileService[category].emit(false);
          this.toastr.error('An error occurred while trying to delete the file. Please try again in a few moments.', 'Error');
        });
  }

}
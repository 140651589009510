import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Colors } from 'src/utils/colors';
import { TableModel } from 'src/models/table.model';
import { PlanDetailsComponent } from '../modals/plan-details/plan-details.component';
import { ViewDocsComponent } from '../view-docs/view-docs.component';

@Component({
  selector: 'app-table-my-purchases',
  templateUrl: './table-my-purchases.component.html',
  styleUrls: ['./table-my-purchases.component.scss']
})
export class TableMyPurchasesComponent implements OnInit {

  public Colors = Colors;

  @Input() tableData: TableModel[];

  @Output() onSelectPlan: EventEmitter<TableModel> = new EventEmitter<TableModel>();

  constructor(
    private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.tableData.length > 0) {
      this.tableData = this.tableData.map((plan: any) => {
        return {
          ...plan,
          dailyRate: plan?.policy.dailyPrice,
          name: plan?.policy.name,
        };
      });
    }
  }

  public selectPlan(plan: TableModel): void {
    this.onSelectPlan.emit(plan);
  }

  public openDetails(data: any): void {
    this.dialog.open(PlanDetailsComponent, {
      width: '500px',
      data: {
        ...data.policy,
        dailyRate: data.policy.dailyPrice
      }
    });
  }

  public searchNewPlan(): void {
    this.router.navigate(['plan-all']);
  }

  public getCurrentPlan(data): string {
    return data.priceUnit === 'euro' ? 'EUR' : 'USD';
  }

  public viewDocs(order: any): void {
    if (!order?.documents || !order.documents.length) {
      this.toastr.info('No documents at the moment, check back later.', 'Oops!');

    } else {
      const dialogRef = this.dialog.open(ViewDocsComponent, {
        width: '800px',
        data: { msg: `Documents - ${order?.policy?.name}`, documents: order?.documents || [] },
        panelClass: 'app-gs4-view-photos'
      });

      dialogRef.afterClosed();
    }
  }

}

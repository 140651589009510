import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AttachFileService {

  public fileLoading_blog = new EventEmitter();
  public fileLoading_order = new EventEmitter();

  private _url: string;
  private httpHeaders = {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }

  constructor(
    private _http: HttpClient
  ) {
    this._url = environment.api_url;
  }

  public docUpload(params: any): Observable<any> {
    return this._http.post(`${this._url}/upload`, params, this.httpHeaders);
  }

  public docDestroy(params: any): Observable<any> {
    return this._http.post(`${this._url}/destroy`, { params }, this.httpHeaders);
  }

}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {

  @Output() onUserMakeLogout = new EventEmitter<any>();

  private _url: string;
  private httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    private _http: HttpClient
  ) { 
    this._url = environment.api_url;
    this.httpHeaders.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
  }

  public getUser(): any {
    return JSON.parse(localStorage.getItem('user'));
  }

  public updateUser(user: any): Observable<any> {
    return this._http.put(`${this._url}/user`, user, { headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      } 
    });
  }

  public logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.onUserMakeLogout.emit();
  }

  public getMyPlans(status: string): Observable<any> {
    return this._http.get(`${this._url}/order/${status}`, { headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      } 
    });
  }
}

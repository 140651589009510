import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Colors } from 'src/utils/colors';
import { LoginService } from './login.service';
import { FunctionsService } from 'src/app/shared/functions.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  @Input() screenOrigin: string;
  @Output() onRecoveryPassword = new EventEmitter();

  public Colors = Colors;
  public email: string;
  public password: string;
  public rememberMe: boolean;
  public loading: boolean;
  public withoutPurchase: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _functions: FunctionsService,
    private loginService: LoginService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.withoutPurchase = params['withoutPurchase'];
    });

    document.body.scrollTop = 0
  }

  public login(): void {
    let emailValid = true;

    if (!this.email) {
      this.toastr.error('Please fill in the email field.', 'Error');
      emailValid = false;

    } else if (!this._functions.validateEmail(this.email)) {
      this.toastr.error('The email field does not contain a valid email address.', 'Error');
      emailValid = false;
    }

    if (emailValid) {
      this.loading = true;
      this.loginService.login({
        email: this.email,
        password: this.password,
        rememberMe: this.rememberMe
      }).subscribe((response) => {
        if (response.status === 'success') {
          this.loginService.setToken(response.token.access_token);
          this.loginService.setUser(response.user);

          if (this.withoutPurchase) {
            this.router.navigate(['/my-account/dashboard']);
            return;
          }

          if (this.screenOrigin === 'purchase-flux') {
            this.router.navigate(['/purchase-flux/cart']);
            return;
          }

          if (response.user.profile === 'administrator') {
            this.router.navigate(['/admin']);
            return;
          }
        }
        this.loading = false;
      }, (error) => {
        this.loading = false;
        this.toastr.error(error?.error?.message || 'Something went wrong, please try again later.', 'Error');
      });
    }
  }

}
<table>
    <tr class="table-header">
        <th></th>
        <th style="width: 40%;"></th>
        <th style="width: 30%">{{ 'policyDuration' | translate }}</th>
        <th style="width: 10%;">{{ 'subtotal' | translate }}</th>
    </tr>
    <tr class="table-body">
        <td>
            <div *ngIf="getCompanyLogo()" class="mt-4 mb-5">
                <img [src]="getCompanyLogo()" alt="cart image" class="img-fluid" />
            </div>
        </td>
        <td>
            <div class="m-4 mb-5">
                <h3 class="name mb-3">{{ cart?.name }}</h3>
                <p class="item" *ngIf="cart?.visaType">{{ 'visaType' | translate }}: <strong>{{ cart?.visaType }}</strong></p>
                <p class="item" *ngIf="cart?.state">{{ 'state' | translate }}: <strong>{{ cart?.state }}</strong></p>
                <p class="item" *ngIf="cart?.university">{{ 'university' | translate }}: <strong>{{ cart?.university }}</strong></p>
                <p class="item">{{ 'dailyRate' | translate }}: <strong>{{ cart?.dailyPrice | currency:getCurrentPlan():true }}</strong></p>
            </div>
        </td>
        <td>
            <div class="mt-4 mb-5">
                <div class="col-10">
                    <app-input 
                    inputId="startDate" 
                    inputLabel="{{ 'startDate' | translate }}" 
                    inputType="date"
                    (inputChange)="handleChangeDate($event.target.value)" 
                    [(ngModel)]="cart.startDate" 
                    ngDefaultControl
                    [value]="cart?.startDate"
                ></app-input>
                </div>
                <div class="col-10 mt-4">
                    <app-select
                        selectId="duration"
                        selectLabel="{{ 'duration' | translate }}"
                        [options]="durationOptions"
                        [selected]="cart?.duration"
                        (selectChange)="handleChangeDuration($event.target.value)"
                        [(ngModel)]="cart.duration"
                        ngDefaultControl
                    ></app-select>
                </div>
            </div>
        </td>
        <td>
            <div class="mt-4 mb-5">
                <p class="mb-0">{{ cart?.total | currency:getCurrentPlan():true }}</p>
            </div>
        </td>
    </tr>
    <tfoot>
        <tr>
            <td></td>
            <td></td>
            <td>
                <div class="col-10">
                    <p style="text-align: right;" class="mb-0">{{ 'total' | translate }}</p>
                </div>
            </td>
            <td>{{ cart?.total | currency:getCurrentPlan():true }}</td>
        </tr>
    </tfoot>
</table>
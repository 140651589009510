<div class="container-modal-user-details">
    <div class="col-12 d-flex justify-content-end">
        <button class="btn" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <h4 class="modal-title">{{ data?.name }} {{ data?.lastName }}</h4>
    <div class="row">
        <div class="col-12">
            <p class="item">Gender: 
                <strong>{{ data?.genre || 'Has not been defined' }}</strong>
            </p>
            <p class="item">Birth date: 
                <strong>{{ data?.birthDate | date:"dd/MM/yyyy" }}</strong>
            </p>
            <p class="item">Phone: 
                <strong>{{ data?.phone }}</strong>
            </p>
            <p class="item">Mobile: 
                <strong>{{ data?.mobile }}</strong>
            </p>
            <p class="item">Email: 
                <strong>{{ data?.email }}</strong>
            </p>
            <p class="item">Passport: 
                <strong>{{ data?.passport }}</strong>
            </p>
            <p class="item">Country of Birth: 
                <strong>{{ data?.countryBirth | firstLetterWord }}</strong>
            </p>
            <p class="item">State: 
                <strong>{{ data?.address?.state | firstLetterWord }}</strong>
            </p>
            <p class="item">City: 
                <strong>{{ data?.address?.city | firstLetterWord }}</strong>
            </p>
            <p class="item">Address: 
                <strong>{{ data?.address?.address }}</strong>
            </p>
            <p class="item">Postal Code: 
                <strong>{{ data?.address?.zipCode }}</strong>
            </p>
        </div>
    </div>
</div>
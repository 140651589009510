import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() label: string;
  @Input() id: string;
  @Input() customLabelColor: string;
  @Input() customLabelSize: string;
  @Input() customLabelWeight: string = 'normal';
  @Input() value: any;

  @Output() checkboxChange: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  public handleChange(event: any): void {
    this.checkboxChange.emit(event);
  }
}

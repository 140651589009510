<div class="register-container">
    <div class="register mx-4">
        <div class="register__title pt-4">
            <h2>{{ 'register' | translate }}</h2>
            <div class="line-stronger"></div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6">
                <app-input
                    inputId="firstName" 
                    inputLabel="{{ 'firstName' | translate }}*" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="firstName = $event.target.value"
                    [(ngModel)]="firstName"
                    ngDefaultControl
                ></app-input>
            </div>
            <div class="col-12 col-md-6 mt-4 mt-md-0">
                <app-input
                    inputId="lastName" 
                    inputLabel="{{ 'lastName' | translate }}*" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="lastName = $event.target.value"
                    [(ngModel)]="lastName"
                    ngDefaultControl
                ></app-input>
            </div>
        </div>

        <div class="mt-4">
            <app-input
                inputId="email" 
                inputLabel="{{ 'email' | translate }}*" 
                inputType="email" 
                inputPlaceholder=""
                (inputChange)="email = $event.target.value"
                [(ngModel)]="email"
                ngDefaultControl
            ></app-input>
        </div>

        <div class="mt-4">
            <app-input
                inputId="password" 
                inputLabel="{{ 'password' | translate }}*" 
                inputType="password" 
                inputPlaceholder=""
                (inputChange)="password = $event.target.value"
                [(ngModel)]="password"
                ngDefaultControl
                isPassword="true"
            ></app-input>
        </div>

        <div class="mt-3">
            <app-checkbox
                id="receiveInformation"
                label="{{ 'receiveInformation' | translate }}"
                (checkboxChange)="receiveInformation = $event.target.checked"
                [(ngModel)]="receiveInformation"
                ngDefaultControl
            >
            </app-checkbox>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-6">
                <app-select 
                    selectId="gender" 
                    selectLabel="{{ 'gender' | translate }}*:"
                    [options]="genders" 
                    (selectChange)="gender = $event.target.value"
                    [(ngModel)]="gender" 
                    ngDefaultControl
                ></app-select>
            </div>
            <div class="col-12 col-md-6 mt-4 mt-md-0">
                <app-input
                    inputId="birthdate" 
                    inputLabel="{{ 'birthDate' | translate }}*" 
                    inputType="date" 
                    inputPlaceholder=""
                    (inputChange)="birthdate = $event.target.value"
                    [(ngModel)]="birthdate"
                    ngDefaultControl
                ></app-input>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-6">
                <app-select 
                    selectId="country" 
                    selectLabel="{{ 'countryOfBirth' | translate }}*:"
                    [options]="countries" 
                    (selectChange)="country = $event.target.value"
                    [(ngModel)]="country" 
                    ngDefaultControl
                ></app-select>
            </div>
            <div class="col-12 col-md-6 mt-4 mt-md-0">
                <app-input
                    inputId="passport" 
                    inputLabel="{{ 'passport' | translate }}*" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="passport = $event.target.value"
                    [(ngModel)]="passport"
                    ngDefaultControl
                ></app-input>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-6">
                <app-input
                    inputId="phone" 
                    inputLabel="{{ 'phone' | translate }}*" 
                    inputType="number" 
                    inputPlaceholder=""
                    (inputChange)="phone = $event.target.value"
                    [(ngModel)]="phone"
                    ngDefaultControl
                ></app-input>
            </div>
            <div class="col-12 col-md-6 mt-4 mt-md-0">
                <app-input
                    inputId="mobile" 
                    inputLabel="{{ 'mobile' | translate }}" 
                    inputType="number" 
                    inputPlaceholder=""
                    (inputChange)="mobile = $event.target.value"
                    [(ngModel)]="mobile"
                    ngDefaultControl
                ></app-input>
            </div>
        </div>
        
        <div class="text-center mt-4">
            <label for="address">{{ 'universitiesSchoolsInformation' | translate }}</label>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-6">
                <app-select 
                    selectId="university.country" 
                    selectLabel="{{ 'country' | translate }}*:"
                    [options]="countries" 
                    (selectChange)="onSelectUniversityCountry($event.target.value)"
                    [(ngModel)]="university.country" 
                    ngDefaultControl
                ></app-select>
            </div>
            <div class="col-12 col-md-6 mt-4 mt-md-0">
                <app-select 
                    selectId="university.state" 
                    selectLabel="{{ 'state' | translate }}*:"
                    [options]="statesByCountry" 
                    (selectChange)="university.state = $event.target.value"
                    [(ngModel)]="university.state" 
                    ngDefaultControl
                ></app-select>
            </div>
        </div>

       <div class="mt-4">
            <app-input
                inputId="university.address" 
                inputLabel="{{ 'address' | translate }}*" 
                inputType="text" 
                inputPlaceholder=""
                (inputChange)="university.address = $event.target.value"
                [(ngModel)]="university.address"
                ngDefaultControl
            ></app-input>
       </div>

        <div class="row mt-4">
            <div class="col-12 col-md-6">
                <app-input
                    inputId="university.city" 
                    inputLabel="{{ 'city' | translate }}*" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="university.city = $event.target.value"
                    [(ngModel)]="university.city"
                    ngDefaultControl
                ></app-input>
            </div>
            <div class="col-12 col-md-6 mt-4 mt-md-0">
                <app-input
                    inputId="university.postalCode" 
                    inputLabel="{{ 'postalCode' | translate }}*" 
                    inputType="number" 
                    inputPlaceholder=""
                    (inputChange)="university.postalCode = $event.target.value"
                    [(ngModel)]="university.postalCode"
                    ngDefaultControl
                ></app-input>
            </div>
        </div>

        <div class="mt-4">
            <p class="policy-info">
                {{ 'yourPersonalDataWiilBeUseToSupport' | translate }} <a href="">{{ 'privacyPolicy' | translate }}</a>.
            </p>
        </div>

        <div class="mt-3">
            <app-checkbox
                id="termsAccepted"
                label="{{ 'byUsingThisFormYouAgree' | translate }}"
                (checkboxChange)="termsAccepted = $event.target.checked"
                [(ngModel)]="termsAccepted"
                ngDefaultControl
            >
            </app-checkbox>
        </div>
    </div>

    <div class="register__button pb-5 mt-5">
        <app-button 
            (click)="register()" 
            buttonLabel="{{ 'register' | translate }}" 
            [buttonBGColor]="Colors.deepPurple"
            [loading]="loading"
        ></app-button>
    </div>
</div>
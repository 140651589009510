<div class="input">
    <label [for]="inputId" [style.color]="labelColor">{{ inputLabel }}</label>
    <input 
        *ngIf="!mask"
        [id]="inputId" 
        [type]="inputType" 
        [placeholder]="inputPlaceholder" 
        [name]="name"
        (change)="inputChange.emit($event)"
        [(ngModel)]="value"
        [ngClass]="{ 'selected-green': selected }"
        (keyup.enter)="onKeyEnter.emit($event)"
        [disabled]="disabled"
    />
    <input 
        *ngIf="mask"
        [id]="inputId" 
        [type]="inputType" 
        [placeholder]="inputPlaceholder" 
        [name]="name"
        (change)="inputChange.emit($event)"
        [mask]="mask"
        [(ngModel)]="value"
        [ngClass]="{ 'selected-green': selected }"
        (keyup.enter)="onKeyEnter.emit($event)"
        [disabled]="disabled"
    />
    <div *ngIf="isPassword">
        <mat-icon 
            class="icon-eye" 
            [ngClass]="{ 'selected-green': selected }" 
            (click)="handleShowPassword()"
        >
            {{ showPassword ? 'visibility_off' : 'visibility' }}
        </mat-icon>
    </div>
</div>
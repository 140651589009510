import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Colors } from 'src/utils/colors';

@Component({
  selector: 'app-gs4-not-found-plan',
  templateUrl: './not-found-plan.component.html',
  styleUrls: ['./not-found-plan.component.scss']
})
export class NotFoundPlanComponent implements OnInit {

  public Colors = Colors;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public handleNavigate(path: string): void {
    this.router.navigate([path]);
  }

  public onClickChatWithUs() {
    window.open('https://api.whatsapp.com/send/?phone=+18884055205&text=Hi%2C%20I%20need%20help%21&type=phone_number&app_absent=0', '_blank');
  }
  // public onClickChatWithUs() {
  //   window.open('/international-student/contact-an-insurance-expert', '_blank');
  // }
}

<div class="breadcrumb-custom-purchase">
    <div *ngFor="let step of steps" class="breadcrumb-custom-item" (click)="onStepClicked(step)">
        <div class="breadcrumb-custom-item__icon">
            <mat-icon 
                class="breadcrumb-item__icon_color" 
                aria-hidden="false" 
                aria-label="Example home icon"
                [ngStyle]="{ 
                    'color': step.active ? Colors.deepPurple : !step.active && step.completed ? Colors.lightGray : Colors.darkGray 
                }"
            >check_circle</mat-icon>
        </div>
        <div class="breadcrumb-custom-item__line"></div>
        <div 
            class="breadcrumb-custom-item__label" 
            [ngStyle]="{ 
                'color': step.active ? Colors.deepPurple : !step.active && step.completed ? Colors.lightGray : Colors.darkGray, 
                'font-weight': step.active ? 'bold' : 'normal' 
            }"
        >
            <p class="mb-0">{{ step?.label | translate }}</p>
        </div>
    </div>
</div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api_url: 'https://global4students.com/api',
  paypal_client_id: 'AWjec4f8g9Msf6l5ayQIYnoVIXBTGD8Wtls3SJ0WJX1oKO0PY_R1ffsrogxfqG5J2DMBJj0MRCT3BqYk'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


import { AfterViewChecked, Component, ElementRef, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit, AfterViewChecked {

  @Input() index: string;
  @Input() title: string;
  @Input() description: string;

  public isExpanded: boolean = false;

  constructor(
    protected sanitizer: DomSanitizer,
    private router: Router,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewChecked() {
    if (this.elementRef.nativeElement.querySelector('#buttonTerms')) {
      this.elementRef.nativeElement
        .querySelector('#buttonTerms')
        .addEventListener(
          'click',
          this.navigateTo.bind(this, 'privacy-policy')
        );
    }
  }

  public handleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  public navigateTo(path: string): any {
    this.router.navigate([path]);
  }
}

<div class="container-ad">
    <div class="row mx-md-5 h-100">
        <div class="text-area col-12 col-md-8">
            <h2>Are you going to study in the USA or Abroad? Get your International Student Insurance with us!</h2>
            <div class="mt-3">
                <app-button 
                    (click)="getQuote()" 
                    buttonLabel="{{ 'getYourQuoteNow' | translate }}!" 
                    [buttonBGColor]="Colors.pink"
                ></app-button>
            </div>
        </div>
        <div class="col-4 mt-auto hide-mobile image-bg">
            <!-- <img src="../../../assets/img/woman-ad.webp" alt="Insure and Go" /> -->
        </div> 
    </div>
</div>
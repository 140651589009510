<div class="col-12 col-md-10 mx-auto not-found-plan">
    <div class="row">
        <div class="col-12 col-md-6 my-auto">
            <h2>Hey! We are updating some plans on the website.</h2>
            <p>But we are happy to find a plan that fits your needs and school requirements. Please contact us, and we will send you options just for you!</p>
            <!-- <div class="mt-3">
                <app-button class="force-margin-left" (click)="handleNavigate('student-health-insurance-plans')" buttonLabel="{{ 'newSearch' | translate }}" [buttonBGColor]="Colors.deepPurple"></app-button>
            </div> -->
            <div class="mt-3">
                <app-button (click)="handleNavigate('international-student/contact-an-insurance-expert')" buttonLabel="{{ 'sendAnEmailToOurTeam' | translate }}" [buttonBGColor]="Colors.deepPurple"></app-button>
            </div>
            <div class="mt-3">
                <app-button (click)="onClickChatWithUs()" buttonLabel="{{ 'chatWithOurTeam' | translate }}" [buttonBGColor]="Colors.deepPurple"></app-button>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <img class="img-fluid" src="../../../assets/img/not-found-plan-woman.png" alt="Not found plan" />
        </div>
    </div>
</div>
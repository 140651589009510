<div class="processing-payment">
    <div class="payment-header pt-4">
        <h2>{{ 'payment' | translate }}</h2>
        <div class="line-stronger mt-3"></div>
    </div>

    <div class="mx-auto col-8 text-center mt-5 info-texts">
        <h3 class="mb-4">{{ 'pleaseDontCloseThisWindow' | translate }}</h3>

        <p>{{ 'youAreMinutesAwayFromBeignProtected' | translate }}</p>
    </div>
</div>
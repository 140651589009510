import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() inputLabel: string;
  @Input() inputType: string;
  @Input() inputPlaceholder: string;
  @Input() inputId: string;
  @Input() name: string;
  @Input() inputRequired: boolean;
  @Input() mask: string = '';
  @Input() value: string;
  @Input() labelColor: string;
  @Input() selected: boolean = false;
  @Input() isPassword: boolean = false;
  @Input() disabled: boolean = false;

  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onKeyEnter: EventEmitter<string> = new EventEmitter<string>();

  public showPassword: boolean = false;

  constructor() { }

  ngOnInit(): void {}

  public handleShowPassword(): void {
    this.showPassword = !this.showPassword;
    this.inputType = this.showPassword ? 'text' : 'password';
  }
}

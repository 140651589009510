import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private _url: string;

  constructor(
    private _http: HttpClient
  ) { 
    this._url = environment.api_url;
  }

  public setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  public setUser(user: any): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  public register(data: any): Observable<any> {
    return this._http.post(`${this._url}/user`, data);
  }
}

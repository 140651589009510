import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableModel } from 'src/models/table.model';
import { Colors } from 'src/utils/colors';
import { PlanDetailsComponent } from '../../modals/plan-details/plan-details.component';

@Component({
  selector: 'app-card-plan',
  templateUrl: './card-plan.component.html',
  styleUrls: ['./card-plan.component.scss']
})
export class CardPlanComponent implements OnInit {
  public Colors = Colors;

  @Input() plan: any;
  @Output() onSelectPlan: EventEmitter<TableModel> = new EventEmitter<TableModel>();

  public planColors = {
    bestPrice: Colors.bestPrice,
    bestValue: Colors.bestValue,
    bestCoverage: Colors.bestCoverage
  }

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void { }

  public selectPlan(plan: TableModel): void {
    this.onSelectPlan.emit(plan);
  }

  public getPlanName(plan: string): string {
    const options = {
      bestPrice: 'Best Price',
      bestValue: 'Best Value',
      bestCoverage: 'Best Coverage'
    }

    return options[plan];
  }

  public openDetails(data: any): void {
    this.dialog.open(PlanDetailsComponent, {
      width: '500px',
      data
    });
  }
}

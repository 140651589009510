import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { ShortenLinkComponent } from './components/shorten-link/shorten-link.component';

const routes: Routes = [
  // { loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule), path: '' },
  // { path: ':hash', component: ShortenLinkComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.errorHandler = () => {
      this.router.navigate(['']); // 'student-health-insurance-plans'
    }
  }
}
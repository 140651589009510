import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Colors } from 'src/utils/colors';

@Component({
  selector: 'app-gs4-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit {

  @Input() data: any;

  public Colors = Colors;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    if (typeof this.data.tags === 'string') {
      this.data.tags = [this.data.tags];
    }
  }

  public handleReadPost(): void {
    if (this.data.path && this.data.path !== '') {
      this.router.navigate(['/international-student/health-insurance-blog/', this.data.path]);
    } else {
      this.router.navigate(['/international-student/health-insurance-blog/blog', this.data.id]);
    }
  }

  public getPartOfContent(): string {
    const text = this.data.content.slice(0, 100) + '...';
    return text.replace(/(<([^>]+)>)/gi, '');
  }
}

<button 
    class="base-style-button" 
    [ngStyle]="{ 'background-color': colorClone, 'color': buttonTextColor }"
    (mouseover)="hexToRgb()"
    (mouseleave)="colorClone = buttonBGColor"
    [style.width]="customWidth"
>
    <span *ngIf="!loading">{{ buttonLabel }}</span>
    <div *ngIf="loading" class="px-auto">
        <mat-spinner class="mx-auto" [diameter]="30"></mat-spinner>
    </div>
</button>
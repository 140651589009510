<div class="container-search-bar">
    <div class="switch-search-form row pt-3">
        <div class="col-12 d-flex justify-content-center">
            <div>
                <label for="usa">{{ 'usa' | translate }}</label>
                <input 
                    type="radio" 
                    id="usa"
                    [(ngModel)]="searchForm"
                    name="switchSearchForm"
                    value="usa"
                />
            </div>
            <div style="margin-left: 30px;">
                <label for="abroad">{{ 'abroad' | translate }}</label>
                <input 
                    type="radio" 
                    id="abroad"
                    [(ngModel)]="searchForm"
                    name="switchSearchForm"
                    value="abroad"
                />
            </div>
            <div style="margin-left: 30px;">
                <label for="school">{{ 'school' | translate }}</label>
                <input 
                    type="radio" 
                    id="school"
                    [(ngModel)]="searchForm"
                    name="switchSearchForm"
                    value="school"
                />
            </div>
        </div> 
    </div>

    <div *ngIf="searchForm === 'usa'" class="search-bar">
        <div class="row mx-auto">
            <div class="col search-bar__input mt-3 mt-md-0">
                <app-input 
                    name="email"
                    inputId="email" 
                    inputLabel="{{ 'email' | translate }}:" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="email = $event.target.value" 
                    [(ngModel)]="email" 
                    ngDefaultControl
                    labelColor="white"
                ></app-input>
            </div>
            <div class="col search-bar__input mt-3 mt-md-0">
                <app-select 
                    selectId="visaType" 
                    selectLabel="{{ 'visaType' | translate }}:" 
                    [options]="visaTypes"
                    (selectChange)="visaType = $event.target.value" 
                    [(ngModel)]="visaType"
                    ngDefaultControl
                    labelColor="white"
                    customWidth="100%"
                >
                </app-select>
            </div>
            <div class="col search-bar__input mt-3 mt-md-0">
                 <app-input 
                    inputId="birthDate" 
                    inputLabel="{{ 'dateOfBirth' | translate }}:" 
                    inputType="date" 
                    inputPlaceholder=""
                    (inputChange)="birthDate = $event.target.value" 
                    [(ngModel)]="birthDate" 
                    ngDefaultControl
                    labelColor="white"
                ></app-input>
            </div>
            <div class="col search-bar__input mt-3 mt-md-0">
                <app-select 
                    selectId="universityState" 
                    selectLabel="{{ 'universitiesState' | translate }}:"
                    [options]="universityStates" 
                    (selectChange)="handleStateChange($event)"
                    [(ngModel)]="universityState" 
                    ngDefaultControl
                    labelColor="white"
                    customWidth="100%"
                ></app-select>
            </div>
            <div class="col search-bar__input mt-3 mt-md-0">
                <app-select 
                    selectId="universityName" 
                    selectLabel="{{ 'universitiesName' | translate }}:"
                    [options]="universities" 
                    (selectChange)="universityName = $event.target.value"
                    [(ngModel)]="universityName" 
                    ngDefaultControl
                    labelColor="white"
                    customWidth="100%"
                ></app-select>
            </div>
            <div class="col search-bar__button">
                <app-button 
                    (click)="search()" 
                    buttonLabel="{{ 'getQuote' | translate }}"
                    [buttonBGColor]="Colors.pink"
                    customWidth="100%"
                ></app-button>
            </div>
        </div>
    </div>

    <div *ngIf="searchForm === 'abroad'" class="search-bar">
        <div class="row mx-auto">
            <div class="col search-bar__input mt-3 mt-md-0 search-bar__grow">
                <app-input 
                    name="email"
                    inputId="email" 
                    inputLabel="{{ 'email' | translate }}:" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="email = $event.target.value" 
                    [(ngModel)]="email" 
                    ngDefaultControl
                    labelColor="white"
                ></app-input>
            </div>
            <div class="col search-bar__input mt-3 mt-md-0">
                <app-input 
                    inputId="birthDate" 
                    inputLabel="{{ 'birthDate' | translate }}:" 
                    inputType="date"
                    inputPlaceholder=""
                    (inputChange)="birthDate = $event.target.value" 
                    [(ngModel)]="birthDate" 
                    ngDefaultControl
                    labelColor="white"
                ></app-input>
            </div>
            <div class="col search-bar__input mt-3 mt-md-0">
                <app-select 
                    selectId="country" 
                    selectLabel="{{ 'country' | translate }}:"
                    [options]="countriesAbroad" 
                    (selectChange)="country = $event.target.value"
                    [(ngModel)]="country" 
                    ngDefaultControl
                    labelColor="white"
                ></app-select>
            </div>
            <div class="col search-bar__input mt-3 mt-md-0">
                <app-input 
                    inputId="universityNameSearch" 
                    inputLabel="{{ 'universityName' | translate }}:" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="universityNameSearch = $event.target.value" 
                    [(ngModel)]="universityNameSearch" 
                    ngDefaultControl
                    labelColor="white"
                ></app-input>
            </div>
            <div class="col search-bar__button">
                <app-button 
                    (click)="search()" 
                    buttonLabel="{{ 'getQuote' | translate }}"
                    [buttonBGColor]="Colors.pink"
                    customWidth="100%"
                ></app-button>
            </div>
        </div>
    </div>

    <div *ngIf="searchForm === 'school'" class="search-bar">
        <div class="row mx-auto">
            <div class="col search-bar__input mt-3 mt-md-0 search-bar__grow">
                <app-input 
                    name="email"
                    inputId="email" 
                    inputLabel="{{ 'email' | translate }}:" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="email = $event.target.value" 
                    [(ngModel)]="email" 
                    ngDefaultControl
                    labelColor="white"
                ></app-input>
            </div>
            <div class="col search-bar__input mt-3 mt-md-0">
                <app-select 
                    selectId="visaType" 
                    selectLabel="{{ 'visaType' | translate }}:" 
                    [options]="visaTypesSchool"
                    (selectChange)="visaSchool = $event.target.value" 
                    [(ngModel)]="visaSchool"
                    ngDefaultControl
                    labelColor="white"
                    customWidth="100%"
                >
                </app-select>
            </div>
            <div class="col search-bar__input mt-3 mt-md-0">
                <app-input 
                    inputId="birthDate" 
                    inputLabel="{{ 'birthDate' | translate }}:" 
                    inputType="date"
                    inputPlaceholder=""
                    (inputChange)="birthDate = $event.target.value" 
                    [(ngModel)]="birthDate" 
                    ngDefaultControl
                    labelColor="white"
                ></app-input>
            </div>
            <div class="col search-bar__input mt-3 mt-md-0">
                <app-select 
                    selectId="universityState" 
                    selectLabel="{{ 'schoolsState' | translate }}:"
                    [options]="universityStates" 
                    (selectChange)="handleStateChange($event)"
                    [(ngModel)]="universityState" 
                    ngDefaultControl
                    labelColor="white"
                    customWidth="100%"
                ></app-select>
            </div>
            <div class="col search-bar__input mt-3 mt-md-0">
                <app-input 
                    inputId="schoolNameSearch" 
                    inputLabel="{{ 'schoolName' | translate }}:" 
                    inputType="text" 
                    inputPlaceholder=""
                    (inputChange)="schoolNameSearch = $event.target.value" 
                    [(ngModel)]="schoolNameSearch" 
                    ngDefaultControl
                    labelColor="white"
                ></app-input>
            </div>
            <div class="col search-bar__button">
                <app-button 
                    (click)="search()" 
                    buttonLabel="{{ 'getQuote' | translate }}"
                    [buttonBGColor]="Colors.pink"
                    customWidth="100%"
                ></app-button>
            </div>
        </div>
    </div>

    <div class="search-bar__description">
        <h3>
            Medical insurance is mandatory by law for foreign students.
        </h3>
        <p class="cursor-pointer" (click)="clickLink.emit('clicked')">
            See how it works on the section below.
        </p>
    </div>
</div>
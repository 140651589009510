<div class="container-order-review">
    <div class="order-review mt-md-0 mt-4">
        <div class="order-review-header hide-mobile">
            <h2>{{ 'yourOrder' | translate }}</h2>
        </div>

        <div class="order-review-body">
            <div class="order-review-summary row mt-5">
                <div class="col-8">
                    <h4 class="plan-name">{{ orderReviewData?.name }}</h4>
                    <p class="plan-description">
                        {{ orderReviewData?.coverageDays }} {{ 'days' | translate }} - {{ orderReviewData?.visa }} {{ 'visa' | translate }}
                    </p>
                </div>

                <div class="col-4">
                    <h4 class="price">{{ orderReviewData?.price | currency:getCurrentPlan():true }}</h4>
                </div>
            </div>

            <div class="order-review-total mt-3">
                <div class="row">
                    <div class="col-8">
                        <h4 class="price-label">{{ 'subtotal' | translate }}</h4>
                    </div>

                    <div class="col-4">
                        <h4 class="price">{{ orderReviewData?.subtotal | currency:getCurrentPlan():true }}</h4>
                    </div>
                </div>
            </div>

            <div class="order-review-total mt-3">
                <div class="row">
                    <div class="col-8">
                        <h4 class="price-label">{{ 'total' | translate }}</h4>
                    </div>

                    <div class="col-4">
                        <h4 class="price-total">{{ orderReviewData?.total | currency:getCurrentPlan():true }}</h4>
                    </div>
                </div>
            </div>

            <div class="order-review-terms">
                <div class="mt-3">
                    <app-checkbox
                        id="termsAccepted"
                        label="{{ 'acceptTermsText' | translate }}"
                        (checkboxChange)="handleChange($event.target.checked, 'termsAccepted')"
                        [(ngModel)]="termsAccepted"
                        ngDefaultControl
                    >
                    </app-checkbox>
                </div>

                <div class="mt-3">
                    <app-checkbox
                        id="termsStoreHandlingData"
                        label="{{ 'byUsingThisFormYourAgree' | translate }}*"
                        (checkboxChange)="handleChange($event.target.checked, 'termsStoreHandlingData')"
                        [(ngModel)]="termsStoreHandlingData"
                        ngDefaultControl
                    >
                    </app-checkbox>
                </div>
            </div>
        </div>
    </div>
</div>
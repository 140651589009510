import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Colors } from 'src/utils/colors';
import { countries } from 'src/utils/countries';
import { StatesFromCountries } from 'src/utils/states-countries';
import { RegisterService } from './register.service';
import { FunctionsService } from 'src/app/shared/functions.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public Colors = Colors;

  public withoutPurchase: boolean;
  public loading: boolean;
  public firstName: string;
  public lastName: string;
  public email: string;
  public password: string;
  public receiveInformation: boolean;
  public gender: string;
  public countries: any[] = countries;
  public birthdate: string;
  public country: string;
  public passport: string;
  public phone: string;
  public mobile: string;
  public termsAccepted: boolean;
  public statesByCountry: any[] = [];
  public genders: any[] = [
    {
      label: 'Male',
      value: 'male'
    },
    {
      label: 'Female',
      value: 'female'
    },
  ];
  public university: {
    country: string;
    state: string;
    address: string;
    city: string;
    postalCode: string;
  }

  constructor(
    private registerService: RegisterService,
    private activatedRoute: ActivatedRoute,
    private _functions: FunctionsService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.university = {
      country: '',
      state: '',
      address: '',
      city: '',
      postalCode: ''
    };

    this.activatedRoute.queryParams.subscribe(params => {
      this.withoutPurchase = params['withoutPurchase'];
    });
  }

  public register(): void {
    let emailValid = true;

    if (!this.email) {
      this.toastr.error('Please fill in the email field.', 'Error');
      emailValid = false;

    } else if (!this._functions.validateEmail(this.email)) {
      this.toastr.error('The email field does not contain a valid email address.', 'Error');
      emailValid = false;
    }

    if (emailValid) {
      this.loading = true;
      const user = {
        name: this.firstName,
        lastName: this.lastName,
        email: this.email,
        genre: this.gender,
        password: this.password,
        receiveInformation: this.receiveInformation,
        birthDate: moment(this.birthdate).format('YYYY-MM-DD'),
        termsAccepted: this.termsAccepted,
        mobile: this.mobile,
        phone: this.phone,
        passport: this.passport,
        countryBirth: this.country,
        address: this.university
      }

      this.registerService.register({
        user
      }).subscribe((response) => {
        if (response.status === 'success') {
          this.registerService.setToken(response.token.access_token);
          this.registerService.setUser(response.user);
          if (this.withoutPurchase) {
            this.router.navigate(['/my-account/dashboard']);
            return;
          }

          this.router.navigate(['/purchase-flux/cart']);
        }
        this.loading = false;
      }, (error) => {
        this.loading = false;
        this.toastr.error(error?.error?.message || 'Something went wrong, please try again later.', 'Error');
      });
    }
  }

  public onSelectUniversityCountry(country: any): void {
    this.university.country = country;
    this.loadStatesByCountry(country);
  }

  public loadStatesByCountry(country: any): void {
    StatesFromCountries.find((item) => {
      if (item.country.value === country.replace('-', ' ')) {
        this.statesByCountry = item.states;
      }
    });
  }
}

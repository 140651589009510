<div class="attachments">
    <ul>
        <li class="active-icon" *ngFor="let f of (files);let i=index" [style.background-image]="'url(' + f.url + ')'">
            <!-- Image -->
            <div class="content-icon">
                <span (click)="downloadDoc(f)" matTooltip="Download image" matTooltipPosition="above">
                    <mat-icon>cloud_download</mat-icon>
                </span>
                <span (click)="seeAttached(f)" matTooltip="View image" matTooltipPosition="above">
                    <mat-icon>remove_red_eye</mat-icon>
                </span>
                <span *ngIf="canDelete" (click)="endpointDelete(f, i, 'deleteIndex')" matTooltip="Delete image" matTooltipPosition="above">
                    <mat-icon>delete</mat-icon>
                </span>
            </div>
            <!-- PDF -->
            <img *ngIf="verifyPDF(f.url)" class="filePdf" src="assets/img/ico/pdf.ico">
        </li>
        <li class="cursorPointer noBorder" *ngIf="((files?.length + 1) <= limit) && canAdd">
            <div style="overflow:hidden" matTooltip="Click or drag the image" matTooltipPosition="above">
                <input type="file" (change)="changeListener($event)" />
                <mat-icon class="add_circle_outline">add_circle_outline</mat-icon>
            </div>
        </li>
        <li *ngIf="(files?.length>0) && canDelete" matTooltip="Delete all attachments" matTooltipPosition="right"
            class="cursorPointer noBorder" style="position: relative;" (click)="endpointDelete(null, null, 'deleteAll')">
            <mat-icon class="remove_circle_outline">remove_circle_outline</mat-icon>
        </li>
    </ul>
</div>
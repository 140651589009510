import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input() selectLabel: string;
  @Input() selectId: string;
  @Input() name: string
  @Input() options: string | number[];
  @Input() selected: string | number = '';
  @Input() labelColor: string;
  @Input() customWidth: string;
  @Input() selectedCheckbox: boolean = false;
  
  @Output() selectChange: EventEmitter<string | number> = new EventEmitter<string | number>();

  constructor() { }

  ngOnInit(): void { }
}

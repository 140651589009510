import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Colors } from 'src/utils/colors';
import { countriesAbroad } from 'src/utils/countries-abroad';
import { SearchBarService } from './search-bar.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  @Input() fillFields: any;
  @Output() onSearch: EventEmitter<any> = new EventEmitter();

  public Colors = Colors;
  public visaType: string;
  public visaTypes: any[];
  public visaTypesSchool: any[];
  public birthDate: string;
  public universityState: string;
  public universityStates: any[];
  public universityName: string;
  public universities: any[];
  public searchForm: any = 'usa';
  public countries: any[] = countriesAbroad;
  public country: string;
  public universityNameSearch: string;
  public schoolNameSearch: string;

  constructor(
    private _searchBarService: SearchBarService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getVistaTypes();
    this.getUniversityStates();
    if (this.fillFields) {
      this.handleFillFileds();
    }
  }

  public handleFillFileds(): void {
    this.visaType = this.fillFields.idVisa;
    this.birthDate = this.fillFields.dateOfBirth;
    this.universityState = this.fillFields.idState;
    this.universityName = this.fillFields.searchForm === 'usa' ? this.fillFields.idUniversity : '0';
    this.universityNameSearch = this.fillFields.universityNameSearch;
    this.country = this.fillFields.country;
    this.schoolNameSearch = this.fillFields.schoolNameSearch;
    this.searchForm = this.fillFields.searchForm;

    this.searchUniversityByState();
  }

  public search(): void {
    this.onSearch.emit({
      visaType: this.visaType,
      dateOfBirth: this.birthDate,
      idUniversity: this.searchForm === 'usa' ? this.universityName : '0',
      country: this.country,
      searchForm: this.searchForm,
      type: this.searchForm,
      universityState: this.universityState,
      universityName: this.universityNameSearch,
      schoolName: this.schoolNameSearch,
    });
  }

  public handleStateChange(event: any): void {
    this.universityName = event.target.value;
    this.searchUniversityByState();
  }

  private getVistaTypes(): void {
    this._searchBarService.getVisaTypes().subscribe(
      (res) => {
        if (res.status === 'success') {
          this.visaTypes = res.visa.map((visa) => ({
            value: visa.id,
            label: visa.name
          }));
          this.forceOrderVisaTypes();
        }
      }, (error) => {
        this.toastr.error(error?.error?.message || 'Something went wrong, please try again later.', 'Error');
      }
    );
  }

  private forceOrderVisaTypes(): void {
    const visaTypes = this.visaTypes;
    const visaTypesOrdered = [];
    this.visaTypesSchool = [];

    visaTypes.forEach((visaType) => {
      if (visaType.label === '-- SELECT VISA --') {
        visaTypesOrdered.push(visaType);
      }
      if (visaType.label === 'K-12 Full-time F1 Visa') {
        this.visaTypesSchool.push(visaType);
      }
    });

    visaTypes.forEach((visaType) => {
      if (visaType.label === 'F1 International Student') {
        visaTypesOrdered.push(visaType);
      }
    });

    visaTypes.forEach((visaType) => {
      if (visaType.label === 'F2') {
        visaTypesOrdered.push(visaType);
      }
    });

    visaTypes.forEach((visaType) => {
      if (visaType.label === 'J1 International Student') {
        visaTypesOrdered.push(visaType);
      }
    });

    visaTypes.forEach((visaType) => {
      if (visaType.label === 'J2') {
        visaTypesOrdered.push(visaType);
      }
    });

    visaTypes.forEach((visaType) => {
      if (visaType.label === 'OPT') {
        visaTypesOrdered.push(visaType);
      }
    });

    this.visaTypes = visaTypesOrdered;
  }

  private getUniversityStates(): void {
    this._searchBarService.getUniversityStates().subscribe(
      (res) => {
        if (res.status === 'success') {
          this.universityStates = res.state.map((state) => ({
            value: state.id,
            label: state.name
          }));
        }
      }, (error) => {
        this.toastr.error(error?.error?.message || 'Something went wrong, please try again later.', 'Error');
      });
  }

  public searchUniversityByState(): void {
    if (this.searchForm !== 'abroad') {
      this._searchBarService.getUniversitiesByState(+this.universityState, parseInt(this.visaType, 10)).subscribe(
        (res) => {
          if (res.status === 'success') {
            this.universities = res.university.map((university: any) => ({
              value: university.id,
              label: university.name
            }));
          }
        }, (error) => {
          this.toastr.error(error?.error?.message || 'Something went wrong, please try again later.', 'Error');
        });
    }
  }
}

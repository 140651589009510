<h3>
    {{ data.msg }}
</h3><br />

<mat-dialog-content class="mat-typography">
    <div class="attachments">
        <ul>
            <li class="active-icon" *ngFor="let f of data.documents" [style.background-image]="'url(' + f.url + ')'">
                <div class="content-icon">
                    <!-- Image -->
                    <span (click)="downloadDoc(f)" matTooltip="Download image" matTooltipPosition="above">
                        <mat-icon>cloud_download</mat-icon>
                    </span>
                    <span (click)="seeAttached(f)" matTooltip="View image" matTooltipPosition="above">
                        <mat-icon>remove_red_eye</mat-icon>
                    </span>
                    <!-- PDF -->
                    <img *ngIf="verifyPDF(f.url)" class="filePdf" src="assets/img/ico/pdf.ico">
                </div>
            </li>
        </ul>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="right"><button mat-raised-button (click)="close()">Close</button></mat-dialog-actions>
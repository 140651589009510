<div class="text-center">
    <h4>{{ data.title }}</h4>
    <p>{{ data.description }}</p>
    <div class="mt-4">
        <app-input
            inputId="file" 
            inputLabel="" 
            inputType="file" 
            inputPlaceholder=""
            (inputChange)="onFileChange($event)"
            [(ngModel)]="file"
            ngDefaultControl
        ></app-input>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

import { MyAccountService } from 'src/app/pages/my-account/my-account.service';
import { Colors } from 'src/utils/colors';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  
  @Input() userInfo: any;

  public Colors = Colors;
  public firstName: string;
  public lastName: string;
  public email: string;
  public password: string;
  public confirmPassword: string;
  public birthdate: string;
  public passport: string;
  public phone: string;
  public mobile: string;
  public loading: boolean;

  constructor(
    private myAccountService: MyAccountService
  ) { }

  ngOnInit(): void {
    this.firstName = this.userInfo?.name;
    this.lastName = this.userInfo?.lastName;
    this.email = this.userInfo?.email;
    this.birthdate = moment(this.userInfo?.birthDate).format('DD/MM/YYYY');
    this.passport = this.userInfo?.passport;
    this.phone = this.userInfo?.phone;
    this.mobile = this.userInfo?.mobile;
  }

  public updateUser(): void {
    this.loading = true;
    const user = {
      ...this.myAccountService.getUser(),
      name: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
      confirmPassword: this.confirmPassword,
      birthDate: moment(this.birthdate).format('YYYY-MM-DD'),
      passport: this.passport,
      phone: this.phone,
      mobile: this.mobile,
    }

    this.myAccountService.updateUser({ user }).subscribe((response) => {
      this.loading = false;
      if (response.status === 'success') {
        // localStorage.setItem('user', JSON.stringify(user));
      }
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
  }
}

<div class="container-modal-plan-details">
    <div class="col-12 d-flex justify-content-end">
        <button class="btn" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <h4 class="modal-title">{{ data.name }}</h4>
    <div class="row">
        <div class="col-md-6 col-12">
            <span *ngIf="!viewType || viewType === 'policy'">
                <p class="item">{{ 'dailyRate' | translate }}: 
                    <strong>{{ data?.dailyPrice  | currency:getCurrentPlan():true }}</strong>
                </p>
                <p  class="item">{{ 'monthlyRate' | translate }}: 
                    <strong>{{ data?.monthlyRate | currency:getCurrentPlan():true }}</strong>
                </p>
            </span>
            <p *ngIf="data?.evacuationApply" class="item">{{ 'evacuation' | translate }}: 
                <strong *ngIf="data?.evacuationUnit === 'dollar'">{{ data?.evacuation | currency:getCurrentPlan():true }}</strong>
                <strong *ngIf="data?.evacuationUnit === 'percentage'">{{ data?.evacuation }}%</strong>
            </p>
            <p *ngIf="data?.repatriationApply" class="item">{{ 'repatriation' | translate }}: 
                <strong *ngIf="data?.repatriationUnit === 'dollar'">{{ data?.repatriation | currency:getCurrentPlan():true }}</strong>
                <strong *ngIf="data?.repatriationUnit === 'percentage'">{{ data?.repatriation }}%</strong>
            </p>
            <p *ngIf="data?.shsDeductibleAmount" class="item">{{ 'deductible' | translate }}: 
                <strong>{{ data?.overallAmount | currency:getCurrentPlan():true }}</strong>
            </p>
            <p *ngIf="data?.anualMaxApply" class="item">{{ 'annualMax' | translate }}: 
                <strong *ngIf="!['covered', 'unlimited', 'paid as'].includes(data?.anualMax)">{{ data?.anualMax | currency:getCurrentPlan():true }}</strong>
                <strong *ngIf="['covered', 'unlimited', 'paid as'].includes(data?.anualMax)">{{ data?.anualMax }}</strong>
            </p>
            <p *ngIf="data?.benefitApply" class="item">{{ 'perEventBenefit' | translate }}: 
                <strong>{{ data?.benefit | currency:getCurrentPlan():true }}</strong>
            </p>
            <p *ngIf="data?.coInsuranceApply" class="item">{{ 'coInsurance' | translate }}: 
                <strong>{{ data?.coInsurance }}%</strong>
            </p>
            <p *ngIf="data?.aca" class="item">{{ 'aca' | translate }}: 
                <strong>{{ data?.aca }}</strong>
            </p>
            <p *ngIf="data?.erApply" class="item">{{ 'er' | translate }}: 
                <strong>{{ data?.er | currency:getCurrentPlan():true}}</strong>
            </p>
            <p *ngIf="data?.ratingApply" class="item">{{ 'rating' | translate }}:
                <strong>{{ data?.rating }}</strong>
            </p>
        </div>
        <div class="col-md-6 col-12">
            <p *ngIf="data?.preventiveApply" class="item">{{ 'preventive' | translate }}: 
                <strong>{{ data?.preventive }}%</strong>
            </p>
            <p *ngIf="data?.alcoholDrugsApply" class="item">{{ 'drugsAndAlcohol' | translate }}: 
                <strong *ngIf="data?.alcoholDrugsUnit === 'dollar'">{{ data?.alcoholDrugs | currency:getCurrentPlan():true }}</strong>
                <strong *ngIf="data?.alcoholDrugsUnit === 'percentage'">{{ data?.alcoholDrugs }}%</strong>
            </p>
            <p *ngIf="data?.maternityApply" class="item">{{ 'maternity' | translate }}: 
                <strong *ngIf="data?.maternityUnit === 'dollar'">{{ data?.maternity | currency:getCurrentPlan():true }}</strong>
                <strong *ngIf="data?.maternityUnit === 'percentage'">{{ data?.maternity }}%</strong>
            </p>
            <p *ngIf="data?.pecApply" class="item">{{ 'pec' | translate }}: 
                <strong *ngIf="data?.pecUnit === 'percentage'">{{ data?.pec }}%</strong>
                <strong *ngIf="data?.pecUnit !== 'percentage'">{{ data?.pec }}</strong>
            </p>
            <p *ngIf="data?.frequencyApply" class="item">{{ 'frequency' | translate }}: 
                <strong>{{ getFrequencyPeriodLabel(data?.frequencyPeriod) }}</strong>
            </p>
            <p *ngIf="data?.shcCoverageApply" class="item">{{ 'shcCoverage' | translate }}: 
                <strong>{{ data?.shcCoverage }}%</strong>
            </p>
            <p *ngIf="data?.prescriptionDrugsApply" class="item">{{ 'prescriptionDrugs' | translate }}: 
                <strong *ngIf="data?.prescriptionDrugsUnit === 'dollar'">{{ data?.prescriptionDrugs | currency:getCurrentPlan():true }}</strong>
                <strong *ngIf="data?.prescriptionDrugsUnit === 'percentage'">{{ data?.prescriptionDrugs }}%</strong>
            </p>
            <p *ngIf="data?.accidentalApply" class="item">{{ 'accidental' | translate }}: 
                <strong>{{ data?.accidental | currency }}</strong>
            </p>
            <p *ngIf="data?.mentalHealthApply" class="item">{{ 'mentalHealth' | translate }}: 
                <strong *ngIf="data?.mentalHealthUnit === 'dollar'">{{ data?.mentalHealth | currency:getCurrentPlan():true }}</strong>
                <strong *ngIf="data?.mentalHealthUnit === 'percentage'">{{ data?.mentalHealth }}%</strong>
                <strong *ngIf="!['dollar', 'percentage'].includes(data?.mentalHealthUnit)">{{ data?.mentalHealth }}</strong>
            </p>
        </div>
    </div>

    <span *ngIf="!viewType">
        <p class="info-text">*{{ 'referenceRate' | translate }}</p>

        <div class="col-12 text-center">
            <app-button 
                (click)="selectPlan(data)" 
                buttonLabel="{{ 'selectPlan' | translate }}" 
                [buttonBGColor]="Colors.pink"
            ></app-button>
        </div>
    </span>
</div>
<table class="w-100">
    <tr class="table-header">
        <th style="width: 12%">{{ 'plan' | translate }}</th>
        <th>{{ 'monthlyRate' | translate }}</th>
        <th class="hide-mobile">{{ 'evacuation' | translate }}</th>
        <th class="hide-mobile">{{ 'repatriation' | translate }}</th>
        <th class="hide-mobile">{{ 'deductible' | translate }}</th>
        <th class="hide-mobile">{{ 'annualMax' | translate }}</th>
        <th class="hide-mobile">{{ 'coInsurance' | translate }}</th>
    </tr>
    <tr class="table-body" *ngFor="let data of tableData">
        <td class="plan-name">
            <app-badge
                *ngIf="data?.classification"
                [label]="data?.classification"
                [bgColor]="Colors.oceanBlue"
                [textColor]="Colors.darkGray"
            ></app-badge>
            <a (click)="openDetails(data)">{{ data?.name || '-' }}</a>
        </td>
        <td>{{ data?.dailyRate * 30 | currency }}</td>
        <td class="hide-mobile">
            <span *ngIf="data?.evacuationUnit === 'dollar'">
                {{ data?.evacuation | currency }}
            </span>
            <span *ngIf="data?.evacuationUnit === 'percentage'">
                {{ data?.evacuation }}%
            </span>
        </td>
        <td class="hide-mobile">
            <span *ngIf="data?.repatriationUnit === 'dollar'">
                {{ data?.repatriation | currency }}
            </span>
            <span *ngIf="data?.repatriationUnit === 'percentage'">
                {{ data?.repatriation }}%
            </span>
        </td>
        <td class="hide-mobile">
            {{ data?.shsDeductibleAmount | currency }}
        </td>
        <td class="hide-mobile">
            <span *ngIf="!['covered', 'unlimited', 'paid as'].includes(data?.anualMax)">
                {{ data?.anualMax | currency }}
            </span>
            <span *ngIf="['covered', 'unlimited', 'paid as'].includes(data?.anualMax)">
                {{ data?.anualMax }}
            </span>
            <span *ngIf="!data?.anualMax">
                -
            </span>
        </td>
        <td class="hide-mobile">{{ data?.coInsurance }}%</td>
        <td>
            <app-button 
                (click)="selectPlan(data)" 
                buttonLabel="{{ 'select' | translate }}" 
                [buttonBGColor]="Colors.pink"
                customWidth="50px"
            ></app-button>
        </td>
    </tr>
</table>
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  @Output() onUserMakeLogin = new EventEmitter<any>();


  private _url: string;

  constructor(
    private _http: HttpClient
  ) { 
    this._url = environment.api_url;
  }

  public login(data: any): Observable<any> {
    return this._http.post(`${this._url}/auth/login`, data);
  }

  public isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  }

  public setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  public setUser(user: any): void {
    if (user) {
      this.onUserMakeLogin.emit(user);
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  public getUser(): any {
    return JSON.parse(localStorage.getItem('user'));
  }

  public recoveryPassword(data: any): Observable<any> {
    return this._http.post(`${this._url}/user/recovery-password`, data);
  }

  public changePassword(data: any): Observable<any> {
    return this._http.post(`${this._url}/user/change-password`, data);
  }
}

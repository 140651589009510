import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableModel } from 'src/models/table.model';
import { Colors } from 'src/utils/colors';
import { PlanDetailsComponent } from '../modals/plan-details/plan-details.component';

@Component({
  selector: 'app-table-list-plans',
  templateUrl: './table-list-plans.component.html',
  styleUrls: ['./table-list-plans.component.scss']
})
export class TableListPlansComponent implements OnInit {

  public Colors = Colors;

  @Input() tableData: TableModel;
  @Output() onSelectPlan: EventEmitter<TableModel> = new EventEmitter<TableModel>();

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {}

  public selectPlan(plan: TableModel): void {
    this.onSelectPlan.emit(plan);
  }

  public openDetails(data: any): void {
    this.dialog.open(PlanDetailsComponent, {
      width: '500px',
      data
    });
  }
}

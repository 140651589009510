<div class="card-plan-container mt-5">
    <div class="badge-header-area">
        <app-badge
            *ngIf="plan?.classification"
            [label]="getPlanName(plan?.classification)"
            [bgColor]="planColors[plan.classification]"
        ></app-badge>
    </div>

    <p (click)="openDetails(plan)" class="card-plan-name cursor-pointer">{{ plan?.name }}</p>

    <div class="card-plan-body mt-4">
        <div class="card-plan-topic">
            <p class="card-plan-topic-label">{{ 'monthlyRate' | translate }}</p>
            <p class="card-plan-topic-value">{{ plan?.dailyPrice * 30 | currency }}</p>
        </div>

        <div class="card-plan-topic mt-2">
            <p class="card-plan-topic-label">{{ 'repatriation' | translate }}</p>
            <p *ngIf="plan?.repatriationUnit === 'dollar'" class="card-plan-topic-value">{{ plan?.repatriation | currency }}</p>
            <p *ngIf="plan?.repatriationUnit === 'percentage'" class="card-plan-topic-value">{{ plan?.repatriation }}%</p>
        </div>

        <div class="card-plan-topic mt-2">
            <p class="card-plan-topic-label">{{ 'annualMax' | translate }}</p>
            <p *ngIf="!['covered', 'unlimited', 'paid as'].includes(plan?.anualMax)" class="card-plan-topic-value">{{ plan?.anualMax | currency }}</p>
            <p *ngIf="['covered', 'unlimited', 'paid as'].includes(plan?.anualMax)" class="card-plan-topic-value">{{ plan?.anualMax }}</p>
        </div>

        <div class="card-plan-topic mt-2">
            <p class="card-plan-topic-label">{{ 'overallAmount' | translate }}</p>
            <p class="card-plan-topic-value">{{ plan?.overallAmount | currency }}</p>
        </div>

        <div class="card-plan-button-area">
            <app-button (click)="selectPlan(plan)" buttonLabel="Select Plan" [buttonBGColor]="Colors.pink"></app-button>
        </div>
    </div>
</div>
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Colors } from 'src/utils/colors';

@Component({
  selector: 'app-breadcrumb-purchase',
  templateUrl: './breadcrumb-purchase.component.html',
  styleUrls: ['./breadcrumb-purchase.component.scss']
})
export class BreadcrumbPurchaseComponent implements OnInit {

  @Input() steps = [];

  @Output() stepClicked: EventEmitter<any> = new EventEmitter();

  public Colors = Colors;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.changeStepColor();
    this.router.events.subscribe(() => {
      this.changeStepColor();
    });
  }

  private changeStepColor(): void {
    const currentStep = this.steps.find(step => step.path === this.router.url.split('?')[0].split('/').pop());
    const currentStepIndex = this.steps.indexOf(currentStep);

    this.steps.forEach((step, index) => {
      if (index < currentStepIndex) {
        step.completed = true;
        step.active = false;
        return;
      }  
      
      if (index === currentStepIndex) {
        step.completed = false;
        step.active = true;
        return;
      }

      step.completed = false;
      step.active = false;
    });
  }

  public onStepClicked(step: any): void {
    if (step.disabled) return;

    if (step.completed) {
      this.stepClicked.emit(step);
    }
  }
}

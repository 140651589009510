import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { Colors } from 'src/utils/colors';
import { countries } from 'src/utils/countries';
import { countriesAbroad } from 'src/utils/countries-abroad';
import { SearchBarService } from '../search-bar/search-bar.service';
import { FunctionsService } from 'src/app/shared/functions.service';
import { HubspotService } from '../hubspot.service';

@Component({
  selector: 'app-gs4-search-bar-home',
  templateUrl: './search-bar-home.component.html',
  styleUrls: ['./search-bar-home.component.scss']
})
export class SearchBarHomeComponent implements OnInit {

  @Output() clickLink: EventEmitter<any> = new EventEmitter();

  public Colors = Colors;
  public visaType: string;
  public visaTypes: any[];
  public visaTypesSchool: any[];
  public visaSchool: string;
  public age: string;
  public universityState: string;
  public universityStates: any[];
  public universityName: string;
  public universities: any[];
  public searchForm: any = 'usa';
  public countries: any[] = countries;
  public countriesAbroad: any[] = countriesAbroad;
  public country: string;
  public universityNameSearch: string;
  public schoolNameSearch: string;
  public birthDate: string;
  public email: string;
  public schoolName: string;

  constructor(
    private _searchBarService: SearchBarService,
    private _functions: FunctionsService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getVistaTypes();
    this.getUniversityStates();
    this.loadHubSpot();
  }

  private loadHubSpot(): void {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
  }

  public search(): void {
    let emailValid = true;

    if ( this.searchForm === 'usa'){
      if (!this.universityName) {
        this.toastr.error('Select University Name.', 'Error');
        emailValid = false;
      }

      if (!this.universityState) {
        this.toastr.error('Select University State.', 'Error');
        emailValid = false;
      }

      if (!this.visaType) {
        this.toastr.error('Select the type of visa.', 'Error');
        emailValid = false;
      }
    }
    if ( this.searchForm === 'abroad'){
      if (!this.universityNameSearch) {
        this.toastr.error('Select University Name.', 'Error');
        emailValid = false;
      }

      if (!this.country) {
        this.toastr.error('Select the Country.', 'Error');
        emailValid = false;
      }
    }
    if ( this.searchForm === 'school'){
      if (!this.universityState) {
        this.toastr.error('Select School State.', 'Error');
        emailValid = false;
      }

      if (!this.schoolNameSearch) {
        this.toastr.error('Select School Name.', 'Error');
        emailValid = false;
      }

      if (!this.visaSchool) {
        this.toastr.error('Select the type of visa.', 'Error');
        emailValid = false;
      }
    }

    if (!this.birthDate) {
      this.toastr.error('Please inform your date of birth.', 'Error');
      emailValid = false;
    }

    if (!this.email) {
      this.toastr.error('Please fill in the email field.', 'Error');
      emailValid = false;

    } else if (!this._functions.validateEmail(this.email)) {
      this.toastr.error('The email field does not contain a valid email address.', 'Error');
      emailValid = false;
    }

    if (emailValid) {
      const body = {
        idVisa: this.searchForm === 'school' ? this.visaSchool : this.visaType,
        dateOfBirth: this.birthDate,
        idState: this.universityState,
        idUniversity: this.searchForm === 'usa' ? this.universityName : '0',
        country: this.country,
        searchForm: this.searchForm,
        universityNameSearch: this.universityNameSearch,
        schoolNameSearch: this.schoolNameSearch,
        email: this.email
      };

      this.router.navigate(['/plan-all'], { queryParams: body});
    }
  }

  private getVistaTypes(): void {
    this._searchBarService.getVisaTypes().subscribe(
      (res) => {
        if (res.status === 'success') {
          this.visaTypes = res.visa.map((visa) => ({
            value: visa.id,
            label: visa.name
          }));
          this.forceOrderVisaTypes();
        }
      }, (err) => {
        console.log('err', err);
      }
    );
  }

  private forceOrderVisaTypes(): void {
    const visaTypes = this.visaTypes;
    const visaTypesOrdered = [];
    this.visaTypesSchool = [];

    visaTypes.forEach((visaType) => {
      if (visaType.label === '-- SELECT VISA --') {
        visaTypesOrdered.push(visaType);
      }
      if (visaType.label === 'K-12 Full-time F1 Visa') {
        this.visaTypesSchool.push(visaType);
      }
    });

    visaTypes.forEach((visaType) => {
      if (visaType.label === 'F1 International Student') {
        visaTypesOrdered.push(visaType);
      }
    });

    visaTypes.forEach((visaType) => {
      if (visaType.label === 'F2') {
        visaTypesOrdered.push(visaType);
      }
    });

    visaTypes.forEach((visaType) => {
      if (visaType.label === 'J1 International Student') {
        visaTypesOrdered.push(visaType);
      }
    });

    visaTypes.forEach((visaType) => {
      if (visaType.label === 'J2') {
        visaTypesOrdered.push(visaType);
      }
    });

    visaTypes.forEach((visaType) => {
      if (visaType.label === 'OPT') {
        visaTypesOrdered.push(visaType);
      }
    });

    this.visaTypes = visaTypesOrdered;
  }

  private getUniversityStates(): void {
    this._searchBarService.getUniversityStates().subscribe(
      (res) => {
        if (res.status === 'success') {
          this.universityStates = res.state.map((state: any) => ({
            value: state.id,
            label: state.name
          }));
        }
      }, (err) => {
        this.toastr.error(err?.error?.message || 'Something went wrong, please try again later.', 'Error');
      });
  }

  public handleStateChange(event: any): void {
    this.universityName = event.target.value;
    this.searchUniversityByState();
  }

  public searchUniversityByState(): void {
    this._searchBarService.getUniversitiesByState(+this.universityState, parseInt(this.visaType, 10)).subscribe(
      (res) => {
        if (res.status === 'success') {
          this.universities = res.university.map((university: any) => ({
            value: university.id,
            label: university.name
          }));
        }
      }, (err) => {
        this.toastr.error(err?.error?.message || 'Something went wrong, please try again later.', 'Error');
      });
  }
}
